import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const postCall = async (url, data) =>{
    // return axios
    // .post(url,data? data : '',
    //     {
    //         headers: { Authorization: `Bearer ${cookies.get('access_token_tlh_service')}` },
    //     })
    // .then((res) => {
    //     return res.data;
    // })
    // .catch((err) => {
    //     console.log(err);
    // });

    return new Promise(function(resolve, reject) {
        axios.post(
        url, data? data : '',
        {
            headers: { Authorization: `Bearer ${cookies.get('access_token_tlh_service')}` },
        }).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            console.log(err);
        });
    });
}
