import {
  IonGrid,
  IonHeader,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonToolbar,
  IonToast,
  IonSpinner
} from "@ionic/react";

import {
  eye, 
  homeOutline,
  arrowBack,
} from 'ionicons/icons'

import { useState } from 'react';
import "./ChangePassword.css";
import "./ChangePassword.scss";
import { BrowserRouter as Router, Route, Link, useHistory, Redirect } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ChangePassword: React.FC = () => {
  let history = useHistory();

  const [oldPassword, setOldPassword] = useState<any>('');
  const [newPassword, setNewPassword] = useState<any>('');
  const [confirmPassword, setConfirmPassword] = useState<any>('');

  const [message, setMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [passwordShown3, setPasswordShown3] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };
  const togglePassword3 = () => {
    setPasswordShown3(!passwordShown3);
  };

  const validation = () => {
    if(oldPassword == '') {
      setMessage('Old password is required');
      setShowToast(true);
      return false;
    }else if(newPassword == '') {
      setMessage('New password is required');
      setShowToast(true);
      return false;
    }else if(!/^[A-Za-z\d@$!%^*#?&]{6,20}$/.test(newPassword)){
      setMessage('New password must be 6 to 20 characters long');
      setShowToast(true);
      return false;
    }else if(confirmPassword == '') {
      setMessage('Confirm password is required');
      setShowToast(true);
      return false;
    }else if(!/^[A-Za-z\d@$!%^*#?&]{6,20}$/.test(confirmPassword)){
      setMessage('Confirm password must be 6 to 20 characters long');
      setShowToast(true);
      return false;
    }else if(newPassword != confirmPassword) {
      setMessage('Confirm password not matching');
      setShowToast(true);
      return false;
    }
    return true;
  }

  const handleChangePassword = () => {
    if(validation()){
      setLoading(true);
      var url = BASE_URL + "users/password-update";
      axios
      .post(url, {
        current_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
      },
      {
        headers: { Authorization: `Bearer ${cookies.get('access_token_tlh_service')}` },
    })
      .then((res) => {
        if(res.data.status){
          setMessage(res.data.message);
          setLoading(false);
          setShowToast(true);
          
          setTimeout(()=>{
            history.goBack();
          }, 500);
        }
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  }

  return (
    <IonPage className="">
      <IonHeader>
          <div className="header1">
            <div className="back-btn-container">
              <IonIcon icon={arrowBack} onClick={()=>{
                history.goBack();
              }} size="large" className="text-white" />
            </div>
            <h1 className="header-title1">Change password</h1>
          </div>
      </IonHeader>
      
      <IonContent >
        <IonGrid className="ion-grid">
          {/* <div className=" password-page">
            <h1 className="password-heading">Create New Password</h1>
            </div>
            */}
      
            <IonLabel className='ion-label' position="floating">Current Paswword</IonLabel>
           <IonItem className='item-setting' lines='none'>
            <IonInput placeholder="Old Password" onIonChange={(e)=>{
              setOldPassword(e.detail.value);
            }} type={passwordShown ? "text" : "password"} />
            <IonIcon onClick={togglePassword} icon={eye} style={{color:"#144CC5"}}  />
          </IonItem>
          
          <IonLabel className='ion-label' position="floating">New Password</IonLabel>
           <IonItem className='item-setting' lines='none'>
            <IonInput placeholder="New Password" onIonChange={(e)=>{
              setNewPassword(e.detail.value);
            }} type={passwordShown2 ? "text" : "password"} />
            <IonIcon onClick={togglePassword2} icon={eye} style={{color:"#144CC5"}}  />
          </IonItem>

          <IonLabel className='ion-label' position="floating">Confirm Password</IonLabel>
           <IonItem className='item-setting' lines='none'>
            <IonInput placeholder="Confirm Password" onIonChange={(e)=>{
              setConfirmPassword(e.detail.value);
            }} type={passwordShown3 ? "text" : "password"}/>
            <IonIcon onClick={togglePassword3} icon={eye} style={{color:"#144CC5"}}  />
          </IonItem>
          
          <div className="">
              <button className="password-btn" onClick={()=> handleChangePassword()}>{loading? <IonSpinner className="load-spinner" name="crescent" /> : 'Update Password'}</button>
          </div>
           
        </IonGrid>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
