import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToast,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonImg,
  IonIcon,
  IonSpinner,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { arrowBackOutline, eye } from "ionicons/icons";
import "./Login.css";
import "./Login.scss";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";
import Cookies from "universal-cookie";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { Device } from "@capacitor/device";

const cookies = new Cookies();

const Home: React.FC = () => {
  let history = useHistory();
  let cookies = new Cookies();

  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceInformation, setDeviceInformation] = useState<any>();
  const [deviceToken, setDeviceToken] = useState<any>({
    value: ''
  });

  // useIonViewWillEnter(() => {
  //   deviceInfo();
  //   if (cookies.get('device_token_tlh_service')) {
  //     setDeviceToken(cookies.get('device_token_tlh_service'));
  //   }
  // });

  // const deviceInfo = async () => {
  //   const info = await Device.getInfo();
  //   setDeviceInformation(info);
  // };
  useEffect(() => {
    let isMounted = true;

    const deviceInfo = async () => {
      const info = await Device.getInfo();
      if (isMounted) {
        setDeviceInformation(info);
      }
    };

    deviceInfo();

    const token = cookies.get('device_token_tlh_service');
    if (isMounted && token) {
      setDeviceToken(token);
    }

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const validation = () => {
    if (email == "") {
      setMessage("Email must be provided");
      setShowToast(true);
      return false;
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setMessage("Invalid Email");
      setShowToast(true);
      return false;
    } else if (password == "") {
      setMessage("Password is required");
      setShowToast(true);
      return false;
    } else if (!/^[A-Za-z\d@$!%^*#?&]{6,20}$/.test(password)) {
      setMessage("Password must be 6 to 20 characters long");
      setShowToast(true);
      return false;
    } else {
      return true;
    }
  };
  const handleLogin = () => {
    if (validation()) {
      setLoading(true);
      var url = BASE_URL + "login";
      axios
        .post(url, {
          email: email,
          password: password,
          device_token: deviceToken.value,
          manufacturer: deviceInformation.manufacturer,
          model: deviceInformation.model,
          operating_system: deviceInformation.operatingSystem,
          os_version: deviceInformation.osVersion,
          platform: deviceInformation.platform
        })
        .then((res) => {
          setEmail('');
          setPassword('');
          if (res.data.status) {
            setLoading(false);
            // localStorage.setItem('loves_burger_user', JSON.stringify(userData));
            let date = new Date();
            date.setFullYear(date.getFullYear() + 1);
            if (res.data.companies.length > 1) {
              history.push('/companylist', { data: res.data });
            } else {
              localStorage.setItem("access_token_tlh_service", JSON.stringify(res.data.token.token));
              // cookies.set("access_token_tlh_service", res.data.token.token, {
              //   path: "/",
              //   expires: date,
              //   sameSite: "lax",
              // });
              localStorage.setItem("select_companies", JSON.stringify(res.data.companies));

              url = BASE_URL + "users/me";
              axios.get(url, {
                headers: {
                  Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
                },
              })
                .then((res) => {
                  localStorage.setItem("tlh_service_user", JSON.stringify(res.data.data));
                  // cookies.set("tlh_service_user", JSON.stringify(res.data.data), {
                  //   path: "/",
                  //   expires: date,
                  //   sameSite: "lax",
                  // });
                  setLoading(false);
                  history.push("/page/dashboard");
                })
                .catch((error: any) => {
                  console.log(error)
                });
            }
          } else {
            setMessage(res.data.message);
            setShowToast(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  if (
    !cookies.get("seen_welcome_screen_tlh_service") ||
    cookies.get("seen_welcome_screen_tlh_service") == undefined
  ) {
    return <Redirect to="/page/accountcreated"></Redirect>;
  } else if (!localStorage.getItem("access_token_tlh_service"))
  // if (
  //   !cookies.get("access_token_tlh_service") ||
  //   cookies.get("access_token_tlh_service") === undefined
  // ) 
  {
    return (
      <IonPage>
        <IonHeader className="login-header">
          <IonIcon
            icon={arrowBackOutline}
            size="large"
            onClick={() => history.goBack()}
          />
          <IonToolbar>
            <IonImg
              src={require("../images/pexels-photo-1662159.jpeg")}
              className="hearder-image"
              style={{ margin: "-4px" }}
            />
            <div className="overlay">
              <IonImg
                src={require("../images/tlh-corp-white.png")}
                className="hearder-log"
              />
              <h1 className="logo-text">Login</h1>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {/* <div className='toolbar'>
         <h1 className='login-heading'>LogIn</h1>
         </div> */}
          <IonGrid className="grid">
            <IonLabel className="label-setting" position="floating">
              Email Address
            </IonLabel>
            <IonItem className="input-setting" lines="none">
              <IonInput
                type="text"
                onIonChange={(e) => {
                  setEmail(e.detail.value);
                }}
              />
            </IonItem>

            <IonLabel className="label-setting" position="floating">
              Password
            </IonLabel>
            <IonItem lines="none" className="input-setting">
              <IonInput
                type={passwordShown ? "text" : "password"}
                onIonChange={(e) => {
                  setPassword(e.detail.value);
                }}
              />
              <IonIcon
                onClick={togglePassword}
                icon={eye}
                style={{ color: "#144CC5" }}
              />
            </IonItem>
            <div className="Login">
              <button className="btn" onClick={handleLogin}>
                {loading ? (
                  <IonSpinner className="load-spinner" name="crescent" />
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <Link to="/page/forget-password">
              <div className="forget-link">
                Forget Password
              </div>
            </Link>

            {/* <div className='link'>
              <IonCol >
                <a href="#" >Signup</a>
              </IonCol >
              <div className='forget-link'>
                <a href="#" >Forget Password</a>
              </div>
            </div> */}
          </IonGrid>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={message}
            duration={900}
            cssClass="tlh-service-toast"
          />
        </IonContent>
      </IonPage>
    );
  } else {
    return <Redirect to="/page/dashboard"></Redirect>;
  }
};

export default Home;
