import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToast,
  IonToolbar,
  IonImg,
  useIonViewWillEnter,
} from "@ionic/react";
// import { eye } from "ionicons/icons";
import "./Login.css";
import "./Login.scss";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useHistory,
  Redirect,
} from "react-router-dom";
import Cookies from "universal-cookie";
import { Plugins, Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import {
  LocalNotifications,
  LocalNotificationSchema,
} from "@capacitor/local-notifications";

const cookies = new Cookies();

const AccountCreated: React.FC = () => {
  let history = useHistory();
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceInformation, setDeviceInformation] = useState<any>();
  const [deviceToken, setDeviceToken] = useState<any>();
  const [notifications, setnotifications] = useState<any>();

  const handleLogin = () => {
    history.push("/page/login");
  };

  useIonViewWillEnter(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e: any) => {
        if (window.location.pathname === "/page/dashboard") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        } else if (window.location.pathname === "/page/accountcreated") {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            Plugins.App.exitApp();
          }
        }
      });
    }

    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    cookies.set("seen_welcome_screen_tlh_service", 1, {
      path: "/",
      expires: date,
      sameSite: "lax",
    });

    if (localStorage.getItem("access_token_tlh_service")) {
      history.push("/page/dashboard");
    }
  });

  const showToastMsg = (message: string) => {
    setMessage(message);
    setShowToast(true);
  };

  const deviceInfo = async () => {
    const info = await Device.getInfo();
    setDeviceInformation(info);
  };

  const register = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      //showToastMsg("Push registration success");
      setDeviceToken(token);

      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      cookies.set("device_token_tlh_service", token, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        let notificationObj: any = {
          notifications: [
            {
              title: notification.title,
              body: notification.body,
              id: 1,
            },
          ],
        };
        LocalNotifications.schedule(notificationObj);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        if (localStorage.getItem("tlh_service_user")) {
          history.push("/page/notifications");
        }
      }
    );

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (notification: any) => {
        if (localStorage.getItem("tlh_service_user")) {
          history.push("/page/notifications");
        }
      }
    );
  };

  useEffect(() => {
    if (Capacitor.platform !== "web") {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== "granted") {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === "denied") {
              showToastMsg("Push Notification permission denied");
            } else {
              showToastMsg("Push Notification permission granted");
              register();
            }
          });
        } else {
          register();
        }
      });
    }
    // deviceInfo();
  }, []);

  // useIonViewWillEnter(async () => {
  //   let info = await Device.getInfo();
  //   setDeviceInformation(info);
  // });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonImg
            src={require("../images/pexels-photo-1662159.jpeg")}
            className="hearder-image"
            style={{ margin: "-4px" }}
          />
          <div className="overlay">
            <IonImg
              src={require("../images/tlh-corp-white.png")}
              className="hearder-log"
            />
            <h1 className="logo-text">Login</h1>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="grid m_xy">
          <div className="Login">
            <button
              className="btn"
              onClick={() => {
                history.push("/page/quote");
              }}
            >
              Request a Quote
            </button>
          </div>

          <div className="Login">
            <button className="btn" onClick={handleLogin}>
              Login
            </button>
          </div>
          <div className="Login">
            <button
              className="btn"
              onClick={() => {
                history.push("/page/register");
              }}
            >
              Create Account
              <p className="customer_text">for customers and professionals only</p>
            </button>
          </div>
        </IonGrid>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default AccountCreated;
