import {
  IonGrid,
  IonHeader,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonProgressBar,
  IonModal,
  useIonViewWillEnter,
} from "@ionic/react";

import {
  pinOutline,
  callOutline,
  addCircle,
  addCircleOutline,
  headset,
  arrowBack,
  alarmOutline,
  timerOutline,
  closeCircle,
} from "ionicons/icons";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";
import { Camera, CameraResultType } from "@capacitor/camera";
import "./DetailImage.scss";
import Cookies from "universal-cookie";
import { ASSET_URL } from "../BaseUrl";
import Rating from "../components/RatingStar";

const cookies = new Cookies();

const DetailImage: React.FC = () => {
  let history = useHistory();

  // const [jobDetail, setJobDetail] = useState<any>(cookies.get("job-detail"));
  let job_details: any = localStorage.getItem('job-detail');
  const [jobDetail, setJobDetail] = useState<any>(JSON.parse(job_details));
  let tracking_service_detail: any = localStorage.getItem("tlh_service_tracking_detail")
  const [trackingDetail, settrackingDetail] = useState<any>(
    JSON.parse(tracking_service_detail)
  );
  // const [trackingDetail, settrackingDetail] = useState<any>(
  //   cookies.get("tlh_service_tracking_detail")
  // );
  const [checkinImages, setCheckinImages] = useState<any>([]);
  const [checkoutImages, setCheckoutImages] = useState<any>([]);
  const [selectedImageType, setSelectedImageType] = useState<any>("");
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>("");
  const [showModal, setShowModal] = useState<any>(false);
  const [locationImages, setLocationImages] = useState<any>([]);
  const [locationCheckOutImages, setLocationCheckOutImages] = useState<any>([]);

  useIonViewWillEnter(() => {
    let checkinimages: any = [];
    let checkoutimages: any = [];

    trackingDetail.attachments.map((item: any, index: any) => {
      if (item.context == "check-in") {
        checkinimages.push(item);
      } else {
        checkoutimages.push(item);
      }
    });
    setTimeout(() => {
      setCheckinImages(checkinimages);
      setCheckoutImages(checkoutimages);
    }, 500);
  });

  const handleImageClick = (index: any, type: any) => {
    setSelectedImageType(type);
    setSelectedImageIndex(index);
    setShowModal(true);
  };
  const handleRemoveImage = (index: any) => {
    let allImages: any =
      selectedImageType == "checkin" ? locationImages : locationCheckOutImages;
    allImages.splice(index, 1);
    setShowModal(false);
    setSelectedImageIndex(0);
    if (selectedImageType == "checkin") {
      setLocationImages(allImages);
    } else if (selectedImageType == "checkout") {
      setLocationCheckOutImages(allImages);
    }
  };

  return (
    <IonPage className="page">
      <IonHeader>
        <div className="header1">
          <div className="track-back-btn">
            <IonIcon
              icon={arrowBack}
              onClick={() => {
                history.goBack();
              }}
              size="large"
              className="text-white"
              style={{ padding: "0px" }}
            />
          </div>
          <h1 className="header-title1">Job Tracking Detail</h1>
        </div>
      </IonHeader>
      <IonContent className="no-scroll">
        <IonGrid>
          <IonModal isOpen={showModal}>
            <IonContent>
              <div className="modal-content">
                <div className="modal-header">
                  {/* <IonImg src={
                        selectedImageType == "checkin" &&
                        locationImages &&
                        locationImages.length > 0
                          ? locationImages[selectedImageIndex].image
                          : selectedImageType == "checkout" &&
                            locationCheckOutImages &&
                            locationCheckOutImages.length > 0
                          ? locationCheckOutImages[selectedImageIndex].image
                          : ""
                      } className='modal-image'/> */}

                  {/* <h1 className="modal-header-title">Attachment</h1> */}
                  <h1
                    onClick={() => setShowModal(false)}
                    className="modal-close"
                  >
                    <IonIcon icon={closeCircle} />
                  </h1>
                </div>
                <div className="modal-body">
                  <img
                    className="modal-image"
                    src={
                      selectedImageType == "checkin" &&
                        checkinImages &&
                        checkinImages.length > 0
                        ? ASSET_URL +
                        "/public/" +
                        checkinImages[selectedImageIndex].attachment_url
                        : selectedImageType == "checkout" &&
                          checkoutImages &&
                          checkoutImages.length > 0
                          ? ASSET_URL +
                          "/public/" +
                          checkoutImages[selectedImageIndex].attachment_url
                          : ""
                    }
                  />
                  <div className="modal-image-content">
                    <h3 className="modal-image-heading">
                      {selectedImageType == "checkin" &&
                        checkinImages &&
                        checkinImages.length > 0
                        ? checkinImages[selectedImageIndex].name
                        : selectedImageType == "checkout" &&
                          checkoutImages &&
                          checkoutImages.length > 0
                          ? checkoutImages[selectedImageIndex].name
                          : ""}
                    </h3>
                    {/* <div className="Modal-btnn">
                   <button  className='btn-contentt'  onClick={() => handleRemoveImage(selectedImageIndex)} >Remove</button>
                   </div> */}
                  </div>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <div className="management">
            <div className="menu-flex">
              <h3 className="sub-title">{jobDetail.job.location.name}</h3>
            </div>
            <h1 className="title">{jobDetail.job.title}</h1>
            {jobDetail &&
              jobDetail.starts_at_formatted &&
              jobDetail.ends_at_formatted ? (
              <span className="time">
                {jobDetail.starts_at_formatted +
                  "-" +
                  jobDetail.ends_at_formatted}
              </span>
            ) : (
              ""
            )}

            {/* <h6 className="progress-heading">14 mins remaining</h6>
            <IonProgressBar
              className="Progressbar"
              color="primary"
              value={0.2}
            ></IonProgressBar> */}
            <br />

            <div className="ion-address">
              <IonIcon className="gray-color" icon={pinOutline} />
              <p className="parmentant-address">
                {jobDetail.job.location.address}
              </p>
            </div>
            <div className="ion-phone">
              <IonIcon className="gray-color" icon={callOutline} />
              <p className="parmentant-no">{jobDetail.job.location.phone_no}</p>
            </div>

            <div className="ion-phone">
              <IonIcon className="gray-color" icon={alarmOutline} />
              <p className="parmentant-no">
                <b>Started at:</b> {trackingDetail.starts_at}
              </p>
            </div>
            <div className="ion-phone">
              <IonIcon className="gray-color" icon={timerOutline} />
              <p className="parmentant-no">
                <b>Ended at:</b> {trackingDetail.ends_at}
              </p>
            </div>

            <h3>Check-in attachments:</h3>
            <div className="flex-box">
              {checkinImages.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => handleImageClick(index, "checkin")}
                    className="box-content"
                    key={index}
                  >
                    {/* <h4 className="box-title">HM</h4> */}
                    <img
                      src={ASSET_URL + "/public/" + item.attachment_url}
                      className="box-title"
                    />
                    <p className="box-discription">{item.name}</p>
                  </div>
                );
              })}
            </div>

            <h3>Check-out attachments:</h3>
            <div className="flex-box">
              {checkoutImages.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => handleImageClick(index, "checkout")}
                    className="box-content"
                    key={index}
                  >
                    {/* <h4 className="box-title">HM</h4> */}
                    <img
                      src={ASSET_URL + "/public/" + item.attachment_url}
                      className="box-title"
                    />
                    <p className="box-discription">{item.name}</p>
                  </div>
                );
              })}
            </div>

            {trackingDetail && trackingDetail.early_checkout_reason ? (
              <>
                <h3>Early Check-out reason:</h3>
                <p>{trackingDetail.early_checkout_reason}</p>
              </>
            ) : (
              ""
            )}

            {trackingDetail.rating ? (
              <>
                <h3>Rating:</h3>
                <Rating customRating={() => { }} star={trackingDetail.rating} />
              </>
            ) : (
              ""
            )}

            {trackingDetail.review ? (
              <>
                <h3>Review</h3>
                <textarea
                  rows={6}
                  placeholder="Enter notes here..."
                  className="review-area"
                  disabled
                >
                  {trackingDetail.review}
                </textarea>
              </>
            ) : (
              ""
            )}
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DetailImage;
