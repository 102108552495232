import {
  IonHeader,
  IonPage,
  IonRow,
  IonCol,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonContent,
  IonIcon,
  IonTitle,
  IonToast,
  IonLoading,
  useIonViewWillEnter,
} from "@ionic/react";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import "./Dashboard.scss";
import { postCall } from "../ApiCalls";
import { BASE_URL } from "../BaseUrl";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Offers: React.FC = () => {
  let history = useHistory();
  let cookies = new Cookies();

  const [showToast, setShowToast] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<any>(true);
  const [toastMessage, setToastMessage] = useState<any>('');
  const [offers, setOffers] = useState<any>([]);

  useIonViewWillEnter(async () => {
    await postCall(BASE_URL + 'job-offers', {}).then(async (data: any) => {
      if(data.status){
        setOffers(data.data);
        setShowLoading(false);
      }
    });
  });

  const handleOfferAction = (id: any, action: any, index: any) => {
    setShowLoading(true);
    postCall(BASE_URL + 'job-offers/action', {
      id: id,
      status: action
    }).then(async (data: any) => {
      if(data.status){
        let all_offers = offers;
        all_offers.splice(index, 1);
        setOffers(all_offers);
        setToastMessage(data.message);
        setShowToast(true);
        setShowLoading(false);
      }
    });
  }

  const handleOfferClick = (item: any) => {
    // console.log(item);
    // let date = new Date();
    // date.setFullYear(date.getFullYear() + 1);
    // cookies.set("tlh_service_project_detail", {name: 'junaid'}, {
    //   path: "/",
    //   expires: date,
    //   sameSite: "lax",
    // });
    history.push("/page/offers-detail", {project: item});
  }

  return (
    <IonPage>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonContent className="bg-ligr">
        <IonHeader className="header-wrapper">
          <div className="job-header-wrapper-inner p-20 bg-texture-image">
            <IonRow className="row">
              <IonCol className="column" size="4">
                <IonButtons className="pl-6">
                  <IonMenuButton>
                    <IonImg
                      src={require("../images/menu.png")}
                      className="manuPicture"
                    />
                  </IonMenuButton>
                </IonButtons>
              </IonCol>
              <IonCol className="column" size="4">
                <IonTitle className="title-color">Offers</IonTitle>
                {/* <IonImg
                src={require("../images/climbing.png")}
                className="header-image"
              /> */}
              </IonCol>
              {/* <IonCol className="column" size="4"></IonCol> */}
            </IonRow>
          </div>
        </IonHeader>
        {offers.length > 0? 
          offers.map((item: any, index: any) => {
            return(
              <div className="offers" key={index} onClick={() => handleOfferClick(item)}>
                <div>
                  <h6>{item.job.title}</h6>
                  <p className="job-type">{item.job.job_type.name}</p>
                </div>
                <IonButtons>
                  <IonIcon color="success" icon={checkmarkOutline} onClick={() => {
                    handleOfferAction(item.id, 'ACCEPTED', index);
                  }} />
                  <IonIcon color="danger" icon={closeOutline} onClick={() => {
                    handleOfferAction(item.id, 'DECLINED', index);
                  }} />
                </IonButtons>
              </div>
            );
          })
         : 
          <p className="no-offer-txt">Sorry no offers yet</p>
          }
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};
export default Offers;