import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import {
  accessibility,
  addCircle,
  addCircleOutline,
  briefcase,
  business,
  calendar,
  call,
  camera,
  cash,
  chevronDown,
  closeCircle,
  closeCircleSharp,
  invertMode,
  key,
  logoFacebook,
  logoLinkedin,
  logoSkype,
  mailOpen,
  newspaper,
  person,
  personCircle,
  text,
  trash,
  trashOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { BASE_URL } from "../BaseUrl";
import axios from "axios";
import Cookies from "universal-cookie";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

const JobEmployeeSection: React.FC = () => {
  const cookies = new Cookies();
  const location = useLocation<{ data: any }>();
  const data: any = location.state?.data;
  let history = useHistory();

  const [jobEmployeeDetail, setJobEmployeeDetail] = useState<any>({});
  const [loading, setLoading] = useState<any>();
  const [company, setCompany] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState({
    from_time: "",
    to_time: "",
  });
  const [selectedValues, setSelectedValues] = useState<any>({
    employee_id: "",
    mode: "",
  });
  const [addEmployee, setAddEmployee] = useState({
    company_id: [],
    department_id: "",
    email: "",
    facebook: "",
    first_name: "",
    hourly_rate: "",
    last_name: "",
    linkedin: "",
    password: "",
    phone_no: "",
    skype: "",
    address: "",
    type: "staff",
  });

  const token: any = localStorage.getItem("access_token_tlh_service");
  const assignToEmployee = () => {
    setLoading(true);
    const url = BASE_URL + "admin/jobs/assign-employees";
    const employeeDetail: any = {
      job_id: data.job.id,
      employees,
    };
    axios
      .post(url, employeeDetail, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          // setEmployeeList(res.data.data);
          setMessage(res.data.message);
          setShowToast(true);
          history.push("/page/dashboard");
        } else {
          setMessage(res.data.message);
          setShowToast(true);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true; // To track if the component is still mounted
    let selected_company: any = localStorage.getItem("select_companies");
    setCompany(JSON.parse(selected_company));
    // Fetch employee list
    const getEmployeeList = async () => {
      setLoading(true);
      const url = "https://quote.tlhbuildings.com/api/users/staff";
      try {
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        );

        if (res.data.data && isMounted) {
          setEmployeeList(res.data.data);
        }
      } catch (error) {
        console.error("Error fetching employee list:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    // Fetch selected company and employee list
    const fetchData = () => {
      const selectedCompany = localStorage.getItem("select_companies");
      if (isMounted) {
        setCompany(JSON.parse(selectedCompany || "{}"));
        getEmployeeList();
      }
    };

    fetchData();

    // Cleanup function to set `isMounted` to false
    return () => {
      isMounted = false;
    };
  }, []);
  const getDepartments = () => {
    var url = "https://quote.tlhbuildings.com/api/departments/all";
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.data) {
          setDepartments(res.data.data);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };

  const openAdditionModal = () => {
    setLoading(true);
    setIsModalOpen(true);
    getDepartments();
  };

  const addEmployees = () => {
    setLoading(true);
    var url = "https://quote.tlhbuildings.com/api/users/store";
    axios
      .post(url, addEmployee, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setEmployeeList((prevEmployees: any) => [
            ...prevEmployees,
            res.data.data,
          ]);
          setMessage(res.data.message);
          setShowToast(true);
          setLoading(false);
          setIsModalOpen(false);
        } else {
          setMessage(res.data.message);
          setShowToast(true);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };

  const [employees, setEmployees] = useState<any>([
    {
      user_id: "",
      title: "",
      description: "",
      // starts_at: '',
      // ends_at: '',
      attachments: [],
    },
  ]);

  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number>(-1);
  const [dateType, setDateType] = useState<"starts_at" | "ends_at" | "">("");
  const addEmployeeSection = () => {
    setEmployees([
      ...employees,
      {
        user_id: "",
        title: "",
        description: "",
        // starts_at: '',
        // ends_at: '',
        attachments: [],
      },
    ]);
  };

  // Remove Employee
  const removeEmployee = (index: number) => {
    setEmployees(employees.filter((_: any, i: any) => i !== index));
  };

  // Handle Input Changes
  const handleEmployeeChange = (index: number, field: string, value: any) => {
    const updatedEmployees: any = [...employees];
    updatedEmployees[index][field] = value;
    setEmployees(updatedEmployees);
  };

  // Capture or Select Image
  const handleImageCapture = async (index: number) => {
    try {
      const image = await Camera.getPhoto({
        resultType: CameraResultType.Base64,
        source: CameraSource.Prompt, // Allows user to choose between Camera and Gallery
        quality: 90,
      });

      const base64String = `data:image/jpeg;base64,${image.base64String || ""}`;
      setEmployees((prevEmployees: any) => {
        const updatedEmployees: any = [...prevEmployees];
        updatedEmployees[index].attachments.push({ base64: base64String });
        return updatedEmployees;
      });
    } catch (error) {
      console.error("Image capture failed:", error);
    }
  };

  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";

    const formattedHours = String(hours % 12 || 12).padStart(2, "0"); // Convert 24-hour to 12-hour format

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${period}`;
  };

  const handleTimeChange = (
    event: any,
    index: number,
    dateType: "starts_at" | "ends_at"
  ) => {
    const selectedDate = new Date(event.detail.value);
    console.log(dateType);
    // Format the date using the formatDate function
    const formattedDate = formatDate(selectedDate);

    setEmployees((prevEmployees: any) => {
      const updatedEmployees = [...prevEmployees];
      const currentEmployee = updatedEmployees[index];

      if (dateType === "starts_at") {
        // Update starts_at and ensure ends_at is not earlier
        updatedEmployees[index] = {
          ...currentEmployee,
          starts_at: formattedDate,
          ends_at:
            currentEmployee.ends_at && formattedDate > currentEmployee.ends_at
              ? ""
              : currentEmployee.ends_at,
        };
      } else if (dateType === "ends_at") {
        // Update ends_at only if it's not before starts_at
        if (
          !currentEmployee.starts_at ||
          formattedDate >= currentEmployee.starts_at
        ) {
          updatedEmployees[index] = {
            ...currentEmployee,
            ends_at: formattedDate,
          };
        } else {
          setShowToast(true);
          setMessage("End time cannot be before the start time.");
        }
      }

      return updatedEmployees;
    });
  };

  const handleRemoveImage = (
    employeeIndex: number,
    attachmentIndex: number
  ) => {
    // Create a copy of the employees array
    const updatedEmployees = [...employees];

    // Get the specific employee
    const employee = updatedEmployees[employeeIndex];

    // Ensure that employee has attachments and attachments is an array
    if (Array.isArray(employee.attachments)) {
      // Remove the attachment at the specified index
      const updatedAttachments = [
        ...employee.attachments.slice(0, attachmentIndex),
        ...employee.attachments.slice(attachmentIndex + 1),
      ];

      // Update the employee's attachments array
      employee.attachments = updatedAttachments;

      // Update the state with the modified employees array
      setEmployees(updatedEmployees);
    }
  };
  const getCurrentDateTime = (): string => {
    const now = new Date();
    return now.toISOString(); // ISO format required for IonDatetime min/max
  };

  return (
    <IonPage>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar
          style={{ paddingBottom: "5px" }}
          className="quote_bg_primary ptb"
        >
          <div className="quote_header_text">
            <IonBackButton mode="md" defaultHref="/" />
            <h4>Job Employee Section</h4>
            <div className="extra_class"></div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList
          lines="none"
          class="ion-padding-horizontal ion-h-100 ion-overflow-y"
        >
          {employees.map((employee: any, index: any) => (
            <div key={index}>
              <IonItem>
                <IonIcon
                  slot="end"
                  onClick={() => removeEmployee(index)}
                  size="large"
                  color="danger"
                  icon={closeCircleSharp}
                />
              </IonItem>
              <IonItem
                lines="none"
                className="box_shadow br_20 quote_ion_input"
              >
                <IonIcon icon={text} className="ion-margin-end" />
                <IonInput
                  placeholder="Title"
                  value={employee.title}
                  onIonChange={(e) =>
                    handleEmployeeChange(index, "title", e.detail.value)
                  }
                />
              </IonItem>
              <div className="mt_25 ">
                {/* <IonItem>
                                    <IonIcon icon={addCircle} size="large" color="primary" slot="end" onClick={() => setIsModalOpen(true)} />
                                </IonItem> */}
                <IonItem
                  lines="none"
                  className="box_shadow br_20 quote_ion_input"
                >
                  <IonIcon icon={accessibility} className="ion-margin-end" />
                  <IonSelect
                    mode="ios"
                    placeholder="Select Employee"
                    interface="action-sheet"
                    value={employee.user_id}
                    onIonChange={(e) =>
                      handleEmployeeChange(index, "user_id", e.detail.value)
                    }
                  >
                    {employeeList.map((employee: any, index: any) => {
                      return (
                        <IonSelectOption key={index} value={employee?.id}>
                          {employee?.full_name}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                  <IonIcon icon={chevronDown} slot="end" />
                </IonItem>
              </div>
              {/* <div className="ion-d-flex ion-align-items-center">
                                <IonItem style={{ width: '50%', marginRight: '15px' }} lines="none" className="box_shadow br_20 mt_25 quote_ion_input" onClick={() => {
                                    setCurrentEmployeeIndex(index);
                                    setDateType('starts_at');
                                    setIsDateModalOpen(true);
                                }}>
                                    <IonIcon icon={calendar} className="ion-margin-end" />
                                    <IonLabel>{employee.starts_at || 'Start At'}</IonLabel>
                                </IonItem>
                                <IonItem style={{ width: '50%' }} lines="none" className="box_shadow br_20 mt_25 quote_ion_input" onClick={() => {
                                    setCurrentEmployeeIndex(index);
                                    setDateType('ends_at');
                                    setIsDateModalOpen(true);
                                }}>
                                    <IonIcon icon={calendar} className="ion-margin-end" />
                                    <IonLabel>{employee.ends_at || 'Ends At'}</IonLabel>
                                </IonItem>
                            </div> */}
              <IonItem
                lines="none"
                style={{ padding: "5px", marginRight: "15px" }}
                className="ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input"
              >
                <IonIcon
                  icon={newspaper}
                  className="ion-margin-top ion-margin-end"
                />
                <IonTextarea
                  rows={6}
                  className="review-area"
                  placeholder="Description"
                  value={employee.description}
                  onIonChange={(e) =>
                    handleEmployeeChange(index, "description", e.detail.value)
                  }
                />
              </IonItem>
              <div
                className="ion-d-flex ion-wrap ion-align-items-center ion-margin-vertical"
                style={{ position: "relative" }}
              >
                {employee.attachments.map(
                  (attachment: any, attachmentIndex: any) => (
                    <div
                      key={attachmentIndex}
                      style={{ position: "relative", width: "85px" }}
                      className="icon-box"
                    >
                      <IonImg
                        src={attachment.base64}
                        style={{ width: "100%", height: "100%", margin: "5px" }}
                      />
                      {/* Delete Icon */}
                      <div
                        className="delete-icon"
                        onClick={() =>
                          handleRemoveImage(index, attachmentIndex)
                        }
                        style={{
                          position: "absolute",
                          top: "-12px",
                          right: "-5px",
                          cursor: "pointer",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "5px",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IonIcon icon={trashOutline} />
                      </div>
                    </div>
                  )
                )}
                <div
                  style={{ width: "85px" }}
                  className="icon-box"
                  onClick={() => handleImageCapture(index)}
                >
                  <IonIcon className="Plus-icon" icon={addCircleOutline} />
                </div>
              </div>
            </div>
          ))}
          <div className="ion-d-flex ion-justify-content-end ion-align-items-center">
            <IonButton slot="end" mode="ios" onClick={addEmployeeSection}>
              <IonIcon icon={addCircle} />
              <IonLabel> Add more</IonLabel>
            </IonButton>
          </div>
          <IonModal
            isOpen={isDateModalOpen}
            onDidDismiss={() => setIsDateModalOpen(false)}
          >
            <IonHeader>
              <IonToolbar>
                <IonTitle>Select Date and Time</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setIsDateModalOpen(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonDatetime
                className="ion-max-w-100"
                presentation="date-time"
                min={getCurrentDateTime()}
                onIonChange={(e) =>
                  handleTimeChange(
                    e,
                    currentEmployeeIndex,
                    dateType as "starts_at" | "ends_at"
                  )
                }
                locale="en-GB-u-hc-h12"
              />
            </IonContent>
          </IonModal>
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar
          className=" ion-no-border ion-padding-vertical"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <IonButton onClick={assignToEmployee} expand="block" mode="ios">
            Submit
          </IonButton>
        </IonToolbar>
      </IonFooter>
      <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Employee</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsModalOpen(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList class="ion-h-100 ion-overflow-y">
            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={person} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="First Name"
                type="text"
                onIonChange={(e) =>
                  setAddEmployee({
                    ...addEmployee,
                    first_name: e.detail.value!,
                  })
                }
                value={addEmployee.first_name}
              />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={person} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="Last Name"
                type="text"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, last_name: e.detail.value! })
                }
                value={addEmployee.last_name}
              />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={mailOpen} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="Email"
                type="email"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, email: e.detail.value! })
                }
                value={addEmployee.email}
              />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={key} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="Password"
                type="password"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, password: e.detail.value! })
                }
                value={addEmployee.password}
              />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={call} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="Phone No"
                type="tel"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, phone_no: e.detail.value! })
                }
                value={addEmployee.phone_no}
              />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px", marginRight: "15px" }}
              className="ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={business} className="ion-margin-end" />
              <IonSelect
                multiple
                mode="ios"
                placeholder="Select a Company"
                interface="action-sheet"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, company_id: e.detail.value })
                }
                value={addEmployee.company_id}
              >
                {company.map((item: any, index: any) => (
                  <IonSelectOption key={index} value={item.id}>
                    {item.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <IonIcon className="ion-chevron-down" icon={chevronDown} />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px", marginRight: "15px" }}
              className="ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={business} className="ion-margin-end" />
              <IonSelect
                mode="ios"
                placeholder="Select a Department"
                interface="action-sheet"
                onIonChange={(e) =>
                  setAddEmployee({
                    ...addEmployee,
                    department_id: e.detail.value!,
                  })
                }
                value={addEmployee.department_id}
              >
                {departments.map((item: any, index: any) => {
                  return (
                    <IonSelectOption key={index} value={item.id}>
                      {item.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
              <IonIcon className="ion-chevron-down" icon={chevronDown} />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={logoFacebook} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="Facebook"
                type="text"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, facebook: e.detail.value! })
                }
                value={addEmployee.facebook}
              />
            </IonItem>

            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={cash} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="Hourly Rate"
                type="number"
                onIonChange={(e) =>
                  setAddEmployee({
                    ...addEmployee,
                    hourly_rate: e.detail.value!,
                  })
                }
                value={addEmployee.hourly_rate}
              />
            </IonItem>

            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={logoLinkedin} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="LinkedIn"
                type="text"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, linkedin: e.detail.value! })
                }
                value={addEmployee.linkedin}
              />
            </IonItem>

            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={logoSkype} className="ion-margin-end" />
              <IonInput
                style={{ marginTop: "5px" }}
                placeholder="Skype"
                type="text"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, skype: e.detail.value! })
                }
                value={addEmployee.skype}
              />
            </IonItem>

            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={personCircle} className="ion-margin-end" />
              <IonSelect
                mode="ios"
                placeholder="Type"
                interface="action-sheet"
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, type: e.detail.value! })
                }
                value={addEmployee.type}
              >
                <IonSelectOption value="staff">Staff</IonSelectOption>
                <IonSelectOption value="manager">Manager</IonSelectOption>
                <IonSelectOption value="admin">Admin</IonSelectOption>
              </IonSelect>
              <IonIcon className="ion-chevron-down" icon={chevronDown} />
            </IonItem>
            <IonItem
              lines="none"
              style={{ padding: "5px" }}
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon
                icon={newspaper}
                className="ion-margin-end ion-margin-top"
              />
              <IonTextarea
                rows={6}
                placeholder="Enter address here..."
                onIonChange={(e) =>
                  setAddEmployee({ ...addEmployee, address: e.detail.value! })
                }
                value={addEmployee.address}
              />
            </IonItem>
          </IonList>
        </IonContent>
        <IonFooter className="ion-no-border">
          <IonToolbar
            className=" ion-padding-vertical"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <IonButton onClick={addEmployees} expand="block" mode="ios">
              Submit
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      <IonToast
        mode="ios"
        color={"primary"}
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={message}
        duration={2000}
        position="bottom"
      />
    </IonPage>
  );
};
export default JobEmployeeSection;
