import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRadio,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonSpinner,
  useIonViewWillEnter,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { eyeOutline, radioButtonOff } from "ionicons/icons";
import "./TimeLine.scss";
import { useState } from "react";
import { arrowBack, radioButtonOn } from "ionicons/icons";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const TimeLine: React.FC = () => {
  let history = useHistory();

  const [jobDetail, setjobDetail] = useState<any>(cookies.get("job-detail"));
  const [loading, setLoading] = useState<any>(false);
  const [timelineData, setTimelineData] = useState<any>([]);

  useIonViewWillEnter(() => {
    setLoading(true);
    setjobDetail(cookies.get("job-detail"));
    let jobDetail = cookies.get("job-detail");
    let d = new Date();
    let month: any = d.getMonth();
    month = parseFloat(month);
    month = month + 1;
    let date = d.getFullYear() + "-" + month + "-" + d.getDate();

    const url = BASE_URL + "manager/job-timeline";
    axios
      .post(
        url,
        {
          job_id: jobDetail.job.id,
          date: date,
        },
        {
          headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
          },
        }
      )
      .then(async (res) => {
        if (res.data.status) {
          setLoading(false);
          setTimelineData(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <IonPage>
      <IonHeader className="Line-header">
        <div className="header1">
          <div className="back-btn-container">
            <IonIcon
              icon={arrowBack}
              onClick={() => {
                history.goBack();
              }}
              size="large"
              className="text-white"
            />
          </div>
          <div className="timeline-header">
            <h1 className="header-title1">Time Line</h1>
            {/* <h2 className='employee-title'>Total Employees</h2>
                  <p className='total-employee'>+150</p> */}
          </div>
        </div>
      </IonHeader>
      <IonContent>
        <div className="Employee">
          <h1 className="employee-title">Employees Worked</h1>
          <p className="total-employee">{timelineData.length}</p>
        </div>

        {loading ? (
          <div className="loader-container">
            <IonSpinner className="dash-spinner" name="crescent" />
          </div>
        ) : (
          timelineData.map((item: any, index: any) => {
            return(
              <>
                <div className="emp-box">
                  <h6>{item.job_employee.employee.full_name}</h6>
                </div>
                <div className="card-body">
                  <div className="timeline-label">
                    <div className="timeline-item">
                      <div className="network-connection">
                        <div className="timeline-badge">
                          <div className="radio-icon"></div>
                          {/* <IonRadio className="radio-icon" slot="start" /> */}
                          {/* <IonIcon className='Icon-color' icon={radioButtonOn}/> */}
                        </div>
                        <div className="Time-line-set">
                          <h3 className="job-time-heading">Start time</h3>
                          <p className="job-start-timing">Today at {item.starts_at_formatted}</p>
                          {/* <span className="time">9am - 5pm</span> */}
                        </div>
                      </div>

                      <button className="check-in">
                        <h4 className="check-in-title"> Check-in</h4>
                      </button>
                    </div>

                    <div className="timeline-item">
                      <div className="network-connection">
                        <div className="timeline-badge">
                          <div className="radio-icon"></div>
                          {/* <IonRadio className="radio-icon" slot="start" /> */}
                          {/* <IonIcon className='Icon-color' icon={radioButtonOn}/> */}
                        </div>
                        <div className="Time-line-set">
                          <h3 className="job-time-heading">End time</h3>
                          <p className="job-start-timing">Today at {item.ends_at_formatted? item.ends_at_formatted : '-'}</p>
                          {/* <span className="time">9am - 5pm</span> */}
                        </div>
                      </div>
                      {item.ends_at_formatted ? 
                          <button className="check-in">
                            <h4 className="check-in-title"> Check out</h4>
                          </button>
                        : 
                        ''
                      }
                    </div>
                  </div>
                </div>
              </>
            )
          })
          )
        }
      </IonContent>
    </IonPage>
  );
};

export default TimeLine;
