import {
  IonHeader,
  IonPage,
  IonRow,
  IonCol,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonContent,
  IonAvatar,
  IonIcon,
  IonButton,
  IonSlides,
  IonSlide,
  IonGrid,
  IonAlert,
  IonLoading,
  IonModal,
  IonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  Camera,
  CameraResultType,
  CameraSource,
  CameraDirection,
} from "@capacitor/camera";
import { starOutline, timeOutline, briefcaseOutline, hammerOutline, addCircleOutline, fileTray } from "ionicons/icons";
import { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./Dashboard.scss";
import Cookies from "universal-cookie";
import { Plugins, Capacitor } from "@capacitor/core";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/effect-cards";

// import "./styles.css";
// import required modules
import { EffectCards } from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { postCall } from "../ApiCalls";
import { BASE_URL } from "../BaseUrl";
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from "axios";

const JobHolderProfile: React.FC = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const location = useLocation();

  // const [professionals_category, setProfessionals_category] = useState<boolean>(true);
  // const [professionals_category_2, setProfessionals_category_2] = useState<boolean>(false);
  // const [professionals_category_3, setProfessionals_category_3] = useState<boolean>(false);

  let history = useHistory();
  const cookies = new Cookies();

  const [userData, setUserData] = useState<any>();
  const [professionalDetail, setProfessionalDetail] = useState<any>({
    first_name: '',
    last_name: '',
    short_description: '',
    average_rating: '',
    total_jobs_worked: '',
    total_hourly_jobs: '',
    total_fixed_jobs: '',
    about: '',
    portfolio: [],
    reviews: []
  });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [input, setInput] = useState<any>([]);
  const [alertHeader, setAlertHeader] = useState<any>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [type, setType] = useState<any>('about');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newProjectImages, setNewProjectImages] = useState<any>([]);
  const [newPortfolioTitle, setNewPortfolioTitle] = useState<any>('');
  const [portfolioActionType, setPortfolioActionType] = useState<any>('new');
  const [portfolioEditIndex, setPortfolioEditIndex] = useState<any>('');
  const [toastMessage, setToastMessage] = useState<any>('');
  const [showToast, setShowToast] = useState<boolean>(false);

  const professionals = useRef<any>(null);
  const about = useRef<any>(null);
  const portfolio = useRef<any>(null);
  const reviews = useRef<any>(null);
  const category_1 = useRef<any>(null);
  const category_2 = useRef<any>(null);
  const category_3 = useRef<any>(null);

  const IMAGE_DIR = 'stored-images';

  useIonViewWillLeave(() => {
    portfolio.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios mr-15';
    reviews.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios';
    about.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md active-btn mr-15';
    category_1.current.className = 'display'
    category_2.current.className = 'no-display';
    category_3.current.className = 'no-display';
    setProfessionalDetail({
      first_name: '',
      last_name: '',
      short_description: '',
      average_rating: '',
      total_jobs_worked: '',
      total_hourly_jobs: '',
      total_fixed_jobs: '',
      about: '',
      portfolio: [],
      reviews: []
    });
  });

  useIonViewWillEnter(async () => {
    setShowLoading(true);
    let details = {
      first_name: '',
      last_name: '',
      short_description: '',
      average_rating: '',
      total_jobs_worked: '',
      total_hourly_jobs: '',
      total_fixed_jobs: '',
      about: '',
      portfolio: [],
      reviews: []
    };
    await postCall(BASE_URL + 'professionals/jobs', {}).then(async (data: any) => {
      let user_data = cookies.get('tlh_service_user');
      setUserData(user_data);
    
      details.first_name = user_data.first_name;
      details.last_name = user_data.last_name;
      details.short_description = user_data.short_description;
      details.average_rating = data.professional_job_avg;
      details.total_jobs_worked = data.completed;
      details.total_hourly_jobs = data.hourly;
      details.total_fixed_jobs = data.fixed;
      details.about = user_data.about;
      
      // setProfessionalDetail({
      //   ...professionalDetail,
      //   first_name: user_data.first_name,
      //   last_name: user_data.last_name,
      //   short_description: user_data.short_description,
      //   about: user_data.about,
      //   average_rating: data.professional_job_avg,
      //   total_jobs_worked: data.completed,
      //   total_hourly_jobs: data.hourly,
      //   total_fixed_jobs: data.fixed
      // });
      await postCall(BASE_URL + 'professional-reviews', {}).then(async (data: any) => {
        if(data.status){
          details.reviews = data.data;
        }
      });
      await postCall(BASE_URL + 'professional-projects', {}).then(async (data: any) => {
        if(data.status){
          details.portfolio = data.data;
        }
      });
    });
    setProfessionalDetail(details);
    loadFiles();
    if(about.current){
      about.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios mr-15 active-btn';
    }
  });

  const loadFiles = async () => {
    Filesystem.readdir({
      directory: Directory.Data,
      path: IMAGE_DIR
    }).then(result => {
      if(result.files.length <= 0){
        setShowLoading(false);
      }
      loadFileData(result.files);
    }, async err => {
      console.log('err: ', err);
      await Filesystem.mkdir({
        directory: Directory.Data,
        path: IMAGE_DIR
      })
    }).then(_ => {
      setShowLoading(false);
    })
  }

  const loadFileData = async (fileNames: any) => {
    let new_project_images = [];
    for await (let f of fileNames) {
      const filePath = `${IMAGE_DIR}/${f.name}`;
      const readFile = await Filesystem.readFile({
        directory: Directory.Data,
        path: filePath
      });

      let project_image = {
        name: f.name,
        path: filePath,
        data: `data:image/jpeg;base64,${readFile.data}`
      }
      new_project_images.push(project_image);
    }
    setNewProjectImages(new_project_images);
    setShowLoading(false);
  }

  const btnClick = async (type: any) => {
    await setType(type);
    about.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios mr-15';
    portfolio.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios mr-15';
    reviews.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios';
   
    if (type === 'about') {
      about.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md active-btn mr-15';
      category_1.current.className = 'display'
      category_2.current.className = 'no-display';
      category_3.current.className = 'no-display';
    }
    else if (type === 'portfolio') {
      portfolio.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md active-btn mr-15';
      category_3.current.className = 'no-display';
      category_2.current.className = 'display';
      category_1.current.className = 'no-display';
      professionals.current.className = 'field-information';
    }
    else if (type === 'reviews') {
      reviews.current.className = 'sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios sc-ion-buttons-md-h sc-ion-buttons-md-s md active-btn';
      category_3.current.className = 'display reviews-detail';
      category_2.current.className = 'no-display';
      category_1.current.className = 'no-display';
      professionals.current.className = 'overflow-width field-information';
    }
  }

  const handleInputUpdate = (alertData: any) => {
    if (type === 'professional-bio') {
      setShowLoading(true);
      postCall(BASE_URL + 'professionals/update', {
        first_name: alertData.first_name,
        last_name: alertData.last_name,
        short_description: alertData.short_description
      }).then(async (data: any) => {
        if (data.status) {
          setProfessionalDetail({
            ...professionalDetail,
            first_name: alertData.first_name,
            last_name: alertData.last_name,
            short_description: alertData.short_description
          });
          let user_data = cookies.get('tlh_service_user');
          user_data.first_name = alertData.first_name;
          user_data.last_name = alertData.last_name;
          user_data.short_description = alertData.short_description;
          cookies.set('tlh_service_user', user_data);
          setShowLoading(false);
        }
      });
    }else if(type === 'about'){
      setShowLoading(true);
      postCall(BASE_URL + 'professionals/about-update', {
        about: alertData.about
      }).then(async (data: any) => {
        if (data.status) {
          setProfessionalDetail({
            ...professionalDetail,
            about: alertData.about
          });
          let user_data = cookies.get('tlh_service_user');
          user_data.about = alertData.about;
          cookies.set('tlh_service_user', user_data);
          setShowLoading(false);
        }
      });
    }else if(type === 'reviews'){
      setShowLoading(true);
      postCall(BASE_URL + 'professional-reviews/save', {
        review: alertData.review,
        rating: alertData.rating_stars,
        project_title: alertData.project_title,
        professional_id: userData.id,
        review_by: alertData.review_by
      }).then(async (data: any) => {
        if (data.status) {
          await postCall(BASE_URL + 'professional-reviews', {}).then(async (data: any) => {
            if(data.status){
              setProfessionalDetail({
                ...professionalDetail,
                reviews: data.data
              });
              setShowLoading(false);
            }
          });
        }
      });
    }
  }

  const handleProfessionalDetailEdit = (type: any) => {
    let arr: any;
    if (type === 'professional-bio') {
      arr = [
        {
          name: 'first_name',
          type: 'text',
          placeholder: 'first name',
          value: professionalDetail.first_name
        },
        {
          name: 'last_name',
          type: 'text',
          placeholder: 'last name',
          value: professionalDetail.last_name
        },
        {
          name: 'short_description',
          type: 'text',
          placeholder: 'profession',
          value: professionalDetail.short_description
        }
      ];
      setType(type);
      setAlertHeader('Edit Information');
    }else if(type === 'bottom'){
      if(about.current.className.includes("active-btn")){
        type = 'about';
        arr = [
          {
            name: 'about',
            type: 'textarea',
            placeholder: 'About yourself...',
            value: professionalDetail.about
          }
        ];
        setAlertHeader('Edit About');
        setType(type);
      }else if(portfolio.current.className.includes("active-btn")){
        type = 'portfolio';
        setPortfolioActionType('new');
        setShowModal(true);
      }else if(reviews.current.className.includes("active-btn")){
        type = 'reviews';
        arr = [
          {
            name: 'review',
            type: 'textarea',
            placeholder: 'Write experience',
            value: ''
          },
          {
            name: 'rating_stars',
            placeholder: 'Number of rating stars',
            type: 'number',
            min: '1',
            max: '5',
            value: ''
          },
          {
            name: 'review_by',
            type: 'text',
            placeholder: 'Review by',
            value: ''
          },
          {
            name: 'project_title',
            type: 'text',
            placeholder: 'Project title',
            value: ''
          },
        ];
        setAlertHeader('Add a new review');
        setType(type);
      }
    }

    if(type !== 'portfolio'){
      setInput(arr);
      // setType(type);
      setShowAlert(true);
    }
  }
  const imgClick = (index: any) => {
    about.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios active-btn';
    portfolio.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios mr-15';
    reviews.current.className = 'sc-ion-buttons-md-h sc-ion-buttons-md-s md sc-ion-buttons-ios-h sc-ion-buttons-ios-s ios';
    cookies.set('tlh_service_single_portfolio', professionalDetail.portfolio[index]);
    history.push("/page/portfoliodetail");
    category_1.current.className = 'display'
    category_2.current.className = 'no-display';
    category_3.current.className = 'no-display';
  }

  const handleDeleteReview = (id:any, index: any) => {
    setShowLoading(true);
    postCall(BASE_URL + 'professional-reviews/delete', {
      id: id
    }).then(async (data: any) => {
      if(data.status){
        let professional_detail = professionalDetail;
        professional_detail.reviews.splice(index, 1);
        setProfessionalDetail(professional_detail);
        setShowLoading(false);
      }
    });
  }

  const handleImageClick = (index:any) =>{

  }

  const handleCamera = (type = '') => {
    Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: type === 'professional'? CameraResultType.Base64 : CameraResultType.Uri,
      source: type === 'professional'? CameraSource.Prompt : CameraSource.Photos,
    })
    .then((cameraResult: any) => {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      if(type === 'professional'){
        setShowLoading(true);
        postCall(BASE_URL + 'professionals/image-update', {
          image: cameraResult.base64String
        }).then(async (data: any) => {
          if(data.status){
            setToastMessage(data.message);
            let user_data = userData;
            // user_data.profile_pic_url = 'data:image/jpeg;base64,' + cameraResult.base64String;
            user_data.profile_pic_url = data.path;
            setUserData(user_data);
            cookies.set('tlh_service_user', user_data);
            setShowLoading(false);
            setShowToast(true);
          }
        }).catch((err) => {
          console.log(err);
          setShowLoading(false);
        });
      }else{
        saveImage(cameraResult);
      }
    })
    .catch((er: any) => {
      console.log(er);
    });
  }

  const saveImage = async (photo:any) => {
    const base64Data = await readAsBase64(photo);
    const fileName = new Date().getTime() + '.jpeg';
    const savedFile = await Filesystem.writeFile({
      directory: Directory.Data,
      path: `${IMAGE_DIR}/${fileName}`,
      data: base64Data
    }).then(() => {
      loadFiles();
    });
  }

  const readAsBase64 = async (photo: any) => {
    if (Capacitor.platform !== "web") {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: photo.path
      });
      return file.data;
    }
    else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath);
      const blob = await response.blob();
      return await convertBlobToBase64(blob) as string;
    }
  }

  const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  const handleSinglePortfolioImageRemove = async (path: any) => {
    await Filesystem.deleteFile({
      directory: Directory.Data,
      path: path
    });
    loadFiles();
  }

  const handleSavePortfolio = async () => {
    if(newPortfolioTitle === '' || !newPortfolioTitle){
      setToastMessage('Please add some title');
      setShowToast(true);
      return;
    }else if(newProjectImages.length <= 0){
      setToastMessage('Please add some pictures');
      setShowToast(true);
      return;
    }

    setShowLoading(true);
    const formData = new FormData();
    formData.append('title', newPortfolioTitle);
    for await(let item of newProjectImages){
      const response = await fetch(item.data);
      const blob = await response.blob();
      formData.append('attachment[]', blob, item.name);
    }

    const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.get('access_token_tlh_service')}`
        }
    };
    axios
    .post(BASE_URL + 'professional-projects/save', formData, config)
    .then(async (res: any) => {
        if(res.data.status){
          setNewPortfolioTitle('');
          setToastMessage(res.data.message);
          for await (let item of newProjectImages){
            await Filesystem.deleteFile({
              directory: Directory.Data,
              path: item.path
            });
          }
          loadFiles();
          await postCall(BASE_URL + 'professional-projects', {}).then(async (data: any) => {
            if(data.status){
              let professional_detail = professionalDetail;
              professional_detail.portfolio = data.data;
              setProfessionalDetail(professional_detail);
              setShowLoading(false);
              setShowModal(false);
              setShowToast(true);
            }
          });
        }
    })
    .catch((error: any) => {
        console.log(error);
    });
  }

  const handlePortfolioProjectEdit = (id: any, index: any) => {
    let professional_detail = professionalDetail;
    professional_detail.portfolio[index].duplicate_title = professional_detail.portfolio[index].title;
    setProfessionalDetail(professional_detail);
    setPortfolioActionType('edit');
    setPortfolioEditIndex(index);
    setShowModal(true);
  }

  const handlePortfolioProjectRemove = (id: any, index: any) => {
    setShowLoading(true);
    postCall(BASE_URL + 'professional-projects/delete', {
      id: id
    }).then(async (data: any) => {
      if(data.status){
        let professional_detail = professionalDetail;
        professional_detail.portfolio.splice(index, 1);
        setProfessionalDetail(professional_detail);
        setShowLoading(false);
      }
    });
  }

  const handleRemoveSinglePortfolioProjectImage = (id: any, index: any) => {
    setShowLoading(true);
    postCall(BASE_URL + 'professional-projects/attachment/delete', {
      id: id
    }).then(async (data: any) => {
      if(data.status){
        let professional_detail = professionalDetail;
        professional_detail.portfolio[portfolioEditIndex].attachment.splice(index, 1);
        setProfessionalDetail(professional_detail);
        setShowLoading(false);
      }
    });
  }

  const handleProfessionalImageChange = () => {
    handleCamera('professional');
  }

  const handleUpdatePortfolio = async () => {
    if(professionalDetail.portfolio[portfolioEditIndex].duplicate_title === '' || !professionalDetail.portfolio[portfolioEditIndex].duplicate_title){
      setToastMessage('Please add some title');
      setShowToast(true);
      return;
    }
    // else if(newProjectImages.length <= 0){
    //   setToastMessage('Please add some pictures');
    //   setShowToast(true);
    //   return;
    // }

    setShowLoading(true);
    const formData = new FormData();
    formData.append('id', professionalDetail.portfolio[portfolioEditIndex].id);
    formData.append('title', professionalDetail.portfolio[portfolioEditIndex].duplicate_title);
    if(newProjectImages.length > 0){
      for await(let item of newProjectImages){
        const response = await fetch(item.data);
        const blob = await response.blob();
        formData.append('attachment[]', blob, item.name);
      }
    }

    const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.get('access_token_tlh_service')}`
        }
    };
    axios
    .post(BASE_URL + 'professional-projects/update', formData, config)
    .then(async (res: any) => {
      if(res.data.status){
        setToastMessage(res.data.message);
        for await (let item of newProjectImages){
          await Filesystem.deleteFile({
            directory: Directory.Data,
            path: item.path
          });
        }
        loadFiles();
        setShowModal(false);
        await postCall(BASE_URL + 'professional-projects', {}).then(async (data: any) => {
          if(data.status){
            let professional_detail = professionalDetail;
            professional_detail.portfolio = data.data;
            setProfessionalDetail(professional_detail);
            setShowLoading(false);
            setShowToast(true);
          }
        });
      }
    })
    .catch((error: any) => {
        console.log(error);
    });
  }

  return (
    <IonPage>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass='input-alert'
        header={alertHeader}
        inputs={input}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {

            }
          },
          {
            text: 'Ok',
            handler: (alertData) => {
              handleInputUpdate(alertData);
            }
          }
        ]}
      />

      <IonModal id="example-modal" isOpen={showModal} onIonModalDidDismiss={async ()=> {
        for await (let item of newProjectImages){
          await Filesystem.deleteFile({
            directory: Directory.Data,
            path: item.path
          });
        }
        loadFiles();
        setNewPortfolioTitle('');
        setShowModal(false);
        }}>
        <div className="wrapper">
          <h4><b>{portfolioActionType === 'new'? 'Add' : 'Edit'} Portfolio</b></h4>
          <input type="text" placeholder="project title" defaultValue={portfolioActionType === 'new'? newPortfolioTitle : professionalDetail.portfolio[portfolioEditIndex].duplicate_title} onChange={(e)=>{
              if(portfolioActionType === 'new'){
                setNewPortfolioTitle(e.target.value);
              }else{
                let professional_detail = professionalDetail;
                professional_detail.portfolio[portfolioEditIndex].duplicate_title = e.target.value;
                setProfessionalDetail(professional_detail);
              }
            }}/>
          <div className="flex-box  mt_25">
            {portfolioActionType === 'new'? 
                newProjectImages.map((item: any, index: any) => {
                  return (
                    <div
                      onClick={() => handleImageClick(index)}
                      className="box-content"
                      key={index}
                    >
                      <IonImg className="remove-portfolio-img" onClick={() => handleSinglePortfolioImageRemove(item.path)} src={require("../images/remove.png")} />
                      <img src={item.data} alt={index} className="box-title" />
                      <p className="box-discription">{item.name}</p>
                    </div>
                  );
                })
              :
              professionalDetail.portfolio[portfolioEditIndex].attachment.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => handleImageClick(index)}
                    className="box-content"
                    key={index}
                  >
                    {index === 0? '': <IonImg className="remove-portfolio-img" onClick={() => {
                      handleRemoveSinglePortfolioProjectImage(item.id, index);
                    }} src={require("../images/remove.png")} />}
                    <img src={item.attachment_url} alt={index} className="box-title" />
                    <p className="box-discription">{item.file_name}</p>
                  </div>
                );
              })
            }
            {portfolioActionType !== 'new'?
              newProjectImages.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => handleImageClick(index)}
                    className="box-content"
                    key={index}
                  >
                    <IonImg className="remove-portfolio-img" onClick={() => handleSinglePortfolioImageRemove(item.path)} src={require("../images/remove.png")} />
                    <img src={item.data} alt={index} className="box-title" />
                    <p className="box-discription">{item.name}</p>
                  </div>
                );
              })
              :
              ''
            }
            <div className="icon-box" onClick={() => {
              handleCamera();
            }}>
              <IonIcon className="Plus-icon" icon={addCircleOutline} />
            </div>
            </div>
            <IonButtons className="ion-btn-group">
              <IonButton className="ion-text-capitalize active-btn new-portfolio-btn"
                onClick={() => {
                  if(portfolioActionType === 'new'){
                    handleSavePortfolio();
                  }else{
                    // setNewProjectImages
                    handleUpdatePortfolio();
                  }
                }}
              >{portfolioActionType === 'new'? 'Save' : 'Update'}</IonButton>
            </IonButtons>
        </div>
      </IonModal>

      <IonContent className="bg-ligr">
        <IonHeader className="header-wrapper ">
          <div className="job-header-wrapper-inner bg-texture-image pb-70">
            <IonRow className="row">
              <IonCol className="column" size="12">
                <IonButtons className="padding-b-30">
                  <IonMenuButton>
                    <IonImg
                      src={require("../images/menu.png")}
                      className="manuPicture"
                    />
                  </IonMenuButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          </div>
        </IonHeader>
        <div className="person-detail">
          <IonImg className="edit-info-icon" onClick={() => handleProfessionalDetailEdit('professional-bio')} src={require("../images/pencil.png")} />
          <div className="person-data">
            <div className="person-img">
              <IonAvatar>
                <IonImg src={userData? userData.profile_pic_url : require("../images/istock.jpg")} />
              </IonAvatar>
              <IonImg className="edit-icon" src={require("../images/pencil.png")} onClick={()=>handleProfessionalImageChange()} />
            </div>
            <div className="person-name-profession">
              <h5>{professionalDetail.first_name + ' ' + professionalDetail.last_name}</h5>
              <p>{!professionalDetail.short_description || professionalDetail.short_description === '' ? 'Your Profession Here' : professionalDetail.short_description}</p>
            </div>
            {/* <div className="hour-payment">
              <h4>$25/hrs</h4>
            </div> */}
          </div>
        </div>
        <IonGrid className="person-working-detail ion-padding">
          <IonRow>
            <IonCol size="6">
              <div className="ion-text-center">
                <div className="performance">
                  <IonIcon icon={starOutline} size="large" />
                  <h3>{professionalDetail.average_rating === 0? '--' : professionalDetail.average_rating}</h3>
                </div>
                <p>average rating</p>
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="ion-text-center">
                <div className="performance">
                  <IonIcon icon={briefcaseOutline} size="large" />
                  <h3>{professionalDetail.total_jobs_worked > 9999 ? '9999+' : professionalDetail.total_jobs_worked === 0? '--' : professionalDetail.total_jobs_worked}</h3>
                </div>
                <p>total jobs worked</p>
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="ion-text-center">
                <div className="performance">
                  <IonIcon icon={timeOutline} size="large" />
                  <h3>{professionalDetail.total_hourly_jobs > 5000 ? '5000+' : professionalDetail.total_hourly_jobs === 0? '--' : professionalDetail.total_hourly_jobs}</h3>
                </div>
                <p>total hourly jobs</p>
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="ion-text-center">
                <div className="performance">
                  <IonIcon icon={hammerOutline} size="large" />
                  <h3>{professionalDetail.total_fixed_jobs > 7500 ? '7500+' : professionalDetail.total_fixed_jobs === 0? '--' : professionalDetail.total_fixed_jobs}</h3>
                </div>
                <p>total fixed jobs</p>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButtons className="ion-btn-group">
          <IonButton ref={about} className="ion-text-capitalize mr-15"
            onClick={() => btnClick("about")}
          >about</IonButton>
          <IonButton ref={portfolio} className="ion-text-capitalize mr-15"
            onClick={() => btnClick("portfolio")}
          >portfolio</IonButton>
          <IonButton ref={reviews} className="ion-text-capitalize"
            onClick={() => btnClick("reviews")}
          >reviews</IonButton>
        </IonButtons>
        <div className="position-relative">
          {type === 'about' || type === 'professional-bio'? 
              <IonImg className="edit-info-icon" src={require("../images/pencil.png")} onClick={()=>handleProfessionalDetailEdit('bottom')} />
            :
            type === 'portfolio' || type === 'reviews'?
              <IonImg className="edit-info-icon" src={require("../images/plus.png")} onClick={()=>handleProfessionalDetailEdit('bottom')} />
            :
            ''
          }
          <div ref={professionals} className="field-information">
            <div ref={category_1} className='display'>
              <p>{!professionalDetail.about || professionalDetail.about === ''? "Write something about yourself..." : professionalDetail.about}</p>
            </div>
            <div ref={category_2} className=" no-display project-detail-images">
              {/* <Swiper
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            > */}
              {professionalDetail.portfolio.length > 0?
                ""
                :
                <p className="add-portfolio">Add portfolio</p>  
              }
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
              >
                {professionalDetail.portfolio.map((item:any, index:any)=>{
                  return(
                    <SwiperSlide key={index}>
                      <IonImg className="edit-portfolio-icon" src={require("../images/pencil.png")} onClick={()=>handlePortfolioProjectEdit(item.id, index)} />
                      <IonImg className="remove-portfolio-icon" src={require("../images/delete-bin.png")} onClick={()=>handlePortfolioProjectRemove(item.id, index)} />
                      <div className="slide-inner" onClick={()=>imgClick(index)}>
                        <img src={item.attachment[0].attachment_url} alt={index} />
                        <h4 className="project-title"><b>{item.title}</b></h4>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div ref={category_3} className="reviews-detail no-display">
              {professionalDetail.reviews.length > 0? 
                  ""
                :
                  <p>Add some reviews...</p>
                }
              {professionalDetail.reviews.map((item:any, index:any)=>{
                let rating = item.rating > 5? 5 : item.rating;
                return(
                  <div className="review-box" key={index}>
                    <IonImg className="delete-img" src={require("../images/delete.png")} onClick={() => handleDeleteReview(item.id, index)} />
                    <IonImg className="quote-img" src={require("../images/image-quote.png")} />
                    <p>
                      " {item.review} "
                    </p>
                    <div className="rating-sender">
                      <div className="rating-star">
                        {[...Array(rating)].map((item:any, index:any)=>{
                          return(
                            <IonImg key={index} src={require("../images/star.png")} />
                          );
                        })}
                      </div>
                      <h6>{item.review_by}</h6>
                      <h5>{item.project_title}</h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};
export default JobHolderProfile;