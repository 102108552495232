import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonIcon,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  eye,
  homeOutline,
  arrowBack,
  calendarOutline,
  timerOutline,
} from "ionicons/icons";
import "./Dashboard.scss";
import "./JobTrackings.scss";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const cookies = new Cookies();

const JobTrackings: React.FC = () => {
  let history = useHistory();
  let location: any = useLocation();

  const [loading, setLoading] = useState(true);
  const [usersTrackings, setUsersTrackings] = useState([]);
  const [userTracking, setUserTracking] = useState<any>("");
  const [userData, setUserData] = useState<any>(localStorage.getItem("tlh_service_user"));
  const [jobDetail, setjobDetail] = useState<any>(cookies.get("job-detail"));

  useIonViewWillEnter(() => {
    setLoading(true);
    // var jobdetail = cookies.get("job-detail");
    var jobdetail = location.state.job_detail;
    setjobDetail(jobdetail);
    const logged_user_data: any = localStorage.getItem("tlh_service_user");
    let user_role = JSON.parse(logged_user_data);
    if (user_role.role.id === 1) {
      const url =
        BASE_URL + `admin/${jobdetail.job.id}/job-employees-trackings-today`
      axios
        .get(url, {
          headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
          },
        })
        .then(async (res) => {
          if (res.data.status) {
            setLoading(false);
            setUsersTrackings(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const url =
        BASE_URL +
        "manager/" +
        jobdetail.job.id +
        "/job-employees-trackings-today";
      axios
        .get(url, {
          headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
          },
        })
        .then(async (res) => {
          if (res.data.status) {
            setLoading(false);
            setUsersTrackings(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  const handleTrackingClick = (tracking: any) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    localStorage.setItem("tlh_service_tracking_detail", JSON.stringify(tracking));

    // cookies.set("tlh_service_tracking_detail", tracking, {
    //   path: "/",
    //   expires: date,
    //   sameSite: "lax",
    // });
    history.push("/page/single-tracking");
  };

  return (
    <IonPage>
      <IonHeader className="history-header">
        <IonRow className="row">
          <IonCol className="column" size="12">
            <div className="flex">
              <IonIcon
                icon={arrowBack}
                onClick={() => {
                  history.goBack();
                }}
                size="large"
                className="text-white"
              />
              <h3>Job History</h3>
              <IonIcon
                icon={timerOutline}
                size="large"
                className="text-white calendar-icon"
                onClick={() => {
                  history.push("/page/time-line");
                }}
              />
            </div>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className="wrapper">
        {loading ? (
          <div className="loader-container">
            <IonSpinner className="dash-spinner" name="crescent" />
          </div>
        ) : (
          <IonGrid>
            <IonRow className="row">
              {usersTrackings.map((item: any, index: any) => {
                return (
                  <IonCol
                    key={index}
                    onClick={() => handleTrackingClick(item)}
                    className="column"
                    size="12"
                  >
                    <IonImg
                      src={
                        item.job_employee.employee.profile_pic_url
                          ?
                          item.job_employee.employee.profile_pic_url
                          : require("../images/dummy-image.jpg")
                      }
                    />
                    <div className="job-description">
                      <span className="card-timing">{item.job_employee.employee.full_name}</span>
                      <span className="address">
                        Started at:
                        {item.starts_at_formatted
                          ? item.starts_at_formatted
                          : ""}
                      </span>
                      <span className="area">
                        Ended at:{" "}
                        {item.ends_at_formatted ? item.ends_at_formatted : ""}
                      </span>
                      {item.review ? (
                        <span className="rating-box">
                          <img
                            className="rating-img"
                            src={require("../images/check-mark.png")}
                          />{" "}
                          reviewed
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </IonCol>
                );
              })}
              {usersTrackings.length <= 0 ? (
                <div className="no-data">
                  <img src={require("../images/sad.png")} />
                  <h4>No data available at the moment!</h4>
                </div>
              ) : (
                ""
              )}
            </IonRow>
          </IonGrid>
        )}
        {/* <RatingStar/> */}
      </IonContent>
    </IonPage>
  );
};
export default JobTrackings;
