import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonSpinner,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import { BASE_URL } from "../BaseUrl";
import { Capacitor } from "@capacitor/core";
import { ActionPerformed } from "@capacitor/local-notifications";
import {
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import { create, eye } from "ionicons/icons";

const JobsList: React.FC = () => {
  const cookies = new Cookies();
  let history = useHistory();
  const location: any = useLocation<{ status: any }>();
  let logged_user_data: any = localStorage.getItem("tlh_service_user");

  const [statusJob, setStatusJob] = useState<any>(false);
  const [userData, setUserData] = useState<any>(JSON.parse(logged_user_data));
  const [loading, setLoading] = useState<any>(true);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [userJobs, setUserJobs] = useState([]);

  useIonViewWillEnter(() => {
    setLoading(true);
    let url;
    let user_role_id: any = JSON.parse(logged_user_data);
    user_role_id = user_role_id.role.id;

    // if (user_role_id == "6") {
    //     url = BASE_URL + "manager/jobs/active";
    //     //url = BASE_URL + "manager/103/job-employees-trackings-today";
    //     axios
    //         .get(url, {
    //             headers: {
    //                 Authorization: `Bearer ${cookies.get(
    //                     "access_token_tlh_service"
    //                 )}`,
    //             },
    //         })
    //         .then(async (res) => {
    //             if (res.data.status) {
    //                 setUserJobs(res.data.data);
    //                 setLoading(false);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // } else if (user_role_id == "3" || user_role_id == "7") {
    //     url = BASE_URL + "staff/jobs";
    //     axios
    //         .get(url, {
    //             headers: {
    //                 Authorization: `Bearer ${cookies.get(
    //                     "access_token_tlh_service"
    //                 )}`,
    //             },
    //         })
    //         .then(async (res) => {
    //             if (res.data.status) {
    //                 setLoading(false);
    //                 setUserJobs(res.data.data);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // } else if (user_role_id == "4") {
    //     url = BASE_URL + "customer/jobs";
    //     // url = BASE_URL + "customer/quotation-requests";
    //     axios
    //         .post(url, {
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${cookies.get(
    //                     "access_token_tlh_service"
    //                 )}`,
    //             },
    //         }
    //         )
    //         .then(async (res) => {
    //             if (res.data.status) {
    //                 setLoading(false);
    //                 setUserJobs(res.data.data);
    //             } else {
    //                 setLoading(false);
    //                 setUserJobs([]);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // } else if (user_role_id === 1) {
    // }
    getJobs(user_role_id);
  });

  const getJobs = (user_role_id: any) => {
    localStorage.setItem("adminjobStatus", JSON.stringify(location?.state.status));
    let jobstatus: any = localStorage.getItem("adminjobStatus");

    const jobStatus: any = location?.state?.status
      ? location.state.status
      : JSON.parse(jobstatus);

    let jobFilter: any;
    if (jobStatus === "completed") {
      jobFilter = `admin/jobs/${jobStatus}`;
    } else if (jobStatus === "active") {
      jobFilter = `admin/jobs/active`;
    } else if (jobStatus === "awaiting_approval_of_admin") {
      jobFilter = `admin/jobs/awaiting_approval_of_admin`;
    } else if (jobStatus === "submitted") {
      jobFilter = `admin/jobs/submitted`;
    } else if (jobStatus === "pending") {
      if (user_role_id === 1) {
        jobFilter = `admin/jobs/pending`;
      } else {
        jobFilter = `staff/sub-jobs/pending`;
      }
      setStatusJob(true);
    } else if (jobStatus === "active-employee") {
      jobFilter = `staff/sub-jobs/active`;
    } else if (jobStatus === "completed-employee") {
      jobFilter = `staff/sub-jobs/completed`;
    } else if (jobStatus === "awaiting_approval_of_staff") {
      jobFilter = `staff/sub-jobs/awaiting_approval_of_admin`;
    } else if (jobStatus === "rejected_by_admin") {
      jobFilter = `staff/sub-jobs/rejected_by_admin`;
    } else {
      jobFilter = `admin/jobs`;
      setStatusJob(true);
    }

    let url = BASE_URL + jobFilter;
    let isMounted = true;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access_token_tlh_service") || "null"
          )}`,
        },
      })
      .then((res) => {
        if (isMounted) {
          // Only update state if component is still mounted
          setLoading(false);
          if (res.data.status) {
            setUserJobs(res.data.data);
          }
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error(error);
        }
      });

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  };

  const handleJobClick = (job: Object, type: any) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    localStorage.setItem("job-detail", JSON.stringify(job));
    // if (type == "staff" || type == "professional") {
    //     history.push("/page/job-detail", { job_detail: job });
    // } else {
    history.push('/employee-detail', { data: job, type });
    // history.push("/admin-job-tracking", { job_detail: job });
    // }
  };

  const updateStatus = (event: any, status: any, id: any) => {
    setLoading(true);
    event?.preventDefault();
    event.stopPropagation();

    // Initialize url as empty
    let url: string | undefined = "";

    // Check if the user has the role ID of 1
    if (userData.role.id === 1) {
      if (status === "approve") {
        url = `https://quote.tlhbuildings.com/api/app/admin/job-employees/${id}/approve`;
      } else if (status == "rejected") {
        url = `https://quote.tlhbuildings.com/api/app/admin/job-employees/${id}/reject`;
      }
    } else {
      // For other role IDs, check for pending or active status
      if (status === "pending") {
        url = `https://quote.tlhbuildings.com/api/app/staff/sub-jobs/${id}/accept`;
      } else if (status === "rejected") {
        url = `https://quote.tlhbuildings.com/api/app/staff/sub-jobs/${id}/reject`;
      } else if (status === "active") {
        url = `https://quote.tlhbuildings.com/api/app/staff/sub-jobs/${id}/complete`;
      }
    }

    // Only proceed if a valid URL was set
    if (url) {
      axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("access_token_tlh_service") || "null"
              )}`,
            },
          }
        )
        .then((res: any) => {
          if (res.data.status) {
            setLoading(false);
            setMessage(res.data.message);
            setShowToast(true);
            history.push("/page/dashboard");
          } else {
            setLoading(false);
            setShowToast(true);
            setMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // console.error("No valid URL set for this action.");
      setLoading(false);
      setShowToast(true);
      setMessage("Invalid action or status for the current role.");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          style={{ paddingBottom: "5px" }}
          className="quote_bg_primary ptb"
        >
          <div className="quote_header_text">
            <IonBackButton mode="md" defaultHref="/" />
            <h4>Jobs List</h4>
            <div className="extra_class"></div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="loader-container">
            <IonSpinner className="dash-spinner" name="crescent" />
          </div>
        ) : (
          <IonGrid>
            <IonRow className="row">
              {userJobs.length > 0 ? (
                userJobs.map((item: any, index: any) => {
                  return (
                    <IonCol
                      key={index}
                      className="column"
                      size="12"
                      onClick={() => handleJobClick(item, userData.role.key)}
                    >
                      <div
                        style={{ width: "100%" }}
                        className="job-description"
                      >
                        {item.starts_at_formatted && item.ends_at_formatted ? (
                          <span className="card-timing">
                            {item.starts_at_formatted} -{" "}
                            {item.ends_at_formatted}
                          </span>
                        ) : (
                          ""
                        )}
                        <span className="font-weight-700 address">
                          {item.job
                            ? item.job.title
                            : item?.title
                              ? item.title
                              : ""}
                        </span>
                        <span className="area">
                          {item.job
                            ? item?.job?.location?.name
                            : item?.location
                              ? item?.location?.name
                              : ""}
                        </span>

                        <div className="ion-d-flex ion-justify-content-center ion-align-items ion-margin-top">
                          {/* User Buttons  */}
                          {userData.role.id === 3 &&
                            item.status === "pending" ? (
                            <>
                              <IonButton
                                onClick={(e: any) =>
                                  updateStatus(e, "pending", item.id)
                                }
                                className="ion-margin-end"
                                color="primary"
                                mode="ios"
                              >
                                Accept
                              </IonButton>
                              <IonButton
                                onClick={(e: any) =>
                                  updateStatus(e, "rejected", item.id)
                                }
                                className="ion-margin-end"
                                color="danger"
                                mode="ios"
                              >
                                Reject
                              </IonButton>
                            </>
                          ) : userData.role.id === 3 &&
                            [
                              "accepted_by_employee",
                              "rejected_by_admin",
                              "in_progress",
                              "active",
                            ].includes(item.status) ? (
                            <IonButton
                              onClick={(e: any) =>
                                updateStatus(e, "active", item.id)
                              }
                              className="ion-margin-end"
                              color="primary"
                              mode="ios"
                            >
                              Complete
                            </IonButton>
                          ) : null}

                          {/* Admin Buttons */}
                          {userData.role.id === 1 &&
                            item.status === "awaiting_approval_of_admin" ? (
                            <>
                              <IonButton
                                onClick={(e: any) =>
                                  updateStatus(e, "approve", item.id)
                                }
                                className="ion-margin-end"
                                color="primary"
                                mode="ios"
                              >
                                Approve
                              </IonButton>
                              <IonButton
                                onClick={(e: any) =>
                                  updateStatus(e, "rejected", item.id)
                                }
                                className="ion-margin-end"
                                color="danger"
                                mode="ios"
                              >
                                Reject
                              </IonButton>
                            </>
                          ) : null}

                          {/* Button for 'pending' status or non-admin user */}
                          {/* {item.status === "pending" ||
                            (userData.role.id !== 1 && (
                              <IonButton
                                onClick={(e: any) =>
                                  updateStatus(e, item.status, item.id)
                                }
                                className="ion-margin-end"
                                color="primary"
                                mode="ios"
                              >
                                {item.status === "pending"
                                  ? "Accept"
                                  : "Complete"}
                              </IonButton>
                            ))} */}

                          {/* Button for 'active' status */}
                          {/* {item.status === "active" && (
                            <IonButton
                              onClick={(e: any) =>
                                updateStatus(e, item.status, item.id)
                              }
                              className="ion-margin-end"
                              color="primary"
                              mode="ios"
                            >
                              Complete
                            </IonButton>
                          )} */}

                          {/* Button for 'awaiting_approval_of_admin' status, only for admin */}
                          {/* {item.status === "awaiting_approval_of_admin" &&
                            userData.role.id === 1 && (
                              <IonButton
                                onClick={(e: any) =>
                                  updateStatus(e, item.status, item.id)
                                }
                                className="ion-margin-end"
                                color="primary"
                                mode="ios"
                              >
                                Awaiting approval of admin
                              </IonButton>
                            )}
                          {(item.status === "pending" ||
                            userData.role.id !== 1) &&
                          statusJob ? (
                            <IonButton
                              color="danger"
                              mode="ios"
                              onClick={(e: any) =>
                                updateStatus(e, "reject", item.id)
                              }
                            >
                              Reject
                            </IonButton>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>
                    </IonCol>
                  );
                })
              ) : (
                <h3>No Jobs Found</h3>
              )}
            </IonRow>
          </IonGrid>
        )}
        <IonToast
          mode="ios"
          color={"primary"}
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={2000}
          position="bottom"
        />
      </IonContent>
    </IonPage>
  );
};
export default JobsList;
