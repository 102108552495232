import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, BrowserRouter } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Home from "./pages/Home";
import JobDetail from "./pages/JobDetail";
import Dashboard from "./pages/Dashboard";
import History from "./pages/History";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";
import DetailImage from "./pages/DetailImage";
import ForgetPassword from "./pages/ForgetPassword";
import UserProfile from "./pages/UserProfile";
import Application from "./layouts/application";


import TimeLine from "./pages/TimeLine";
import MyJobs from "./pages/MyJobs";
import JobTrackings from "./pages/JobTrackings";
import SingleTracking from "./pages/SingleTracking";
import LineChart from './components/LineChart'
import Quote from "./pages/Quote";
import AccountCreated from "./pages/AccountCreated";
import Register from "./pages/Register";
import Notifications from "./pages/Notifications";
import QuoteRequests from "./pages/QuoteRequests";
import SingleQuoteRequest from "./pages/SingleQuoteRequest";
import JobHolderProfile from "./pages/JobHolderProfile";
import PortFolioDetail from "./pages/PortFolioDetail";
import Offers from "./pages/Offers";
import OffersDetail from "./pages/OffersDetail";
import CompanyList from "./pages/companyList";
import CreateJob from "./pages/CreateJob";
import JobEmployeeSection from "./pages/JobEmployeeSection";
import JobsList from "./pages/JobsList";
import AdminJobTrackings from "./pages/AdminJobTracking";
import EmployeeDetail from "./pages/EmployeeDetail";
import AdminJobsList from "./pages/AdminJobList";


//import Page from './pages/Page'

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            {/* <Route path="/app" render={(props) => <Application {...props} />} /> */}

            <Route path="/" exact={true}>
              <Redirect to="/page/accountcreated" />
            </Route>
            <Route path="/page/home" exact={true}>
              <Home />
            </Route>
            <Route path="/page/login" exact={true}>
              <Login />
            </Route>
            <Route path="/page/job-detail" exact={true}>
              <JobDetail />
            </Route>
            <Route path="/page/user-profile" exact={true}>
              <UserProfile />
            </Route>
            <Route path="/page/quote" exact={true}>
              <Quote />
            </Route>
            <Route path="/page/accountcreated" exact={true}>
              <AccountCreated />
            </Route>
            <Route path="/page/register" exact={true}>
              <Register />
            </Route>
            <Application path="/page/dashboard" component={Dashboard} />
            {/* <Route path="/page/dashboard" exact={true}>
              <Dashboard />
            </Route> */}
            <Route path="/page/my-jobs" exact={true}>
              <MyJobs />
            </Route>
            <Route path="/page/job-trackings" exact={true}>
              <JobTrackings />
            </Route>
            <Route path="/page/single-tracking" exact={true}>
              <SingleTracking />
            </Route>
            <Route path="/page/history" exact={true}>
              <History />
            </Route>
            <Route path="/page/change-password" exact={true}>
              <ChangePassword />
            </Route>
            <Route path="/page/job-holder-profile" exact={true}>
              <JobHolderProfile />
            </Route>
            <Route path="/page/portfoliodetail" exact={true}>
              <PortFolioDetail />
            </Route>
            <Route path="/page/offers">
              <Offers />
            </Route>
            <Route path="/page/offers-detail">
              <OffersDetail />
            </Route>
            <Route path="/page/detail-image" exact={true}>
              <DetailImage />
            </Route>
            <Route path="/page/forget-password" exact={true}>
              <ForgetPassword />
            </Route>

            <Route path="/page/time-line" exact={true}>
              <TimeLine />
            </Route>
            <Route path="/page/line-chart" exact={true}>
              < LineChart />
            </Route>

            <Route path="/page/notifications" exact={true}>
              <Notifications />
            </Route>
            <Route path="/page/quote-requests" exact={true}>
              <QuoteRequests />
            </Route>
            <Route path="/single-quote-request" exact={true}>
              <SingleQuoteRequest />
            </Route>
            <Route path="/companylist" exact={true}>
              <CompanyList />
            </Route>
            <Route path="/createjob" exact={true}>
              <CreateJob />
            </Route>
            <Route path="/JobEmployeeSection" exact={true}>
              <JobEmployeeSection />
            </Route>
            <Route path="/job-list" exact={true}>
              <JobsList />
            </Route>
            <Route path="/admin-job-tracking" exact={true}>
              <AdminJobTrackings />
            </Route>
            <Route path="/employee-detail" exact={true}>
              <EmployeeDetail />
            </Route>
            <Route path="/admin-job-list" exact={true}>
              <AdminJobsList />
            </Route>

          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
