import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonContent, IonDatetime, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToast, IonToolbar } from "@ionic/react";
import axios from "axios";
import { accessibility, addCircle, briefcase, business, calendar, call, cash, checkmarkCircle, chevronDown, documentText, earth, earthSharp, ellipsisHorizontal, home, invertMode, list, locationOutline, locationSharp, lockClosed, mail, mailOpen, map, newspaper, person, personOutline, pin, reader, ribbon, text } from "ionicons/icons";
import { useEffect, useState } from "react";
import { BASE_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { useHistory } from "react-router";

const CreateJob: React.FC = () => {
    let history = useHistory();
    let cookies = new Cookies();
    const minDate = new Date().toISOString().split('T')[0];

    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [createJobData, setcreateJobData] = useState<any>({});
    const [company, setCompany] = useState<any>([]);
    const [jobDesignation, setJobDesignation] = useState<any>([]);
    const [jobType, setJobType] = useState<any>([]);
    const [customer, setCustomer] = useState<any>([]);
    const [location, setLocation] = useState<any>([]);
    const [currencyList, setCurrencyList] = useState<any>([]);
    const [countryList, setCountryList] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [dateType, setDateType] = useState<string | null>(null);
    const [filter, setFilter] = useState({
        from_date: '',
        to_date: ''
    });
    const [addLocation, setAddLocation] = useState<any>({
        name: "",
        street: "",
        apartment: "",
        city: "",
        zip_code: "",
        country_id: "",
        latitude: "",
        longitude: "",
        address: "",
        notes: ""
    });
    const [addCustomer, setAddCustomer] = useState<any>({
        city: "",
        company: "",
        country_id: "",
        currency_id: "0",
        email: "",
        password: "",
        phone_no: "",
        state: "",
        status: "1",
        vat_number: "",
        website: "",
        zip_code: "",
        address: ""
    });
    const [selectedValues, setSelectedValues] = useState({
        mode: '',
        job_designation_id: null,
        job_type_id: null,
        customer_id: null,
        location_id: null,
        company_id: null,
        status: ''
    });

    const openModal = (status: boolean, type: string) => {
        setIsDateModalOpen(status);
        setDateType(type);
    };


    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const handleDateChange = (event: any) => {
        const selectedDate = new Date(event.detail.value);
        const formattedDate = formatDate(selectedDate);

        setFilter(prevFilter => {
            if (dateType === 'fromDate') {
                // If updating the from date, reset to_date if necessary
                return {
                    ...prevFilter,
                    from_date: formattedDate,
                    to_date: new Date(prevFilter.to_date) < new Date(formattedDate) ? '' : prevFilter.to_date
                };
            } else {
                // Ensure to_date is not before from_date by comparing only the date parts
                const fromDate = new Date(prevFilter.from_date.split('/').reverse().join('-'));
                const toDate = new Date(formattedDate.split('/').reverse().join('-'));

                if (toDate >= fromDate) {
                    return { ...prevFilter, to_date: formattedDate };
                } else {
                    setShowToast(true);
                    console.warn('To date cannot be before the from date.');
                    return prevFilter;
                }
            }
        });
    };

    const getCustomersAll = () => {
        var url = BASE_URL + "customers/all";
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
        }).then((res) => {
            getLocationsAll();
            if (res.data.data) {
                setCustomer(res.data.data);
            } else {
                //   setMessage(res.data.message);
                //   setShowToast(true);
                //   setLoading(false);
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const getJobTypes = () => {
        var url = BASE_URL + "job-types/all";
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
        }).then((res) => {
            if (res.data.status) {
                setJobType(res.data.data);
                getCustomersAll();
            } else {
                //   setMessage(res.data.message);
                //   setShowToast(true);
                //   setLoading(false);
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const getLocationsAll = () => {
        var url = BASE_URL + "locations/all";
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
        }).then((res) => {
            if (res.data.status) {
                setLocation(res.data.data);
            } else {
                //   setMessage(res.data.message);
                //   setShowToast(true);
                //   setLoading(false);
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        let isMounted = true;

        let selected_company: any = localStorage.getItem('select_companies');
        setCompany(JSON.parse(selected_company));

        const getJobDesignations = () => {
            const url = BASE_URL + "job-designations/all";
            axios.get(url, {
                headers: {
                  Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
                },
            }).then((res) => {
                if (isMounted) {
                    if (res.data.status) {
                        setJobDesignation(res.data.data);
                        getJobTypes(); // Assuming this is another function
                    } else {
                        // Handle the response when status is false
                        // setMessage(res.data.message);
                        // setShowToast(true);
                        // setLoading(false);
                    }
                }
            }).catch((error) => {
                if (isMounted) {
                    console.log(error);
                }
            });
        };

        getJobDesignations();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleSelectChange = (value: any, field: string) => {
        setSelectedValues((prevState) => ({
            ...prevState,
            [field]: value
        }));
        console.log(`Selected value for ${field}:`, value);
    };

    const moveToJobSelectionEmployee = () => {
        setLoading(true);
        const jobCreationData: any = {
            title: createJobData.title,
            location: createJobData.location,
            total_budget: createJobData.total_budget,
            mode: selectedValues.mode,
            job_designation_id: selectedValues.job_designation_id,
            job_type_id: selectedValues.job_type_id,
            customer_id: selectedValues.customer_id,
            location_id: selectedValues.location_id,
            company_id: selectedValues.company_id,
            status: selectedValues.status,
            starts_at: filter.from_date,
            ends_at: filter.to_date,
            description: createJobData.description
        }
        const url = BASE_URL + "admin/jobs/store";
        const token:any = localStorage.getItem("access_token_tlh_service")
        axios.post(url,
            jobCreationData,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                },
            }
        ).then((res) => {
            setLoading(false);
            if (res.data.status) {
                history.push('/JobEmployeeSection', { data: res.data.data })
            } else {
                setMessage(res.data.message);
                setShowToast(true);
                setLoading(false);
            }
        }).catch((error: any) => {
            console.log(error);
            setLoading(false);
        });
    }

    const openAdditionModal = (type: any) => {
        setType(type);
        setIsOpen(true);
        setIsModalOpen(true);
        getCurrency();
    }

    const getCurrency = () => {
        setLoading(true);
        var url = "https://quote.tlhbuildings.com/api/currencies/all";
        axios.post(url, {}, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
        }).then((res) => {
            if (res.data.data) {
                setCurrencyList(res.data.data);
                getCountry();
            } else {
                //   setMessage(res.data.message);
                //   setShowToast(true);
                //   setLoading(false);
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const getCountry = () => {
        var url = "https://quote.tlhbuildings.com/api/countries/all";
        axios.post(url, {}, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
        }).then((res) => {
            setLoading(false);
            if (res.data.data) {
                setCountryList(res.data.data);

            } else {
                //   setMessage(res.data.message);
                //   setShowToast(true);
                //   setLoading(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const addItem = (type: any) => {
        setLoading(true);
        if (type !== 'location') {
            var url = "https://quote.tlhbuildings.com/api/customers/store";
            axios.post(url, addCustomer, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
                },
            }).then((res: any) => {
                setLoading(false);
                if (res.data.status) {
                    setCustomer((prevEmployees: any) => [...prevEmployees, res?.data?.data ? res?.data?.data : addCustomer]);
                    setMessage(res.data.message);
                    setShowToast(true);
                    setLoading(false);
                    setIsModalOpen(false);
                } else {
                    setMessage(res.data.message);
                    setShowToast(true);
                    setLoading(false);
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            var url = "https://quote.tlhbuildings.com/api/locations/store";
            axios.post(url, addLocation, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
                },
            }).then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setLocation((prevEmployees: any) => [...prevEmployees, res.data.data]);
                    setMessage(res.data.message);
                    setShowToast(true);
                    setLoading(false);
                    setIsOpen(false);

                } else {
                    setMessage(res.data.message);
                    setShowToast(true);
                    setLoading(false);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    return (
        <IonPage>
            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={"Please wait..."}
            />
            <IonHeader>
                <IonToolbar style={{ paddingBottom: '5px' }} className="quote_bg_primary ptb">
                    <div className="quote_header_text">
                        <IonBackButton mode="md" defaultHref="/" />
                        <h4>Create Job</h4>
                        <div className="extra_class"></div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList class="ion-padding-horizontal ion-h-100 ion-overflow-y">
                    <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={text} className="ion-margin-end" />
                        <IonInput style={{ marginTop: '5px' }} placeholder="Title" type="text"
                            onIonChange={(e) => {
                                let jobdata = createJobData;
                                jobdata.title = e.detail.value;
                                setcreateJobData(jobdata);
                            }}></IonInput>
                    </IonItem>
                    {/* <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={cash} className="ion-margin-end" />
                        <IonInput style={{ marginTop: '5px' }} placeholder="Total Budget" type="number"
                            onIonChange={(e) => {
                                let jobdata = createJobData;
                                jobdata.total_budget = e.detail.value;
                                setcreateJobData(jobdata);
                            }}></IonInput>
                    </IonItem>
                    <div className="ion-d-flex ion-align-items-center">
                        <IonItem onClick={() => openModal(true, 'fromDate')} lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                            <IonIcon icon={calendar} className="ion-margin-end" />
                            <IonLabel className="job-timing">{filter?.from_date ? filter?.from_date : 'Start At'}</IonLabel>
                        </IonItem>
                        <IonItem onClick={() => openModal(true, 'toDate')} lines="none" style={{ padding: '5px' }} className='ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                            <IonIcon icon={calendar} className="ion-margin-end" />
                            <IonLabel className="job-timing">{filter?.to_date ? filter?.to_date : 'Ends At'}</IonLabel>
                        </IonItem>
                    </div>
     
                    <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={ribbon} className="ion-margin-end" />
                        <IonSelect mode="ios" placeholder="Job Designation" interface="action-sheet" onIonChange={(e) => handleSelectChange(e.detail.value, 'job_designation_id')}>
                            {jobDesignation.map((item: any, index: any) => {
                                return (
                                    <IonSelectOption key={index} value={item.id}>{item.title}</IonSelectOption>
                                )
                            })}
                        </IonSelect>
                        <IonIcon className="ion-chevron-down" icon={chevronDown} />
                    </IonItem> */}
                    {/* <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={briefcase} className="ion-margin-end" />
                        <IonSelect mode="ios" placeholder="Select Job Type" interface="action-sheet" onIonChange={(e) => handleSelectChange(e.detail.value, 'job_type_id')}>
                            {jobType.map((item: any, index: any) => {
                                return (
                                    <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
                                )
                            })}
                        </IonSelect>
                        <IonIcon className="ion-chevron-down" icon={chevronDown} />
                    </IonItem> */}
                    {/* <div>
                        <div className="ion-d-flex ion-justify-content-end ion-align-items-center">
                            <IonIcon onClick={() => openAdditionModal('customer')} icon={addCircle} size="large" color="primary" className="ion-margin-vertical" />
                        </div>
                        <IonItem lines="none" style={{ padding: '5px', marginRight: '15px', marginTop: '0px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                            <IonIcon icon={accessibility} className="ion-margin-end" />
                            <IonSelect mode="ios" placeholder="Select Customer" interface="action-sheet" onIonChange={(e) => handleSelectChange(e.detail.value, 'customer_id')}>
                                {customer.map((item: any, index: any) => {
                                    return (
                                        <IonSelectOption key={index} value={item.id}>{item.company}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>
                            <IonIcon className="ion-chevron-down" icon={chevronDown} />
                        </IonItem>
                    </div> */}
                    <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={business} className="ion-margin-end" />
                        <IonSelect mode="ios" placeholder="Select a Company" interface="action-sheet" onIonChange={(e) => handleSelectChange(e.detail.value, 'company_id')}>
                            {company.map((item: any, index: any) => {
                                return (
                                    <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
                                )
                            })}
                        </IonSelect>
                        <IonIcon className="ion-chevron-down" icon={chevronDown} />
                    </IonItem>
                    <div className="ion-margin-top">
                        {/* <div className="ion-d-flex ion-justify-content-end ion-align-items-center">
                            <IonIcon onClick={() => openAdditionModal('location')} icon={addCircle} size="large" color="primary" className="ion-margin-vertical" />
                        </div> */}
                        <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                            <IonIcon icon={locationSharp} className="ion-margin-end" />
                            <IonInput style={{ marginTop: '5px' }} placeholder="Add Location" type="text"
                                onIonChange={(e) => {
                                    let jobdata = createJobData;
                                    jobdata.location = e.detail.value;
                                    setcreateJobData(jobdata);
                                }}></IonInput>
                        </IonItem>
                        {/* <IonItem lines="none" style={{ padding: '5px', marginRight: '15px', }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                            <IonIcon icon={locationSharp} className="ion-margin-end" />
                            <IonSelect mode="ios" placeholder="Search For a Location" interface="action-sheet" onIonChange={(e) => handleSelectChange(e.detail.value, 'location_id')}>
                                {location.map((item: any, index: any) => {
                                    return (
                                        <IonSelectOption key={index} value={item.id}>{item.address},{item.street},{item.city}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>
                            <IonIcon className="ion-chevron-down" icon={chevronDown} />
                        </IonItem> */}
                    </div>
                    {/* <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={ellipsisHorizontal} className="ion-margin-end" />
                        <IonSelect mode="ios" placeholder="Status" interface="action-sheet" onIonChange={(e) => handleSelectChange(e.detail.value, 'status')}>
                            <IonSelectOption value="active">Active</IonSelectOption>
                            <IonSelectOption value="completed">Completed</IonSelectOption>
                        </IonSelect>
                        <IonIcon className="ion-chevron-down" icon={chevronDown} />
                    </IonItem>
                    <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={invertMode} className="ion-margin-end" />
                        <IonSelect mode="ios" placeholder="Select a Mode" interface="action-sheet" onIonChange={(e) => handleSelectChange(e.detail.value, 'mode')}>
                            <IonSelectOption value="hourly">Hourly</IonSelectOption>
                            <IonSelectOption value="fixed">Fixed</IonSelectOption>
                        </IonSelect>
                        <IonIcon className="ion-chevron-down" icon={chevronDown} />
                    </IonItem> */}
                    <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                        <IonIcon icon={newspaper} className="ion-margin-top ion-margin-end" />
                        <IonTextarea
                            rows={6}
                            placeholder="Enter notes here..."
                            className="review-area"
                            onIonChange={(e: any) => {
                                let jobdata = createJobData;
                                jobdata.description = e.detail.value;
                                setcreateJobData(jobdata);
                            }}
                        />
                    </IonItem>
                </IonList>
                <IonModal id='calender-modal' isOpen={isDateModalOpen} onDidDismiss={() => setIsDateModalOpen(false)}>
                    <IonHeader className="ion-no-border">
                        <IonToolbar>
                            <IonTitle onClick={() => setIsDateModalOpen(false)}>Close</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setIsDateModalOpen(false)}>Confirm</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonDatetime
                            className="ion-max-w-100"
                            presentation="date"
                            onIonChange={handleDateChange}
                            locale="en-GB-u-hc-h12"
                            min={minDate}
                        />
                    </IonContent>
                </IonModal>
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonToolbar className=" ion-padding-vertical" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <IonButton onClick={moveToJobSelectionEmployee} expand="block" mode="ios">
                        Submit
                    </IonButton>
                </IonToolbar>
            </IonFooter>
            <IonModal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{type === "location" ? 'Add Location' : 'Add Customer'}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsModalOpen(false)}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    {type !== "location" ?
                        <IonList class="ion-h-100 ion-overflow-y">
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={business} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Company" type="text"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, company: e.detail.value! })}
                                    value={addCustomer.company}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={reader} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="VAT Number" type="text"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, vat_number: e.detail.value! })}
                                    value={addCustomer.vat_number}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={call} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Phone Number" type="tel"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, phone_no: e.detail.value! })}
                                    value={addCustomer.phone_no}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={mail} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Email" type="email"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, email: e.detail.value! })}
                                    value={addCustomer.email}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={lockClosed} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Password" type="password"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, password: e.detail.value! })}
                                    value={addCustomer.password}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={earth} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Website" type="url"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, website: e.detail.value! })}
                                    value={addCustomer.website}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={earthSharp} className="ion-margin-end" />
                                <IonSelect mode="ios" placeholder="Select Currency" interface="action-sheet" onIonChange={(e) => setAddCustomer({ ...addCustomer, currency_id: e.detail.value! })}>
                                    {currencyList.map((item: any, index: any) => {
                                        return (
                                            <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
                                        )
                                    })}
                                </IonSelect>
                                <IonIcon className="ion-chevron-down" icon={chevronDown} />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={ellipsisHorizontal} className="ion-margin-end" />
                                <IonSelect mode="ios" placeholder="Status" interface="action-sheet" onIonChange={(e) => setAddCustomer({ ...addCustomer, status: e.detail.value! })}>
                                    <IonSelectOption value="1">Active</IonSelectOption>
                                    <IonSelectOption value="0">Inactive</IonSelectOption>
                                </IonSelect>
                                <IonIcon className="ion-chevron-down" icon={chevronDown} />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={earthSharp} className="ion-margin-end" />
                                <IonSelect mode="ios" placeholder="Select Country" interface="action-sheet" onIonChange={(e) => setAddCustomer({ ...addCustomer, country_id: e.detail.value! })}>
                                    {countryList.map((item: any, index: any) => {
                                        return (
                                            <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
                                        )
                                    })}
                                </IonSelect>
                                <IonIcon className="ion-chevron-down" icon={chevronDown} />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={locationOutline} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="City" type="text"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, city: e.detail.value! })}
                                    value={addCustomer.city}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={locationOutline} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="State" type="text"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, state: e.detail.value! })}
                                    value={addCustomer.state}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={mailOpen} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="ZIP Code" type="text"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, zip_code: e.detail.value! })}
                                    value={addCustomer.zip_code}
                                />
                            </IonItem>
                            <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={newspaper} className="ion-margin-top ion-margin-end" />
                                <IonTextarea
                                    rows={6}
                                    placeholder="Enter address here..."
                                    className="review-area"
                                    onIonChange={(e) => setAddCustomer({ ...addCustomer, address: e.detail.value! })}
                                    value={addCustomer.address}
                                />
                            </IonItem>
                        </IonList>
                        :
                        <IonList class="ion-h-100 ion-overflow-y">
                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={person} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Name" type="text"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, name: e.detail.value! })}
                                    value={addLocation.name}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={locationSharp} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Street" type="text"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, street: e.detail.value! })}
                                    value={addLocation.street}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={home} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Apartment" type="text"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, apartment: e.detail.value! })}
                                    value={addLocation.apartment}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={locationSharp} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="City" type="text"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, city: e.detail.value! })}
                                    value={addLocation.city}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={mailOpen} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="ZIP Code" type="text"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, zip_code: e.detail.value! })}
                                    value={addLocation.zip_code}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={earthSharp} className="ion-margin-end" />
                                <IonSelect mode="ios" placeholder="Select Country" interface="action-sheet"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, country_id: e.detail.value! })}>
                                    {countryList.map((item: any, index: any) => (
                                        <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
                                    ))}
                                </IonSelect>
                                <IonIcon className="ion-chevron-down" icon={chevronDown} />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={locationSharp} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Latitude" type="number"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, latitude: e.detail.value! })}
                                    value={addLocation.latitude}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={locationSharp} className="ion-margin-end" />
                                <IonInput style={{ marginTop: '5px' }} placeholder="Longitude" type="number"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, longitude: e.detail.value! })}
                                    value={addLocation.longitude}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={documentText} className="ion-margin-end ion-margin-top" />
                                <IonTextarea rows={4} placeholder="Notes"
                                    onIonChange={(e) => setAddLocation({ ...addLocation, notes: e.detail.value! })}
                                    value={addLocation.notes}
                                />
                            </IonItem>

                            <IonItem lines="none" style={{ padding: '5px' }} className='box_shadow br_20 mt_25 quote_ion_input'>
                                <IonIcon icon={newspaper} className="ion-margin-end ion-margin-top" />
                                <IonTextarea rows={6} placeholder="Enter address here..."
                                    onIonChange={(e) => setAddLocation({ ...addLocation, address: e.detail.value! })}
                                    value={addLocation.address}
                                />
                            </IonItem>
                        </IonList>
                    }
                </IonContent>
                <IonFooter className="ion-no-border">
                    <IonToolbar className=" ion-padding-vertical" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <IonButton onClick={() => addItem(type)} expand="block" mode="ios">
                            Submit
                        </IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
            <IonToast
                mode="ios"
                color={"primary"}
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={message}
                duration={2000}
                position="middle"
            />
        </IonPage>
    )
}
export default CreateJob;