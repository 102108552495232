
import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonIcon,
  useIonViewWillEnter
} from "@ionic/react";
import {
  eye,
  homeOutline,
  arrowBack,
} from 'ionicons/icons';
import "./Dashboard.scss";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { useState } from 'react';
import { useHistory } from "react-router-dom";

const cookies = new Cookies();

const MyJobs: React.FC = () => {

  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [userJobs, setUserJobs] = useState([]);
  // const [userData, setUserData] = useState(cookies.get('tlh_service_user'));
  let logged_user_data: any = localStorage.getItem('tlh_service_user');
  const [userData, setUserData] = useState<any>(JSON.parse(logged_user_data));

  useIonViewWillEnter(() => {
    setLoading(true);
    let url = BASE_URL;
    if (userData.role.key === 'staff') {
      url = url += "jobs";
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`, },
        })
        .then(async (res) => {
          setLoading(false);
          if (res.data.status) {
            setLoading(false);
            setUserJobs(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (userData.role.key === 'customer') {
      url = url += "customer/jobs";
      axios
        .post(url, {}, {
          headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`, },
        })
        .then(async (res) => {
          setLoading(false);
          if (res.data.status) {
            setLoading(false);
            setUserJobs(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      url = url += "admin/jobs";
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`, },
        })
        .then(async (res) => {
          setLoading(false);
          if (res.data.status) {
            setLoading(false);
            setUserJobs(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //const url = BASE_URL + userData.role.key === 'staff'? "jobs" : 'customer/jobs';
  });

  const handleJobClick = async (job: any) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    // await cookies.set("job-detail", job, {
    //     path: "/",
    //     expires: date,
    //     sameSite: "lax",
    // });

    localStorage.setItem("job-detail", JSON.stringify(job))
    history.push('/page/history');
  }

  return (
    <IonPage>
      <IonHeader>
        <div className="header1">
          <div className="back-btn-container">
            <IonIcon icon={arrowBack} onClick={() => {
              history.goBack();
            }} size="large" className="text-white" />
          </div>
          <h1 className="header-title1">My Jobs</h1>
        </div>
      </IonHeader>
      <IonContent className="wrapper">
        {loading ?
          <div className="loader-container">
            <IonSpinner className="dash-spinner" name="crescent" />
          </div>
          :
          <IonGrid>
            <IonRow className="row">
              {userJobs.map((item: any, index: any) => {
                return (
                  <IonCol key={index} onClick={() => handleJobClick(item)} className="column" size="12">
                    <IonImg src={item?.job?.location?.location_pic_url ? item?.job?.location?.location_pic_url : require("../images/location.jpg")} />
                    {/* <div className="job-description">
                        <span className="card-timing">9am-5pm</span>
                        <span className="font-weight-700">{item.job.title? item.job.title : ''}</span>
                        <span>{item.job.location.name? item.job.location.name : ''}</span>
                      </div> */}


                    <div className="job-description">
                      {item.starts_at_formatted && item.ends_at_formatted ? (
                        <span className="card-timing">
                          {item.starts_at_formatted} - {item.ends_at_formatted}
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="font-weight-700 address">
                        {item.job ? item.job.title : item.title ? item.title : ''}
                      </span>
                      <span className="area">
                        {item.job ? item?.job?.location?.name : item?.location ? item?.location?.name : ''}
                      </span>
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        }
        {/* <RatingStar/> */}
      </IonContent>

    </IonPage>
  );
};
export default MyJobs;