import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from "universal-cookie";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  path: string;
}

const cookies = new Cookies();

const Application: React.FC<ProtectedRouteProps> = (props) => {
  // if (!localStorage.getItem("tlh_service_user")) {
  if (!localStorage.getItem("tlh_service_user")) {
    return <Redirect to="/page/accountcreated" />;
  } else {
    return <props.component />;
  }
};
export default Application;
