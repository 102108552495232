import {
  IonHeader,
  IonPage,
  IonRow,
  IonCol,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonContent,
  IonIcon,
  IonTitle,
  IonToast,
  IonButton,
  IonGrid,
  IonLoading,
  useIonViewWillEnter,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./Dashboard.scss";
import Cookies from "universal-cookie";
import { postCall } from "../ApiCalls";
import { BASE_URL } from "../BaseUrl";

const OffersDetail: React.FC = () => {
  let history = useHistory();
  let cookies = new Cookies();
  let location:any = useLocation();

  const [projectDetails, setProjectDetails] = useState<any>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<any>('');
  const [showToast, setShowToast] = useState<boolean>(false);

  useIonViewWillEnter(() => {
    // if(cookies.get('tlh_service_project_detail')){
    //   let project_details = cookies.get('tlh_service_project_detail');
    //   setProjectDetails(project_details);
    // }
    let project_details = location.state.project;
    setProjectDetails(location.state.project);
  });

  const handleOfferAction = (action: any) => {
    setShowLoading(true);
    postCall(BASE_URL + 'job-offers/action', {
      id: projectDetails.id,
      status: action
    }).then(async (data: any) => {
      if(data.status){
        setToastMessage(data.message);
        setShowToast(true);
        setShowLoading(false);
        history.goBack();
      }
    });
  }

  return (
    <IonPage>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonContent className="bg-ligr">
        <IonHeader className="offer-detail-header">
          <IonButtons>
            <IonIcon slot="start" icon={chevronBackOutline} onClick={()=> history.goBack()} />
          </IonButtons>
          <IonTitle className="ion-text-capitalize ion-text-center">offer detail</IonTitle >
        </IonHeader>
        <div className="offer-detail-container">
          <IonButtons>
            <IonButton onClick={() => handleOfferAction('ACCEPTED')} shape="round" className={'active-btn'}>accept</IonButton>
            <IonButton onClick={() => handleOfferAction('DECLINED')} className={'simple-btn'}>decline</IonButton>
          </IonButtons>
          <div className="offer-detail-content">
            <div className="offer-text">
              <h6>Project title</h6>
              <p>{projectDetails?.job.title}</p>
            </div>
            <div className="offer-text">
              <h6>Detail</h6>
              <p>{projectDetails?.job.notes}</p>
            </div>
            <div className="offer-text">
              <h6>Job type</h6>
              <p>{projectDetails?.job.job_type.name}</p>
            </div>
            <div className="offer-text">
              <h6>Address</h6>
              <p>{projectDetails?.job.location.Address}</p>
            </div>
            <IonGrid className="offers-images">
              <IonRow>
                {projectDetails && projectDetails.job.job_attachment.length > 0? 
                    projectDetails.job.job_attachment.map((item: any, index: any)=>{
                      return(
                        <IonCol size="4" key={index}>
                          <IonImg src={item.attachment_url} />
                        </IonCol>
                      );
                    })
                  : 
                  <h6 className="txt-center">No pictures available</h6>
                }
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default OffersDetail;