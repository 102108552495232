import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonHeader,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useLocation, useHistory } from "react-router-dom";
import {
  archiveOutline,
  archiveSharp,
  speedometerOutline,
  speedometerSharp,
  personCircleOutline,
  personCircleSharp,
  exitOutline,
  exitSharp,
  diamondOutline,
  diamondSharp,
  colorWandOutline,
  colorWandSharp,
  trophyOutline,
  briefcaseOutline,
  briefcaseSharp,
} from "ionicons/icons";
import "./Menu.css";
import "./Menu.scss";
import Cookies from "universal-cookie";
import { useState } from "react";
import { ASSET_URL } from "../BaseUrl";

const cookies = new Cookies();

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

<IonPage></IonPage>;

const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/page/dashboard",
    iosIcon: speedometerOutline,
    mdIcon: speedometerSharp,
  },
  {
    title: "Offers",
    url: "/page/offers",
    iosIcon: trophyOutline,
    mdIcon: speedometerSharp,
  },
  {
    title: "My Jobs",
    url: "/page/my-jobs",
    iosIcon: archiveOutline,
    mdIcon: archiveSharp,
  },
  {
    title: "User Profile",
    url: "/page/user-profile",
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
  },
  {
    title: "Professional Profile",
    url: "/page/job-holder-profile",
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp,
  },
  {
    title: "New Quote",
    url: "/page/quote",
    iosIcon: diamondOutline,
    mdIcon: diamondSharp,
  },
  {
    title: "Create Job",
    url: "/createjob",
    iosIcon: briefcaseOutline,
    mdIcon: briefcaseSharp,
  },
  {
    title: "Quote Requests",
    url: "/page/quote-requests",
    iosIcon: colorWandOutline,
    mdIcon: colorWandSharp,
  },
  {
    title: "Logout",
    url: "/page/Archived",
    iosIcon: exitOutline,
    mdIcon: exitSharp,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  let history = useHistory();

  let logged_user_data: any = localStorage.getItem('tlh_service_user');
  const [userData, setUserData] = useState<any>(JSON.parse(logged_user_data));

  const handleMenu = () => {
    // let user_data = localStorage.getItem("tlh_service_user");
    let user_data = JSON.parse(logged_user_data);
    setUserData(user_data);
  };

  const logOut = (title: string) => {
    if (title == "Logout") {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      cookies.remove("device_token_tlh_service", { path: "/" });
      // cookies.remove("tlh_service_user", {
      //   path: "/",
      // });
      localStorage.clear();
      //document.cookie = 'access_token_tlh_service=; Max-Age=-99999999;';
      history.push('/page/login');
    }
  };

  return (
    <IonMenu
      swipe-gesture={false}
      contentId="main"
      type="overlay"
      onIonWillOpen={() => handleMenu()}
    >
      <div className="menu-header">
        <img className="menu-title"
          src={
            userData
              ? userData?.profile_pic_url
              : require("../images/building.jpg")
          }
          alt="user-img"
        />
        <h3 className="menu-desp">{userData ? userData?.full_name : ''}</h3>
      </div>

      <IonContent>
        <IonList id="product-list">
          <h1 className="span">Menu</h1>

          {appPages.map((appPage, index) => {
            if (
              userData &&
              userData?.role.id == "6" &&
              (appPage.title == "Logout" ||
                appPage.title == "User Profile" ||
                appPage.title == "Dashboard")
            ) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => logOut(appPage.title)}
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={
                      appPage.title == "Logout" ? "/page/accountcreated" : appPage.url
                    }
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      className="icon"
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="map-label">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            } else if (
              userData &&
              userData.role.id == "1" &&
              appPage.title == "Create Job"
            ) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => logOut(appPage.title)}
                    className={location.pathname === appPage.url ? "selected" : ""}
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      className="icon"
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="map-label">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            } else if (userData && userData.role.id == "7" && (appPage.title == "Professional Profile" || appPage.title == "Offers")) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => logOut(appPage.title)}
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={
                      appPage.url
                    }
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      className="icon"
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="map-label">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            } else if (userData && userData.role.id == "4" && (appPage.title == "New Quote" || appPage.title == "Quote Requests")) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => logOut(appPage.title)}
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={
                      appPage.url
                    }
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      className="icon"
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="map-label">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            } else if (userData && userData.role_id != 1 && userData.role.id !== 7 && (appPage.title == "Dashboard" || appPage.title == "User Profile" || appPage.title == "Logout")) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => logOut(appPage.title)}
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={
                      appPage.title == "Logout" ? "/page/accountcreated" : appPage.url
                    }
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      className="icon"
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="map-label">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            // } else if (userData && (userData.role.id !== 6 && userData.role.id !== 1 && userData.role.id !== 7) && (appPage.title == "My Jobs" || appPage.title == "Logout")) {
            } else if (userData && (userData.role.id !== 6 && userData.role.id !== 1 && userData.role.id !== 7) && (appPage.title == "Logout")) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => logOut(appPage.title)}
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={
                      appPage.title == "Logout" ? "/page/accountcreated" : appPage.url
                    }
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      className="icon"
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="map-label">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            } else if (userData && (appPage.title == "Logout" || appPage.title == "Dashboard")) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => logOut(appPage.title)}
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={
                      appPage.title == "Logout" ? "/page/accountcreated" : appPage.url
                    }
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      className="icon"
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel className="map-label">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            }
          })}
        </IonList>

        {/* <IonList id="label-list">
          <h1 className='span1'>Category</h1>
         
          {label.map((label, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === label.url ? 'selected' : ''} routerLink={label.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon className='icon' slot="start" ios={label.iosIcon} md={label.mdIcon} />
                  <IonLabel className='map-label'>{label.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>  */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
