import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonToast,
  IonSpinner,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { eye, arrowBack } from "ionicons/icons";
import "./ChangePassword.css";
import "./ChangePassword.scss";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Link,
} from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Home: React.FC = () => {
  let history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [showToast, setShowToast] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const validation = () => {
    if (email == "") {
      setMessage("Email must be provided");
      setShowToast(true);
      return false;
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setMessage("Email not valid");
      setShowToast(true);
      return false;
    }
    return true;
  };

  const handleForgetPassword = () => {
    if (validation()) {
      setLoading(true);
      const url = BASE_URL + "forget-password";
      axios
        .post(
          url,
          {
            email: email,
          },
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
          }
        )
        .then((res) => {
          setMessage(res.data.message);
          if (res.data.status) {
            setTimeout(() => {
              history.push("/page/login");
            }, 500);
          }
          setShowToast(true);
          setLoading(false);
        })
        .catch();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <div className="back-btn-containerb">
          <IonIcon
            icon={arrowBack}
            onClick={() => {
              history.goBack();
            }}
            size="large"
            className="text-white"
          />
        </div>
        <IonToolbar>
          <IonImg
            src={require("../images/pexels-photo-1662159.jpeg")}
            className="hearder-image"
            style={{ margin: "-4px" }}
          />

          <div className="overlay">
            <IonImg
              src={require("../images/tlh-corp-white.png")}
              className="hearder-log"
            />
            <h1 className="logo-text">Forget Password</h1>
          </div>

          {/* <div className='back-btn'>
        <IonIcon icon={arrowBack} onClick={()=>{
            history.goBack();
          }} size="large" className="text-white" />
        </div> */}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="grid">
          <IonLabel className="label-setting" position="floating">
            Email Address
          </IonLabel>
          <IonItem className="input-setting" lines="none">
            <IonInput
              placeholder="Enter Your Email"
              onIonChange={(e) => setEmail(e.detail.value)}
            />
          </IonItem>

          <div className="Login">
            <button className="btn" onClick={() => handleForgetPassword()}>
              {loading ? (
                <IonSpinner className="load-spinner" name="crescent" />
              ) : (
                "Send"
              )}
            </button>
          </div>
        </IonGrid>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default Home;
