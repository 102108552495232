// import React from 'react'
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
//   import { Line } from 'react-chartjs-2';
//   ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
//   );
//   export const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top' as const,
//       },
//       title: {
//         display: true,
//         text: 'Chart.js Line Chart',
//       },
//     },
//   };
//   const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
//   export const data = {
//     labels,
//     datasets: [
//       {
//         label: 'Dataset 1',
//         data: [91,27,36,48,75,36,97],
//         borderColor: 'rgb(255, 99, 132)',
//         backgroundColor: 'rgba(255, 99, 132, 0.5)',
//         fill: false,
//         pointBorderColor: 'rgba(27, 76, 197, 0.8)',
//         pointBackgroundColor: 'rgba(27, 76, 197, 0.8)',
//         pointBorderWidth: 3,
//         pointHoverRadius: 3,
//         pointHoverBackgroundColor: 'gray',
//         pointHoverBorderColor: 'gray',
//         pointHoverBorderWidth: 7,
//         pointRadius: 4,
//         pointHitRadius: 10,
//         stack:'line',
//         borderDashOffset:5,
//         showline:true,
//       },
//       {
//         label: 'Dataset 1',
//         data: [1,37,76,88,25,96,17],
//         borderColor: 'red',
//         backgroundColor: 'red',
//         fill: false,
//         pointBorderColor: 'gray',
//         pointBackgroundColor: 'gray',
//         pointBorderWidth: 2,
//         pointHoverRadius: 2,
//         pointHoverBackgroundColor: 'rgba(27, 76, 197, 0.8)',
//         pointHoverBorderColor: 'rgba(27, 76, 197, 0.8)',
//         pointHoverBorderWidth: 7,
//         pointRadius: 4,
//         pointHitRadius: 10,
//         stack:'line',
//         borderDashOffset:5,
//         showline:true,
//       },
//     ],
//   };
// export default function () {
//   return (
//     <div>
//          <Line
//            width={'710px'}
//            height={'450'}
//             options={options} data={data} />
//     </div>
//   )
// }

// import React from 'react'
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';


// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   interaction: {
//     mode: 'index' as const,
//     intersect: false,
//   },
//   stacked: false,
//   plugins: {
//     title: {
//       display: true,
//       text: 'Chart.js Line Chart - Multi Axis',
//     },
//   },
//   options: {
//     chartArea: {
//         backgroundColor: 'rgba(251, 85, 85, 0.4)',
//     }
// },
//   scales: {
//     y: {
//       type: 'linear' as const,
//       display: true,
//       position: 'left' as const, 
//     },
//     y1: {
//       type: 'linear' as const,
//       display: true,
//       position: 'right' as const,
//       grid: {
//         drawOnChartArea: false,
//       },
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'red',
//       yAxisID: 'y',
//       data: [91,27,36,48,75,36,97],
//       tension:0.4,
     
//     },
//     {
//       label: 'Dataset 2',
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'yellow',
//       pointBackgroundColor:'blue',
//       yAxisID: 'y1',
//       data: [91,77,36,28,65,56,99],
//       tension:0.4,
      
//     },
   
//   ],
  
// };
// export default function LineChart() {
//   return (
//     <div>
//       <Line
//            width={'710px'}
//            height={'450'}
//             options={options} data={data} />
//       </div>
//   )
// }

import React from 'react'
import "./LineChart.scss";
import { Line } from "react-chartjs-2";
const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
      tension:0.4,
    },
    {
      label: "Second dataset",
      data: [33, 25, 35, 51, 54, 76],
      fill: false,
      borderColor: "#748874",
      tension:0.4,
    }
  ]
};
export default function () {
  return (
    <div className='fill'> 
      <Line  data={data} />
      </div>
  )
}





