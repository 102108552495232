import {
  IonGrid,
  IonHeader,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonProgressBar,
  IonTextarea,
  IonSpinner,
  IonToast,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  pinOutline,
  callOutline,
  addCircle,
  addCircleOutline,
  headset,
  arrowBack,
  alarmOutline,
  timerOutline,
  closeCircle,
} from "ionicons/icons";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";
import { Camera, CameraResultType } from "@capacitor/camera";
import "./SingleTracking.scss";
import Cookies from "universal-cookie";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import axios from "axios";
import Rating from "../components/RatingStar";

const cookies = new Cookies();

const SingleTracking: React.FC = () => {
  let history = useHistory();
  // let tracking_detail = cookies.get("tlh_service_tracking_detail");
  const trackDetailJob: any = localStorage.getItem("tlh_service_tracking_detail");
  let tracking_detail: any = trackDetailJob ? JSON.parse(trackDetailJob) : '';
  let job_details: any = localStorage.getItem("job-detail");
  // const [jobDetail, setJobDetail] = useState<any>(cookies.get("job-detail"));
  const [jobDetail, setJobDetail] = useState<any>(JSON.parse(job_details));
  const [trackingDetail, settrackingDetail] = useState<any>("");
  const [checkinImages, setCheckinImages] = useState<any>([]);
  const [checkoutImages, setCheckoutImages] = useState<any>([]);
  const [selectedImageType, setSelectedImageType] = useState<any>("");
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>("");
  const [showModal, setShowModal] = useState<any>(false);
  const [locationImages, setLocationImages] = useState<any>([]);
  const [locationCheckOutImages, setLocationCheckOutImages] = useState<any>([]);
  const [stars, setStars] = useState<any>(tracking_detail.rating);
  const [review, setReview] = useState<any>(tracking_detail.review);
  const [loading, setLoading] = useState<any>(false);
  const [showToast, setShowToast] = useState<any>(false);
  const [message, setMessage] = useState<any>('');
  const [userData, setUserData] = useState<any>(localStorage.getItem("tlh_service_user"));

  // useIonViewWillEnter(() => {
  useIonViewDidEnter(() => {

    // settrackingDetail(cookies.get("tlh_service_tracking_detail"));
    settrackingDetail(tracking_detail);
    // let tracking_detail = cookies.get("tlh_service_tracking_detail");
    setStars(tracking_detail.rating);
    setReview(tracking_detail.review);
    let checkinimages: any = [];
    let checkoutimages: any = [];

    tracking_detail.attachments.map((item: any, index: any) => {
      if (item.context == "check-in") {
        checkinimages.push(item);
      } else {
        checkoutimages.push(item);
      }
    });
    setTimeout(() => {
      setCheckinImages(checkinimages);
      setCheckoutImages(checkoutimages);
    }, 500);
  });

  const handleImageClick = (index: any, type: any) => {
    setSelectedImageType(type);
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const handleRemoveImage = (index: any) => {
    let allImages: any =
      selectedImageType == "checkin" ? locationImages : locationCheckOutImages;
    allImages.splice(index, 1);
    setShowModal(false);
    setSelectedImageIndex(0);
    if (selectedImageType == "checkin") {
      setLocationImages(allImages);
    } else if (selectedImageType == "checkout") {
      setLocationCheckOutImages(allImages);
    }
  };

  const customRating = (stars: any) => {
    setStars(stars);
    let tracking_detail = trackingDetail;
    tracking_detail.rating = stars;
    settrackingDetail(tracking_detail);
  };

  const handleReview = (e: any) => {
    setReview(e.target.value);
    let tracking_detail = trackingDetail;
    tracking_detail.review = e.target.value;
    settrackingDetail(tracking_detail);
  };

  const handleJobRating = () => {
    setLoading(true);
    var url = BASE_URL + "manager/rating";
    axios
      .post(url, {
        job_tracking_id: trackingDetail.id,
        rating: trackingDetail.rating,
        review: trackingDetail.review
      },
        {
          headers: { Authorization: `Bearer ${cookies.get('access_token_tlh_service')}` },
        })
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          setMessage(res.data.data);
          setShowToast(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <IonPage className="page">
      <IonHeader>
        <div className="header1">
          <div className="track-back-btn">
            <IonIcon
              icon={arrowBack}
              onClick={() => {
                history.goBack();
              }}
              size="large"
              className="text-white"
              style={{ padding: "0px" }}
            />
          </div>
          <h1 className="header-title1">Job Tracking Detail</h1>
        </div>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonModal isOpen={showModal}>
            <IonContent>
              <div className="modal-content">
                <div className="modal-header">
                  {/* <IonImg src={
                          selectedImageType == "checkin" &&
                          locationImages &&
                          locationImages.length > 0
                            ? locationImages[selectedImageIndex].image
                            : selectedImageType == "checkout" &&
                              locationCheckOutImages &&
                              locationCheckOutImages.length > 0
                            ? locationCheckOutImages[selectedImageIndex].image
                            : ""
                        } className='modal-image'/> */}

                  {/* <h1 className="modal-header-title">Attachment</h1> */}
                  <h1
                    onClick={() => setShowModal(false)}
                    className="modal-close"
                  >
                    <IonIcon icon={closeCircle} />
                  </h1>
                </div>
                <div className="modal-body">
                  <img
                    className="modal-image"
                    src={
                      selectedImageType == "checkin" &&
                        checkinImages &&
                        checkinImages.length > 0
                        ? ASSET_URL +
                        "/public/" +
                        checkinImages[selectedImageIndex].attachment_url
                        : selectedImageType == "checkout" &&
                          checkoutImages &&
                          checkoutImages.length > 0
                          ? ASSET_URL +
                          "/public/" +
                          checkoutImages[selectedImageIndex].attachment_url
                          : ""
                    }
                  />
                  <div className="modal-image-content">
                    <h3 className="modal-image-heading">
                      {selectedImageType == "checkin" &&
                        checkinImages &&
                        checkinImages.length > 0
                        ? checkinImages[selectedImageIndex].name
                        : selectedImageType == "checkout" &&
                          checkoutImages &&
                          checkoutImages.length > 0
                          ? checkoutImages[selectedImageIndex].name
                          : ""}
                    </h3>
                    {/* <div className="Modal-btnn">
                      <button
                        className="btn-contentt"
                        onClick={() => handleRemoveImage(selectedImageIndex)}
                      >
                        Remove
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <div className="management">
            <div className="menu-flex">
              <h3 className="sub-title">{jobDetail.job.location.name}</h3>
            </div>
            <h1 className="title">{jobDetail.job.title}</h1>
            {jobDetail && jobDetail.starts_at_formatted && jobDetail.ends_at_formatted ? <span className="time">{jobDetail.starts_at_formatted + '-' + jobDetail.ends_at_formatted}</span> : ''}
            {/* <span className="time">{jobDetail.starts_at_formatted + '-' + jobDetail.ends_at_formatted}</span> */}
            {/* <h6 className="progress-heading">14 mins remaining</h6>
              <IonProgressBar
                className="Progressbar"
                color="primary"
                value={0.2}
              ></IonProgressBar> */}
            <br />

            <div className="ion-address">
              <IonIcon className="gray-color" icon={pinOutline} />
              <p className="parmentant-address">
                {jobDetail.job.location.address}
              </p>
            </div>
            <div className="ion-phone">
              <IonIcon className="gray-color" icon={callOutline} />
              <p className="parmentant-no">{jobDetail.job.location.phone_no}</p>
            </div>

            <div className="ion-phone">
              <IonIcon className="gray-color" icon={alarmOutline} />
              <p className="parmentant-no">
                <b>Started at:</b> {trackingDetail.starts_at}
              </p>
            </div>
            <div className="ion-phone">
              <IonIcon className="gray-color" icon={timerOutline} />
              <p className="parmentant-no">
                <b>Ended at:</b> {trackingDetail.ends_at}
              </p>
            </div>

            <h3>Check-in attachments:</h3>
            <div className="flex-box">
              {checkinImages.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => handleImageClick(index, "checkin")}
                    className="box-content"
                    key={index}
                  >
                    {/* <h4 className="box-title">HM</h4> */}
                    <img
                      src={ASSET_URL + "/public/" + item.attachment_url}
                      className="box-title"
                    />
                    <p className="box-discription">{item.name}</p>
                  </div>
                );
              })}
            </div>

            <h3>Check-out attachments:</h3>
            <div className="flex-box">
              {checkoutImages.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => handleImageClick(index, "checkout")}
                    className="box-content"
                    key={index}
                  >
                    {/* <h4 className="box-title">HM</h4> */}
                    <img
                      src={ASSET_URL + "/public/" + item.attachment_url}
                      className="box-title"
                    />
                    <p className="box-discription">{item.name}</p>
                  </div>
                );
              })}
            </div>

            {trackingDetail && trackingDetail.early_checkout_reason ? (
              <>
                <h3>Early Check-out reason:</h3>
                <p>{trackingDetail.early_checkout_reason}</p>
              </>
            ) : (
              ""
            )}

            {userData.role.key !== 'customer' ?
              <>
                <h3>Rating:</h3>
                <Rating
                  customRating={(stars: any) => customRating(stars)}
                  star={stars}
                />

                <h3>Review</h3>
                {/* <IonTextarea
                    rows={6}
                    placeholder="Enter notes here..."
                    className="review-area"
                    inputMode={"text"}
                    onIonChange={(e: any) => {
                      setReview(e.detail.value);
                    }}
                  >
                    {review}
                  </IonTextarea> */}
                <textarea
                  rows={6}
                  placeholder="Enter notes here..."
                  className="review-area"
                  onChange={(e: any) => handleReview(e)}
                >
                  {review}
                </textarea>

                <button className="password-btn" onClick={() => handleJobRating()}>
                  {loading ? (
                    <IonSpinner className="load-spinner" name="crescent" />
                  ) : (
                    "Save"
                  )}
                </button>
              </>
              : ''}

          </div>
        </IonGrid>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default SingleTracking;
