import {
    IonCheckbox, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonPage, IonText
} from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import { BASE_URL } from "../BaseUrl";
import axios from "axios";

const CompanyList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation<{ data: any }>();
    const [companies, setCompanies] = useState<any[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
    const [token, setToken] = useState<any>();
    const history = useHistory();
    const cookies = new Cookies();

    useEffect(() => {
        const fetchData = async () => {
            const data = location.state?.data;
            setCompanies(data?.companies || []); // Default to empty array if data is undefined
            setToken(data?.token.token);
        };

        fetchData();
    }, [location]);

    const selectCompany = (company: any, type: any) => {
        setLoading(true);
        localStorage.removeItem("select_companies");

        if (type === 'single-item') {
            localStorage.setItem("select_companies", JSON.stringify([company]));
            logInWithSelectedCompany([company.id]); // Store single company ID
        } else {
            const companyIds: any = company.map((comp: any) => comp.id); // Get IDs of all selected companies
            localStorage.setItem("select_companies", JSON.stringify(company));
            logInWithSelectedCompany(companyIds);
        }

    };

    const logInWithSelectedCompany = (Companies: any) => {
        const url = BASE_URL + "login-selected-companies";

        axios.post(url,
            {
                companies: Companies // Add selected companies IDs array in payload
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then((res) => {
            setLoading(false);
            if (res.data.status) {
                localStorage.setItem("tlh_service_user", JSON.stringify(res.data.data));
                meDetaile();
            }
        }).catch((error: any) => {
            console.log(error);
            setLoading(false);
        });
    };

    const meDetaile = () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        localStorage.setItem("access_token_tlh_service", JSON.stringify(token));
        // cookies.set("access_token_tlh_service", token, {
        //     path: "/",
        //     expires: date,
        //     sameSite: "lax",
        // });
        const url = BASE_URL + "users/me";
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
        }).then((res) => {
            localStorage.setItem("tlh_service_user", JSON.stringify(res.data.data));
            setLoading(false);
            history.push("/page/dashboard");
        }).catch((error: any) => {
            console.log(error);
        });
    }

    return (
        <IonPage>
            <IonContent>
                <IonList lines="none" className="ion-overflow-y ion-text-center ion-h-100 ion-d-flex ion-align-items-center ion-justify-content-center ion-flex-column">
                    <IonText className="comapany-list-title ion-margin-vertical">Choose a company</IonText>
                    <IonItem onClick={() => selectCompany(companies, 'all-companies')} className="company-list-item ion-text-center">
                        <IonLabel className="company-item-title">Continue With All</IonLabel>
                    </IonItem>
                    {companies.length > 0 ? companies.map((item: any, index: any) => (
                        <IonItem onClick={() => selectCompany(item, 'single-item')} key={index} className="company-list-item">
                            <div className="logo-container">
                                <IonImg src={item.logo_url} />
                            </div>
                            <IonLabel className="company-item-title">{item.name}</IonLabel>
                        </IonItem>
                    )) : <IonText>No companies available</IonText>}
                </IonList>
            </IonContent>
            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={"Please wait..."}
            />
        </IonPage>
    );
};

export default CompanyList;
