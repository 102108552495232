import {
  IonGrid,
  IonHeader,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonToolbar,
  IonToast,
  IonSpinner,
  IonList,
  IonCol,
  IonButton,
  useIonViewWillEnter,
} from "@ionic/react";

import {
  eye,
  homeOutline,
  arrowBack,
  notificationsOutline,
  personCircle,
  mailOutline,
  notifications,
} from "ionicons/icons";
import { useState } from "react";
import "./Notifications.scss";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { postCall } from "../ApiCalls";

const cookies = new Cookies();

const Notifications: React.FC = () => {
  let history = useHistory();
  const [showSingleNotification, setShowSingleNotification] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<any>([]);
  const [singleNotification, setSingleNotification] = useState<any>();
  const [showToast, setShowToast] = useState<any>(false);
  const [toastMessage, setToastMessage] = useState<any>('');

  useIonViewWillEnter(() => {
    const url = BASE_URL + "notifications";
    axios
      .post(url, {}, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
        },
      })
      .then(async (res) => {
        if (res.data.status) {
          setNotifications(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const handleSingleNotificationClick = (index: any) => {
    setSingleNotification(notifications[index]);
    setShowSingleNotification(true);
  }

  const handleMarkAllAsRead = () => {
    postCall(BASE_URL + 'notifications/mark-as-read-all', {}).then(async (data: any) => {
      if (data.status) {
        setToastMessage(data.message);
        setShowToast(true);
        let all_notifications = notifications;
        // all_notifications.forEach((item : any, index: any)=>{
        //   item.is_read = 1;
        // });
        for await (let item of all_notifications) {
          item.is_read = 1;
        }
        setNotifications(all_notifications);
      }
    });
  }

  const handleMarkSingleNotificationAsRead = (index: any) => {
    let notification_id = notifications[index].id;
    postCall(BASE_URL + 'notifications/mark-as-read-single', {
      id: notification_id
    }).then(async (data: any) => {
      if (data.status) {
        setToastMessage(data.message);
        setShowToast(true);
        let all_notifications = notifications;
        all_notifications[index].is_read = 1;
        setNotifications(all_notifications);
      }
    });
  }

  return (
    <IonPage className="">
      <IonContent>
        {showSingleNotification ? (
          <>
            <IonHeader>
              <div className="header1">
                <div className="back-btn-container">
                  <IonIcon
                    icon={arrowBack}
                    onClick={() => {
                      setShowSingleNotification(!showSingleNotification);
                    }}
                    size="large"
                    className="text-white"
                  />
                </div>
                <h1 className="header-title1">Notification</h1>

                {/* <div className="notification-icon">
              <IonIcon icon={notificationsOutline} size="large"  />
              </div> */}
              </div>
            </IonHeader>

            <IonList lines="inset">
              <div className="single-notification-list">
                <div className="single-notification-content">
                  <IonLabel>{singleNotification.notification.title}</IonLabel>
                  <p>{singleNotification.notification.message}</p>
                </div>
              </div>
            </IonList>
          </>
        ) : (
          <>
            <IonHeader>
              <div className="header1">
                <div className="back-btn-container">
                  <IonIcon
                    icon={arrowBack}
                    onClick={() => history.goBack()}
                    size="large"
                    className="text-white"
                  />
                </div>
                <h1 className="header-title1">Notifications</h1>

                {/* <div className="notification-icon">
              <IonIcon icon={notificationsOutline} size="large"  />
              </div> */}
              </div>
            </IonHeader>
            {/* <div className="mark-read">
              <p>Mark as all read</p>
            </div> */}
            {loading ? (
              <div className="loader-container">
                <IonSpinner className="dash-spinner" name="crescent" />
              </div>
            ) : (
              <IonList className="ion-no-padding">
                <div className="notification-list">
                  {/* <div
                  className={
                    showSingleNotification ? "noti-gray-info" : "noti-info"
                  }
                  onClick={() => setShowSingleNotification(true)}
                >
                  <div className="notification-content">
                    <IonLabel>Varification Message</IonLabel>
                    <p>mathila please accept the payment</p>
                  </div>
                  <div className="icon-content">
                    <IonIcon slot="end" icon={mailOutline} />
                    <p onClick={() => setShowSingleNotification(true)}>
                      Mark as read
                    </p>
                  </div>
                </div> */}
                  <p className="read-all" onClick={() => handleMarkAllAsRead()}>Mark all as read</p>
                  {notifications.length > 0 ? notifications.map((item: any, index: any) => {
                    return (
                      <>
                        <div className="noti-gray-info" key={index} onClick={() => handleSingleNotificationClick(index)}>
                          <div className="notification-content">
                            <IonLabel>{item.notification.title}</IonLabel>
                            <p>{item.notification.message}</p>
                          </div>
                          <div className="icon-content">
                            <IonIcon slot="end" icon={mailOutline} />
                            <p onClick={() => handleMarkSingleNotificationAsRead(index)}>
                              Mark as read
                            </p>
                          </div>
                        </div>
                      </>
                    )
                  })
                    :
                    <p className="no-txt">No notifications found.</p>
                  }
                </div>
              </IonList>
            )}
          </>
        )}
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
