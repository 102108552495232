import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonAlert,
  IonButton,
  IonTextarea,
  useIonViewWillEnter,
  IonLoading,
  IonToast,
} from "@ionic/react";
import {
  Camera,
  CameraResultType,
  CameraSource,
  CameraDirection,
} from "@capacitor/camera";
import {
  chevronDown,
  reader,
  business,
  closeCircle,
  addCircleOutline,
  earthOutline,
  trailSignOutline,
  businessOutline,
  personOutline,
  cashOutline,
  locationOutline,
  mailOutline,
  callOutline,
  constructOutline,
  chevronForwardOutline,
  arrowBack,
} from "ionicons/icons";
import React, { useState } from "react";
import axios from "axios";
import { BASE_URL, API_URL, GET_COUNTRIES } from "../BaseUrl";
import Cookies from "universal-cookie";
import "./Quote.scss";
import "./JobDetail.scss";
import { postCall } from "../ApiCalls";
import { validate } from "../Validation";
import { Country, State, City } from "country-state-city";
import {
  useHistory
} from "react-router-dom";

const cookies = new Cookies();

const Quote: React.FC = () => {
  let history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState<any>(false);
  const [locationImages, setLocationImages] = useState<any>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>(0);
  const [base64Image, setBase64Image] = useState("");
  const [imageTitle, setImageTitle] = useState<any>("");
  const [showAlert, setShowAlert] = useState<any>(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  // const [countries, setCountries]= useState( Country.getAllCountries());
  const [isGB, setIsGB] = useState(false);
  const [countries, setCountries] = useState([
    {
      name: "United Kingdom",
      isoCode: "GB",
      id: 225
    },
    {
      name: "United States",
      isoCode: "US",
      id: 226
    },
    {
      name: "Spain",
      isoCode: "ES",
      id: 199
    },
  ]);
  const [countryid, setCountryid] = useState("");
  const [st, setSt] = useState([
    {
      name: "",
      isoCode: "",
    },
  ]);
  const [city, setCity] = useState([]);
  const [quote, setQuote] = useState<any>({
    first_name: null,
    last_name: null,
    email: null,
    phone_no: null,
    description: null,
    country: null,
    state: null,
    city: null,
    zip_code: null,
    address: null,
    company_id: null,
    job_type_id: null,
    customer_id: null,
    device_token: null,
    attachments: {},
  });
  const [company, setCompany] = useState([
    {
      key: "",
      id: "",
      name: "",
    },
  ]);
  const [cities, setCities] = useState<any>([{}]);
  const [jobtype, setJobType] = useState([
    {
      id: "",
      name: "",
      notes: "",
      key: "",
    },
  ]);

  const showToastMessage = (message: any) => {
    setToastMessage(message);
    setShowToast(true);
    setShowLoading(false);
  };

  const submitClick = () => {
    setShowLoading(true);
    let quoteData = quote;
    quoteData.attachments = locationImages;
    setQuote(quoteData);

    if (quoteData.first_name === '' || !quoteData.first_name) {
      showToastMessage("First name not valid");
      return;
    } else if (quoteData.last_name === '' || !quoteData.last_name) {
      showToastMessage("Last name not valid");
      return;
    } else if (validate(quoteData.email, "email")) {
      showToastMessage("Email not valid");
      return;
    } else if (quoteData.phone_no === '' || !quoteData.phone_no) {
      showToastMessage("Please enter valid phone number no without dashes");
      return;
    } else if (validate(quoteData.company_id, "company")) {
      showToastMessage("Please select a company");
      return;
    } else if (validate(quoteData.country, "country")) {
      showToastMessage("Please select a country");
      return;
    } else if (quoteData.state === '' || !quoteData.state) {
      showToastMessage("Provide a valid state name");
      return;
    } else if (quoteData.city === '' || !quoteData.city) {
      showToastMessage("Provide a valid city name");
      return;
    } else if (quoteData.zip_code === '' || !quoteData.zip_code) {
      showToastMessage("ZipCode is not valid");
      return;
    } else if (quoteData.job_type_id === '' || !quoteData.job_type_id) {
      showToastMessage("Please select a job type");
      return;
    } else if (validate(quoteData.address, "address")) {
      showToastMessage("Provide a valid address");
      return;
    } else if (quoteData.attachments.length <= 0) {
      showToastMessage("Please take atleast one picture");
      return;
    }

    var url = API_URL + "api/quotation-request-save";
    axios
      .post(url, quote, {
        headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
        },
      })
      .then((res) => {
        setShowLoading(false);
        let logged_user_data: any = localStorage.getItem("tlh_service_user");
        let user_data = JSON.parse(logged_user_data);
        let device_token = cookies.get("device_token_tlh_service");

        setQuote({
          first_name: null,
          last_name: null,
          email: null,
          phone_no: null,
          description: null,
          country: null,
          state: null,
          city: null,
          zip_code: null,
          address: null,
          company_id: null,
          job_type_id: null,
          customer_id: user_data?.id,
          device_token: device_token ? device_token.value : '',
          attachments: {},
        });
        setLocationImages([]);
        showToastMessage(res.data.message);
        if (res.data.status) {
          history.push('/page/login');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setStatesWithCountryId = (cid: string) => {
    let quoteData = quote;
    quoteData.state = null;
    quoteData.city = null;

    let cIndex = countries.findIndex((country) => {
      return country.name === cid;
    });

    let isoCode = countries[cIndex].isoCode;
    if (isoCode === "GB") {
      setIsGB(true);
      let cities = State.getStatesOfCountry(isoCode);
      setCities(cities);
    } else {
      setIsGB(false);
      let states = State.getStatesOfCountry(isoCode);
      setSt(states);
    }
  };

  const setCitiesWithStateId = (stId: string) => {
    let stIndex = st.findIndex((state) => {
      return state.name === stId;
    });

    let isoCode = st[stIndex].isoCode;
    let cities = City.getCitiesOfState(
      State.getStateByCode(isoCode)?.countryCode as string,
      State.getStateByCode(isoCode)?.isoCode as string
    );

    setCities(cities);
  };

  useIonViewWillEnter(() => {
    let logged_user_data: any = localStorage.getItem("tlh_service_user");
    let user_data: any = JSON.parse(logged_user_data);
    let device_token = cookies.get("device_token_tlh_service");

    setQuote({
      first_name: null,
      last_name: null,
      email: null,
      phone_no: null,
      description: null,
      country: null,
      state: null,
      city: null,
      zip_code: null,
      address: null,
      company_id: null,
      job_type_id: null,
      attachments: {},
      customer_id: user_data?.id,
      device_token: device_token ? device_token.value : ''
    });
    setShowLoading(true);
    let url = API_URL + "api/job-types/all";
    postCall(url, null)
      .then((data) => {
        setJobType(data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    url = API_URL + "api/companies/all";
    postCall(url, null)
      .then((data) => {
        setCompany(data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // url = API_URL + "api/quotation-request-save";
    // postCall(url, null)
    //   .then((data) => {
    //     setCompany(data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setShowLoading(false);
  });

  const handleRemoveImage = (index: any) => {
    let allImages: any = locationImages;
    allImages.splice(index, 1);
    setShowModal(false);
    setSelectedImageIndex(0);
    setLocationImages(allImages);
  };
  const handleCamera = () => {
    const cameraResult = Camera.getPhoto({
      quality: 20,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      direction: CameraDirection.Front,
      promptLabelCancel: "Cancel",
      correctOrientation: false,
    })
      .then((cameraResult: any) => {
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setBase64Image(base64Image);
        setShowAlert(true);
      })
      .catch((er: any) => {
        console.log(er);
      });
  };
  const handleImageUpdate = (alertData: any) => {
    setImageTitle(alertData.image_title);

    let images;
    let single_image_data;
    images = locationImages;
    single_image_data = {
      title: alertData.image_title,
      image: base64Image,
    };
    images.push(single_image_data);
    setLocationImages(images);
    setImageTitle("");
  };
  const handleImageClick = (index: any) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };
  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar className="quote_bg_primary ptb">
            <div className="quote_header_text">
              {/* <IonBackButton text="" icon="add"/> */}
              <IonIcon icon={arrowBack} onClick={() => {
                history.goBack();
              }} size="large" className="text-white" />
              <h4>Quote</h4>
              <div className="extra_class"></div>
            </div>
          </IonToolbar>
        </IonHeader>
        <div className="quote_wrapper">
          <div className="form">
            <h3 className="mtb_15 font_w_600">Quote</h3>
            <h6 className="m_none">Fill your details</h6>
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={personOutline} />
              <IonInput
                placeholder="First name"
                type="text"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.first_name = e.detail.value;
                  setQuote(quoteData);
                }}
                value={quote.first_name}
              ></IonInput>
            </IonItem>
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={personOutline} />
              <IonInput
                placeholder="Last name"
                type="text"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.last_name = e.detail.value;
                  setQuote(quoteData);
                }}
                value={quote.last_name}
              ></IonInput>
            </IonItem>
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={mailOutline} />
              <IonInput
                placeholder="Email"
                type="email"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.email = e.detail.value;
                  setQuote(quoteData);
                }}
                value={quote.email}
              ></IonInput>
            </IonItem>
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={callOutline} />
              <IonInput
                placeholder="Phone"
                type="tel"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.phone_no = e.detail.value;
                  setQuote(quoteData);
                }}
                value={quote.phone_no}
              ></IonInput>
            </IonItem>
            {/* <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={cashOutline} />
              <IonInput
                placeholder="Min price"
                type="number"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.min_price = e.detail.value;
                  setQuote(quoteData);
                }}
              ></IonInput>
            </IonItem> */}
            {/* <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={cashOutline} />
              <IonInput
                placeholder="Price"
                type="number"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.price = e.detail.value;
                  setQuote(quoteData);
                }}
                value={quote.price}
              ></IonInput>
            </IonItem> */}
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={business} />
              <IonLabel>Company</IonLabel>
              <IonSelect
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.company_id = e.detail.value!;
                  setQuote(quoteData);
                }}
                value={quote.company_id}
              >
                {company.map((companydata, index) => {
                  return (
                    <div key={index}>
                      <IonSelectOption value={companydata.id}>
                        {companydata.name}
                      </IonSelectOption>
                    </div>
                  );
                })}
              </IonSelect>
              <IonIcon icon={chevronDown} />
            </IonItem>
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={earthOutline} />
              <IonLabel>Country</IonLabel>
              <IonSelect
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.country = e.detail.value;
                  setQuote(quoteData);
                  //setStatesWithCountryId(e.detail.value);
                }}
                value={quote.country}
              >
                {countries.map((country, index) => {
                  return (
                    <div key={index}>
                      <IonSelectOption value={country.id}>
                        {country.name}
                      </IonSelectOption>
                    </div>
                  );
                })}
              </IonSelect>
              <IonIcon icon={chevronDown} />
            </IonItem>
            <div className="state_zip_city">
              <IonItem
                lines="none"
                className="box_shadow br_20 mt_25 quote_ion_input"
              >
                <IonIcon icon={businessOutline} />
                <IonInput
                  placeholder="State"
                  type="text"
                  onIonChange={(e) => {
                    let quoteData = quote;
                    quoteData.state = e.detail.value;
                    setQuote(quoteData);
                  }}
                  value={quote.state}
                ></IonInput>
              </IonItem>
              <IonItem
                lines="none"
                className="box_shadow br_20 mt_25 quote_ion_input"
              >
                <IonImg
                  className="img_w_20"
                  src={require("../images/city.png")}
                />
                <IonInput
                  style={{ marginRight: ".5rem" }}
                  placeholder="City"
                  type="text"
                  onIonChange={(e) => {
                    let quoteData = quote;
                    quoteData.city = e.detail.value!;
                    setQuote(quoteData);
                  }}
                  value={quote.city}
                ></IonInput>
              </IonItem>
              <IonItem
                lines="none"
                className="box_shadow br_20 mt_25 quote_ion_input"
              >
                <IonIcon icon={trailSignOutline} />
                <IonInput
                  placeholder="Zipcode"
                  type="text"
                  onIonChange={(e) => {
                    let quoteData = quote;
                    quoteData.zip_code = e.detail.value;
                    setQuote(quoteData);
                  }}
                  value={quote.zip_code}
                ></IonInput>
              </IonItem>
            </div>
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon icon={constructOutline} />
              <IonLabel>Job type</IonLabel>
              <IonSelect
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.job_type_id = e.detail.value!;
                  setQuote(quoteData);
                }}
                value={quote.job_type_id}
              >
                {jobtype.map((item, index) => {
                  return (
                    <div key={index}>
                      <IonSelectOption value={item.id}>
                        {item.name}
                      </IonSelectOption>
                    </div>
                  );
                })}
              </IonSelect>
              <IonIcon icon={chevronDown} />
            </IonItem>
            <IonItem
              lines="none"
              className=" box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon className=" pt_20" icon={locationOutline} />
              <IonTextarea
                rows={6}
                cols={20}
                className="quote_ion_input  m_none ptbl_10"
                placeholder="Address"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.address = e.detail.value;
                  setQuote(quoteData);
                }}
                value={quote.address}
              ></IonTextarea>
            </IonItem>
            <IonItem
              lines="none"
              className="box_shadow br_20 mt_25 quote_ion_input"
            >
              <IonIcon className=" pt_20" icon={reader} />
              <IonTextarea
                rows={6}
                cols={20}
                className="quote_ion_input  m_none ptbl_10"
                placeholder="Description"
                onIonChange={(e) => {
                  let quoteData = quote;
                  quoteData.description = e.detail.value;
                  setQuote(quoteData);
                }}
                value={quote.description}
              ></IonTextarea>
            </IonItem>
            <div className="flex-box  mt_25">
              {locationImages.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => handleImageClick(index)}
                    className="box-content"
                    key={index}
                  >
                    <img src={item.image} className="box-title" />
                    <p className="box-discription">{item.title}</p>
                  </div>
                );
              })}
              <div className="icon-box" onClick={() => handleCamera()}>
                <IonIcon className="Plus-icon" icon={addCircleOutline} />
              </div>
            </div>
            <IonModal isOpen={showModal}>
              <IonContent>
                <div className="modal-content">
                  <div className="modal-header">
                    <IonImg
                      src={
                        locationImages && locationImages.length > 0
                          ? locationImages[selectedImageIndex].image
                          : ""
                      }
                      className="modal-image"
                    />

                    <h1
                      onClick={() => setShowModal(false)}
                      className="modal-close"
                    >
                      <IonIcon icon={closeCircle} />
                    </h1>
                  </div>
                  <div className="modal-body">
                    <div className="modal-actions">
                      <h3 className="caption-img-heading">
                        {locationImages && locationImages.length > 0
                          ? locationImages[selectedImageIndex].title
                          : ""}
                      </h3>
                      <div className="Modal-btn">
                        <button
                          className="btn-content"
                          onClick={() => handleRemoveImage(selectedImageIndex)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </IonContent>
            </IonModal>
            <IonButton
              onClick={submitClick}
              className="w_100 btn_br_20 text_cap mt_25"
            >
              Submit
            </IonButton>
          </div>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          backdropDismiss={false}
          cssClass="my-custom-class"
          header={"Picture caption"}
          inputs={[
            {
              name: "image_title",
              type: "text",
              value: imageTitle ? imageTitle : "",
            },
          ]}
          buttons={[
            {
              text: "Save",
              handler: (alertData) => {
                handleImageUpdate(alertData);
              },
            },
          ]}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={1000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Quote;
