import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonIcon,
  IonProgressBar,
  IonAvatar,
  useIonAlert,
  IonAlert,
  IonToast,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonLoading,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  navigateOutline,
  locationOutline,
  mailOutline,
  calendarOutline,
  womanOutline,
  accessibilityOutline,
  walkOutline,
  callOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { IonModal, IonButton } from "@ionic/react";
import "./UserProfile.scss";
import Cookies from "universal-cookie";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";

const cookies = new Cookies();

const UserProfile: React.FC = () => {
  const [images, setImages] = useState<any>("");
  const [showAlert2, setShowAlert2] = useState(false);
  const [userData, setUserData] = useState<any>(cookies.get('tlh_service_user'));
  const [alertHeader, setAlertHeader] = useState<any>("");
  const [input, setInput] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  useIonViewWillEnter(() => {
    setUserData(cookies.get('tlh_service_user'));
  });

  useIonViewWillLeave(() => {
    setUserData(null);
  });

  const handleInput = (type: string) => {
    if (type == "first name") {
      let arr: any = [
        {
          name: "first_name",
          type: "text",
          value: userData.first_name ? userData.first_name : "Jane",
        },
      ];
      setInput(arr);
      setAlertHeader("Edit " + type);
      setShowAlert2(true);
      setType(type);
    } else if (type == "last name") {
      let arr: any = [
        {
          name: "last_name",
          type: "text",
          value: userData.last_name ? userData.last_name : "Mile",
        },
      ];
      setInput(arr);
      setAlertHeader("Edit " + type);
      setShowAlert2(true);
      setType(type);
    } else if (type == "email") {
      let arr: any = [
        {
          name: "email",
          type: "text",
          value: userData.email ? userData.email : "sumsols@gmail.com",
        },
      ];
      setInput(arr);
      setAlertHeader("Edit " + type);
      setShowAlert2(true);
      setType(type);
    } else if (type == "phone") {
      let arr: any = [
        {
          name: "phone",
          type: "text",
          value: userData.phn_no ? userData.phn_no : "+44 7911 123456",
        },
      ];
      setInput(arr);
      setAlertHeader("Edit " + type);
      setShowAlert2(true);
      setType(type);
    } else if (type == "address") {
      let arr: any = [
        {
          name: "address",
          type: "text",
          value: userData.address ? userData.address : "",
        },
      ];
      setInput(arr);
      setAlertHeader("Edit " + type);
      setShowAlert2(true);
      setType(type);
    }
  };

  const handleInputUpdate = (alertData: any) => {
    let data = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      address: userData.address,
      phone_no: userData.phone_no,
    };
    if (type == "first name") {
      setUserData({
        ...userData,
        first_name: alertData.first_name,
      });
      data.first_name = alertData.first_name;
    }
    if (type == "last name") {
      setUserData({
        ...userData,
        last_name: alertData.last_name,
      });
      data.last_name = alertData.last_name;
    } else if (type == "email") {
      if (alertData.email == "") {
        setMessage("Email must be provided");
        setShowToast(true);
        return;
      } else if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          alertData.email
        )
      ) {
        setMessage("Invalid Email");
        setShowToast(true);
        return;
      } else {
        setUserData({
          ...userData,
          email: alertData.email,
        });
        data.email = alertData.email;
      }
    } else if (type == "phone") {
      if (
        !/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(
          alertData.phone
        )
      ) {
        setMessage("Invalid phone number");
        setShowToast(true);
        return;
      } else {
        setUserData({
          ...userData,
          phone_no: alertData.phone,
        });
        data.phone_no = alertData.phone;
      }
    } else if (type == "address") {
      setUserData({
        ...userData,
        address: alertData.address,
      });
      data.address = alertData.address;
    }

    updateCall(data);
  };

  const updateCall = (data: any) => {
    setShowLoading(true);
    const url = BASE_URL + "users/update";
    axios
      .post(url, data, {
        headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("tlh_service_user", JSON.stringify(userData));

          var dat = userData;
          dat.first_name = data.first_name;
          dat.last_name = data.last_name;
          dat.address = data.address;
          dat.phone_no = data.phone_no;
          dat.image = data.image;
          setUserData(dat);
          cookies.remove("tlh_service_user");
          let date = new Date();
          date.setFullYear(date.getFullYear() + 1);
          cookies.set("tlh_service_user", dat, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          setMessage(res.data.message);
          setShowLoading(false);
          setShowToast(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCamera = () => {
    const cameraResult = Camera.getPhoto({
      quality: 20,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      direction: CameraDirection.Front,
      promptLabelCancel: "Cancel",
      correctOrientation: false,
    })
      .then(async (cameraResult) => {
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setImages(base64Image);

        var data = userData;
        data.profile_pic_url = base64Image;
        setUserData(data);
        let user_data = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          address: userData.address,
          phone_no: userData.phone_no,
          image: base64Image,
        };
        
        updateCall(user_data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  return (
    <IonPage>
      <IonContent className="user-content">
        <div className="user-position">
          <div className="position-relative">
            <div className="text-center">
              <IonButtons className="padding-b-30">
                <IonMenuButton>
                  <IonImg
                    src={require("../images/menu.png")}
                    className="manuPicture"
                  />
                </IonMenuButton>
              </IonButtons>
              <h1 className="user-title">
                {userData? userData.first_name : "Jane"}{" "}
                {userData ? userData.last_name : "Mile"}
              </h1>
              <h2 className="user-heading">
                {userData
                  ? userData.department? userData.department.name : ''
                  : "UX/UI designer"}
              </h2>
              <IonRow className="between">
                <IonIcon className="white" icon={locationOutline} />
                <h3 className="san">
                  {userData ? userData.address : "san francisco"}
                </h3>
              </IonRow>
            </div>
          </div>
          {/* <Link to="/page/detail-image"> */}
          <div className="position-absolute">
            <IonAvatar>
              <IonImg
                src={
                  images
                    ? images != ""
                      ? images
                      : require("../images/building.jpg")
                    : userData
                    ? userData.profile_pic_url
                    : require("../images/building.jpg")
                }
              />
            </IonAvatar>

            <div className="overlay1" onClick={() => handleCamera()}></div>
          </div>
          {/* </Link> */}
        </div>

        <div className="User-sidebar">
          <IonList>
            <IonItem
              lines="none"
              onClick={() => handleInput("first name")}
              className="sidebar"
            >
              <IonIcon icon={accessibilityOutline} />
              <p className="Email">
                {userData ? userData.first_name : "Jane"}
              </p>
            </IonItem>

            <IonItem
              lines="none"
              onClick={() => handleInput("last name")}
              className="sidebar"
            >
              <IonIcon icon={walkOutline} />
              <p className="Email">
                {userData ? userData.last_name : "Mile"}
              </p>
            </IonItem>

            <IonItem lines="none" onClick={() => {}} className="sidebar">
              <IonIcon icon={mailOutline} />
              <p className="Email">
                {userData ? userData.email : "sumsols@gmail.com"}
              </p>
            </IonItem>

            <IonItem
              lines="none"
              onClick={() => handleInput("address")}
              className="sidebar"
            >
              <IonIcon icon={navigateOutline} />
              <p className="Email">
                {userData ? userData.address : "Faisalabad"}
              </p>
            </IonItem>

            <IonItem
              lines="none"
              onClick={() => handleInput("phone")}
              className="sidebar"
            >
              <IonIcon icon={callOutline} />
              <p className="Email">
                {userData ? userData.phone_no : "+44 7911 123456"}
              </p>
            </IonItem>
          </IonList>
          <Link to="/page/change-password">
            <div className="Change-password">
              <a href="#">Change Password</a>
            </div>
          </Link>
          <IonAlert
            isOpen={showAlert2}
            onDidDismiss={() => setShowAlert2(false)}
            cssClass="my-custom-class"
            header={alertHeader}
            inputs={input}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {},
              },
              {
                text: "Ok",
                handler: (alertData) => {
                  handleInputUpdate(alertData);
                },
              },
            ]}
          />
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={900}
          cssClass="tlh-service-toast"
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default UserProfile;
