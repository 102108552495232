export const validate = (data, type) => {
    if(type === 'name'){
        if (!/^[a-zA-Z]*$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }else if(type === 'email'){
        if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }
    else if(type === 'phone'){
        // if(!/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(data)){
        //     return true;
        // }
        if(!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }
    else if(type === 'password' || data === '' || typeof data === 'undefined' || !data){
        if(!/^.{6,}$/.test(data)){
            return true;
        }
    }else if(type === 'price'){
        if (!/^[0-9]*$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }else if(type === "minPrice"){
        if (!/^[0-9]*$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }else if(type === "maxPrice"){
        if (!/^[0-9]*$/.test(data.maxPrice) || data.maxPrice === ''|| Number(data.maxPrice) < Number(data.minPrice) || typeof data.maxPrice === 'undefined' || !data){
            return true;
        }
    }else if(type === "country"){
        if (!/^[0-9]*$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }
    else if(type === "zipCode"){
        if (!/^.{1,20}$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    } else if(type === "company"){
        if (!/^[A-Za-z]*/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }
    else if(type === "jobType"){
        if (!/^[A-Za-z]*/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }
    else if(type === "city"){
        if (!/^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }else if(type === "state"){
        if (!/^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/.test(data) || data === '' || typeof data === 'undefined' || !data){
            return true;
        }
    }
    else if(type === "address" || !data){
        if ( data === '' || typeof data === 'undefined'){
            return true;
        }
    }

    return false;
}
