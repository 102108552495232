import react, {useState} from "react";
import './RatingStar.scss';

type ChildProps = {
  star: any;
  customRating: any
}
const RatingStar: React.FC<ChildProps> = (props) => {
    const [rating, setRating] = useState<any>(props.star);
    const [hover, setHover] = useState<any>(0);
    
    return (
      <div className="star-rating">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || rating) ? "on" : "off"}
              onClick={() => {
                setRating(index)
                props.customRating(index);
              }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </div>
    );
  };
  export default RatingStar;

// import React, { useState } from 'react'
// import StarRatings from 'react-star-ratings';


//  const RatingStar: React.FC=()=> {
//     // changeRating=( newRating:any , name:any ) {
//     //     this.setState({
//     //       rating: newRating
//     //     });
//     //   }
//       const [rating ,setrating] = useState(4.5)
//     //  const changeRating=(newRating:any,name:any)=>{
//     //     setrating(newRating)
//     //   }
  
    
//     return (
//         <StarRatings
//         rating={rating}
//         starRatedColor="orange"
//         starDimension="30px"
//         starSpacing="5px"
//         name='rating'
//         changeRating={(newRating)=>{
           
//             setrating((newRating))
//         }}
//       />
//     )
//   }

//   export default RatingStar;