import { IonContent, IonIcon, IonImg, IonPage, useIonViewWillEnter, IonSpinner } from "@ionic/react"
import axios from "axios";
import { arrowBackOutline, sparklesOutline } from "ionicons/icons"
import { useHistory } from "react-router"
import { BASE_URL } from "../BaseUrl";
import "./QuoteRequests.scss";
import Cookies from "universal-cookie";
import { useState } from "react";

const QuoteRequests = () => {
    let history = useHistory();
    let cookies = new Cookies();

    const [loading, setLoading] = useState<any>(true);
    const [quoteRequests, setQuoteRequests] = useState<any>([]);
    const [singleQuote, setSingleQuote] = useState<any>();
    const [quoteView, setQuoteView] = useState<any>(false);

    useIonViewWillEnter(() => {
        // const url = BASE_URL + "customer/jobs";
        const url = BASE_URL + "customer/quotation-requests";
        axios
            .post(url, {
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
                },
            }
            )
            .then(async (res) => {
                if (res.data.status) {
                    setLoading(false);
                    setQuoteRequests(res.data.data);
                } else {
                    setLoading(false);
                    setQuoteRequests([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    });

    const handleQuoteClick = (item: any) => {
        setSingleQuote(item);
        cookies.set('single-quote-request', item);
        history.push('/single-quote-request');
    }

    return (
        <IonPage>
            <IonContent>
                <div className="quote-wrapper">
                    <div className="header">
                        <IonIcon icon={arrowBackOutline} size="large" onClick={() => history.goBack()} />
                        <h1>Quote Requests</h1>
                    </div>
                    <div className="quote-body">
                        {loading ?
                            <div className="loader-container">
                                <IonSpinner className="dash-spinner" name="crescent" />
                            </div>
                            :
                            quoteRequests.map((item: any, index: any) => {
                                return (
                                    <div key={index} className="quote-item" onClick={() => handleQuoteClick(item)}>
                                        <IonIcon icon={sparklesOutline} size="large" className="text-white" />
                                        {/* <IonImg src={item.location? item.job.location.location_pic_url : require("../images/location-avatar.png")}/> */}

                                        <div className="job-description">
                                            {/* <span className="card-timing">
                                            9am to 6pm
                                        </span> */}
                                            <span className="font-weight-700 address">
                                                {item.address}
                                            </span>
                                            <span className="area">
                                                <b>Job Type: </b>{item.job_type.name}
                                            </span>
                                            <span className="area">
                                                <b>Requested at: </b>{item.created_at_formatted}
                                            </span>
                                        </div>
                                        <div className="job-description">
                                            <span className="font-weight-700 status">
                                                {item.quotation_request_status.name}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default QuoteRequests
