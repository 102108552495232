import { IonContent, IonGrid, IonHeader, IonPage, IonTitle,IonButtons,IonMenuButton, IonToolbar, IonList, IonItem, IonLabel, IonInput, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import {
  eyeOutline 
} from 'ionicons/icons'
import './Home.css';
import './Home.scss';
import { useState } from 'react';

const Home: React.FC = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
};
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar >
        <IonImg src={require('../images/pexels-photo-1662159.jpeg')} className='hearder-image' style={{margin: "-4px",}} />
        </IonToolbar>
      </IonHeader>
        <IonContent>
          
          <IonGrid className='grid'>
           <IonLabel className='label-setting' position="floating">Email Address</IonLabel>
           <IonItem className='input-setting' lines='none'>
            <IonInput />
          </IonItem>

          <IonLabel className='label-setting' position="floating">Password</IonLabel>
           <IonItem lines='none' className='input-setting'>
            <IonInput type={passwordShown ? "text" : "password"}/>
            <IonIcon onClick={togglePassword} icon={eyeOutline} />
          </IonItem>
          <div className='Login'>
          <button  className='btn'>LogIn</button>
          </div>

          <IonRow className='signup-link'>
            <IonCol >
              <a href="#" >Signup</a>
            </IonCol >
            <IonCol className='forget-link'>
              <a href="#" >Forget Password</a>
            </IonCol>
          </IonRow>

          </IonGrid>
            </IonContent>
    </IonPage>
  );
};

export default Home;



// import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, 
//   IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonFab, 
//   IonFabButton, IonFabList, IonHeader, IonIcon, IonItem, IonItemOption, 
//   IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, 
//   IonMenuButton, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
  
//   import React, { useState } from 'react';
//   // import './Dashboard2.scss';
//   // import {addCircle, arrowBackCircle, key, add, addCircleOutline, timeOutline, folderOpen, calendarOutline, cardOutline} from 'ionicons/icons';
//   import { Line, Bar, Pie , Doughnut} from 'react-chartjs-2';

//   const Home: React.FC = (props) => {

//       //Set Data for Bar Chart. In Realtime you may bing this using the data coming from API or service. 
//       const barChartData = {
//       labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//       datasets: [
//         {
//           label: 'My First dataset',
//           backgroundColor: 'rgba(255,99,132,0.2)',
//           borderColor: 'rgba(255,99,132,1)',
//           borderWidth: 1,
//           hoverBackgroundColor: 'rgba(255,99,132,0.4)',
//           hoverBorderColor: 'rgba(255,99,132,1)',
//           data: [65, 59, 80, 81, 56, 55, 40]
//         }
//       ]
//       };
      
//       //Set Data for Doughnut Chart. In Realtime you may bing this using the data coming from API or service. 
//       const doughnutChartData = {
//       labels: ['Billable', 'Non Billable'],
//       datasets: [
//         {
//           label: 'Billable Vs. Non Billable',
//           backgroundColor: ['#36a2eb', 'rgba(255,99,132,0.2)'],
//           borderColor: 'rgba(255,99,132,1)',
//           borderWidth: 1,
//           hoverBackgroundColor: 'rgba(255,99,132,0.4)',
//           hoverBorderColor: 'rgba(255,99,132,1)',
//           data: [65, 59]
//         }
//       ]
//       };
      
      
//       return (
//       <IonPage>
//            <IonHeader>
//                   <IonToolbar  color="secondary">
//                       <IonButtons slot="start">
//                           <IonMenuButton />
//                       </IonButtons>
//                       <IonTitle>Dashboard</IonTitle>
//                   </IonToolbar>
//               </IonHeader>
//         <IonContent class="ion-padding">
      
//               <IonList>
//                 <IonItem>
//                   //Bind the Bar Chart with the Data
//                   <Bar data={barChartData}
//                       options={{ maintainAspectRatio: true}}   />
//                 </IonItem>
//                 <IonItem>
//                   <Doughnut data={doughnutChartData}
//                       options={{ maintainAspectRatio: true}}   />
//                 </IonItem>
//                 <IonItem>
//                   <Pie data={doughnutChartData}
//                       options={{ maintainAspectRatio: true}}   />
//                 </IonItem>
//               </IonList>
      
//         </IonContent>
//       </IonPage>
//       );
//       };
      
//       export default Home;