import {
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonToolbar,
    IonRow,
    IonCol,
    IonImg,
    IonButtons,
    IonMenuButton,
    IonSpinner,
    IonIcon,
    useIonViewWillEnter,
    IonAvatar,
    IonBadge,
} from "@ionic/react";
import {
    eye,
    homeOutline,
    arrowBack,
    calendarOutline,
    timerOutline,
    create,
} from "ionicons/icons";
import "./Dashboard.scss";
import "./JobTrackings.scss";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const cookies = new Cookies();

const AdminJobTrackings: React.FC = () => {
    let history = useHistory();
    let location: any = useLocation();
    let jobDetail: any = localStorage.getItem("job-detail");

    const [loading, setLoading] = useState(true);
    const [usersTrackings, setUsersTrackings] = useState<any>([]);
    const [job, setJob] = useState<any>({});

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const jobdetail = location?.state?.job_detail ? location?.state?.job_detail : JSON.parse(jobDetail);

        const url = BASE_URL + `admin/jobs/${jobdetail.id}/show`;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
            },
        }).then((res) => {
            if (isMounted && res.data.status) {
                setUsersTrackings(res.data?.data?.job?.job_employees);
                setJob(res?.data?.data?.job);
                setLoading(false);
            }
        }).catch((error) => {
            if (isMounted) {
                console.log(error);
            }
        });

        return () => {
            isMounted = false;
        };
    }, []);

    const handleTrackingClick = (tracking: any, type: any) => {
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        localStorage.setItem("tlh_service_tracking_detail", JSON.stringify(tracking));
        history.push('/employee-detail', { data: tracking, type });
    };

    return (
        <IonPage>
            <IonHeader className="history-header">
                <IonRow className="row">
                    <IonCol className="column" size="12">
                        <div className="flex">
                            <IonIcon
                                icon={arrowBack}
                                onClick={() => {
                                    history.goBack();
                                }}
                                size="large"
                                className="text-white"
                            />
                            <h3>Job Detail</h3>
                            <IonIcon
                                icon={timerOutline}
                                size="large"
                                className="text-white calendar-icon"
                                onClick={() => {
                                    history.push("/page/time-line");
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
            </IonHeader>
            {loading ? (
                <div className="loader-container">
                    <IonSpinner className="dash-spinner" name="crescent" />
                </div>
            ) : (
                <IonContent className="wrapper">
                    {loading ? (
                        <div className="loader-container">
                            <IonSpinner className="dash-spinner" name="crescent" />
                        </div>
                    ) : (
                        <>
                            <div className="ion-padding ion-d-flex">
                                <IonAvatar>
                                    <IonImg
                                        src={
                                            job.avatar ?
                                                job.avatar
                                                : require("../images/location.jpg")
                                        }
                                    />
                                </IonAvatar>
                                <div className="ion-padding-start">
                                    <h2 className="ion-no-margin">{job?.title}</h2>
                                    <p className="ion-no-margin">{job?.location?.address}</p>
                                    <IonBadge className="ion-text-capitalize" style={{ marginTop: "5px" }}>{job?.status}</IonBadge>
                                </div>
                            </div>
                            <IonGrid>
                                <IonRow className="row">
                                    {usersTrackings.map((item: any, index: any) => {
                                        return (
                                            <IonCol
                                                key={index}
                                                className="column"
                                                size="12"
                                            >
                                                <IonImg
                                                    src={
                                                        item?.employee?.profile_pic_url
                                                            ?
                                                            item?.employee?.profile_pic_url
                                                            : require("../images/dummy-image.jpg")
                                                    }
                                                />
                                                <div style={{ width: '100%' }} className="job-description">
                                                    <div className="ion-d-flex ion-align-items-center ion-justify-content-between">
                                                        <span className="card-timing">{item?.employee?.full_name}</span>
                                                        <div className="ion-margin-bottom ion-d-flex ion-justify-content-end ion-align-items-center">
                                                            <IonIcon onClick={() => handleTrackingClick(item, 'edit')} icon={create} mode="ios" color="primary" size="large" />
                                                            <IonIcon onClick={() => handleTrackingClick(item, 'view')} className="ion-margin-start" icon={eye} mode="ios" color="primary" size="large" />
                                                        </div>
                                                    </div>
                                                    <span className="address">
                                                        Started at:
                                                        {item?.starts_at_formatted
                                                            ? item?.starts_at_formatted
                                                            : ""}
                                                    </span>
                                                    <span className="area">
                                                        Ended at:{" "}
                                                        {item?.ends_at_formatted ? item?.ends_at_formatted : ""}
                                                    </span>
                                                    {item.review ? (
                                                        <span className="rating-box">
                                                            <img
                                                                className="rating-img"
                                                                src={require("../images/check-mark.png")}
                                                            />{" "}
                                                            reviewed
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </IonCol>
                                        );
                                    })}
                                    {usersTrackings.length <= 0 ? (
                                        <div className="no-data">
                                            <img src={require("../images/sad.png")} />
                                            <h4>No data available at the moment!</h4>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </IonRow>
                            </IonGrid>
                        </>
                    )}
                    {/* <RatingStar/> */}
                </IonContent>
            )}
        </IonPage>
    );
};
export default AdminJobTrackings;
