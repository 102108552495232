import { IonContent, IonIcon, IonImg, IonPage, useIonViewWillEnter, IonSpinner } from "@ionic/react"
import axios from "axios";
import { arrowBackOutline, sparklesOutline } from "ionicons/icons"
import { useHistory } from "react-router"
import { BASE_URL } from "../BaseUrl";
import "./SingleQuoteRequest.scss";
import Cookies from "universal-cookie";
import { useState } from "react";

const SingleQuoteRequest = () => {
    let history = useHistory();
    let cookies = new Cookies();

    const [singleQuote, setSingleQuote] = useState<any>(cookies.get('single-quote-request'));
    const [quoteView, setQuoteView] = useState<any>(false);

    useIonViewWillEnter(() =>{
    });

  return (
    <IonPage>
        <IonContent>
            <div className="quote-wrapper">
                <div className="header">
                    <IonIcon icon={arrowBackOutline} size="large" onClick={()=>  history.goBack()} />
                    <h1>Quote Request</h1>
                </div>
                <div className="quote-body">
                    <div className="job-description">
                        <h5 className="heading">Full Name:</h5>
                        <p className="text-data">{singleQuote.full_name}</p>
                        <h5 className="heading">Address:</h5>
                        <p className="text-data">{singleQuote.address}</p>
                        <h5 className="heading">City:</h5>
                        <p className="text-data">{singleQuote.city}</p>
                        <h5 className="heading">State:</h5>
                        <p className="text-data">{singleQuote.state}</p>
                        <h5 className="heading">Country:</h5>
                        <p className="text-data">{singleQuote.country}</p>
                        <h5 className="heading">Email:</h5>
                        <p className="text-data">{singleQuote.email}</p>
                        <h5 className="heading">Phone Number:</h5>
                        <p className="text-data">{singleQuote.phone_no}</p>
                        <h5 className="heading">Zip Code:</h5>
                        <p className="text-data">{singleQuote.zip_code}</p>
                        <h5 className="heading">Job Type:</h5>
                        <p className="text-data">{singleQuote.job_type.name}</p>
                        <h5 className="heading">Company:</h5>
                        <p className="text-data">{singleQuote.company.name}</p>
                        <h5 className="heading">Description:</h5>
                        <p className="text-data">{singleQuote.description}</p>
                        <h5 className="heading">Quotation Request Status:</h5>
                        <p className="text-data status">{singleQuote.quotation_request_status.name}</p>
                    </div>
                </div>
            </div>
        </IonContent>
    </IonPage>
  )
}

export default SingleQuoteRequest;
