import { IonPage, IonContent, IonHeader, IonButtons,  IonToolbar, IonIcon, IonLabel, useIonViewWillEnter, IonLoading } from "@ionic/react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./Dashboard.scss";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { chevronBackOutline } from "ionicons/icons";
import Cookies from "universal-cookie";

const PortFolioDetail: React.FC = () => {
  let history = useHistory();
  let location:any = useLocation();
  const cookies = new Cookies();

  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [portfolioProject, setPortfolioProject] = useState<any>(null);
  // const [showLoading, setShowLoading] = useState<any>(true);

  useIonViewWillEnter(() => {
    let portfolio_detail = cookies.get('tlh_service_single_portfolio');
    setPortfolioProject(portfolio_detail);
    // setTimeout(()=>{
    //   setShowLoading(false);
    // }, 1000);
  });

  const bacKBtn = () => {
    setPortfolioProject(null);
    history.push("/page/job-holder-profile");
  }

  return (
    <IonPage>
      {/* <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      /> */}
      <IonHeader>
        <IonToolbar className="header-bg">
          <IonButtons onClick={bacKBtn}>
            <IonIcon className="back-btn" icon={chevronBackOutline} />
            <IonLabel className="header-label">Back</IonLabel>
            {/* <IonBackButton className="back-btn" defaultHref="job-holder-profile" mode="ios" text="Back"></IonBackButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Swiper
          // style={{
          //   "--swiper-navigation-color": "#fff",
          //   "--swiper-pagination-color": "#fff",
          // }}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {portfolioProject && portfolioProject.attachment.map((item: any, index: any)=>{
            return(
              <SwiperSlide key={index}>
                <img src={item.attachment_url} alt={index} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {portfolioProject && portfolioProject.attachment.map((item: any, index: any)=>{
            return(
              <SwiperSlide key={index}>
                <img src={item.attachment_url} alt={index} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* <Swiper
          style={{
            "--swiper-navigation-color": "#fff";
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          <SwiperSlide>
            <IonImg src={require("../images/p1.jfif")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src={require("../images/p2.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src={require("../images/p3.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src={require("../images/p4.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src="https://swiperjs.com/demos/images/nature-10.jpg" />
          </SwiperSlide>
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          <SwiperSlide>
            <IonImg src={require("../images/p1.jfif")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src={require("../images/p2.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src={require("../images/p3.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src={require("../images/p4.jpg")} />
          </SwiperSlide>
          <SwiperSlide>
            <IonImg src="https://swiperjs.com/demos/images/nature-10.jpg" />
          </SwiperSlide>
        </Swiper> */}
      </IonContent>
    </IonPage>
  );
};
export default PortFolioDetail;