import {
  IonGrid,
  IonHeader,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonProgressBar,
  IonToast,
  IonAlert,
  IonSpinner,
  IonModal,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";

import {
  pinOutline,
  callOutline,
  arrowBack,
  addCircleOutline,
  closeCircle,
} from "ionicons/icons";
import "./JobDetail.scss";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { useState } from "react";
import Cookies from "universal-cookie";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";

const JobDetail: React.FC = () => {
  let history = useHistory();
  let location: any = useLocation();
  const cookies = new Cookies();

  const [jobDetail, setJobDetail] = useState<any>("");
  const [jobTrackingId, setJobTrackingId] = useState<any>("");
  const [showAlert, setShowAlert] = useState<any>(false);
  const [showAlert2, setShowAlert2] = useState<any>(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [base64Image, setBase64Image] = useState<any>("");
  const [imageTitle, setImageTitle] = useState<any>("");
  const [locationImages, setLocationImages] = useState<any>([]);
  const [locationCheckOutImages, setLocationCheckOutImages] = useState<any>([]);
  const [checkedIn, setCheckedIn] = useState(false);
  const [loading, setLoading] = useState<any>(true);
  const [checkinLoading, setCheckinLoading] = useState<any>(false);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>(0);
  const [selectedImageType, setSelectedImageType] = useState<any>(0);
  const [hours, setHours] = useState<any>("00");
  const [minutes, setMinutes] = useState<any>("00");
  const [seconds, setSeconds] = useState<any>("00");
  const [jobCompletedToday, setJobCompletedToday] = useState<any>(false);
  const [number, setNumber] = useState<any>(0);
  const [earlyCheckoutReason, setEarlyCheckoutReason] = useState<any>("");

  let myTimeout: any;

  useIonViewWillEnter(() => {
    setLoading(true);
    resetTimer();

    // let jobData = cookies.get("job-detail");
    let jobData = location.state.job_detail;
    setJobDetail(jobData);
    resetTimer();
    const url = BASE_URL + "jobs/running-trackings";
    axios
      .post(
        url,
        {
          job_employee_id: jobData.id,
        },
        {
          headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status && res.data.data) {
          msToTime(res.data.data.starts_at);
          let locImages: any = [];
          res.data.data.attachments.map((item: any, index: any) => {
            let locSingleImage = {
              image: ASSET_URL + "public/" + item.attachment_url,
              title: item.name,
            };
            locImages.push(locSingleImage);
          });
          setTimeout(() => {
            setLocationImages(locImages);
            setJobTrackingId(res.data.data.id);
            setLoading(false);
            setCheckedIn(true);
          }, 500);
        } else if (res.data.status && !res.data.data) {
          setLoading(false);
          setJobCompletedToday(false);
        } else {
          setLoading(false);
          setMessage(res.data.message);
          setShowToast(true);
          setJobCompletedToday(true);
          resetTimer();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  useIonViewWillLeave(() => {
    setLocationImages([]);
    setLocationCheckOutImages([]);
    resetTimer();
  });

  const resetTimer = () => {
    clearInterval(number);
    setHours("00");
    setMinutes("00");
    setSeconds("00");
  };

  let timerCycle = (s: any, m: any, h: any) => {
    clearInterval(myTimeout);
    let sec: any = parseInt(s);
    let min: any = parseInt(m);
    let hr: any = parseInt(h);

    sec = sec + 1;

    if (sec == 60) {
      min = min + 1;
      sec = 0;
    }
    if (min == 60) {
      hr = hr + 1;
      min = 0;
      sec = 0;
    }

    if (sec < 10 || sec == 0) {
      sec = "0" + sec;
    }
    if (min < 10 || min == 0) {
      min = "0" + min;
    }
    if (hr < 10 || hr == 0) {
      hr = "0" + hr;
    }

    setSeconds(sec);
    setMinutes(min);
    setHours(hr);

    myTimeout = setTimeout(() => timerCycle(sec, min, hr), 1000);
    //console.log(setTimeout(() => timerCycle(sec, min, hr), 1000));
  };

  const msToTime = (starttime: any) => {
    myTimeout = setInterval(() => {
      setNumber(myTimeout);
      let d = new Date();
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      let p = new Date(starttime);

      let duration = d.getTime() - p.getTime();
      duration = Math.abs(duration);
      var milliseconds: any = Math.floor((duration % 1000) / 100),
        seconds: any = Math.floor((duration / 1000) % 60),
        minutes: any = Math.floor((duration / (1000 * 60)) % 60),
        hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);

    // if(myTimeout){
    //   clearInterval(number);
    // }
    // myTimeout = setInterval(() => {msToTime(starttime)}, 1000);
    // setNumber(myTimeout);
    // console.log(myTimeout);

    // myTimeout = setTimeout(() => msToTime(starttime), 1000);
    //timerCycle(seconds, minutes, hours);
    //myTimeout = setTimeout(() => timerCycle(seconds, minutes, hours), 1000);
    //myTimeout = setInterval(() => timerCycle(seconds, minutes, hours), 1000);
    //console.log(setInterval(() => timerCycle(seconds, minutes, hours), 1000));
  };

  const handleCamera = (type: any) => {
    setSelectedImageType(type);
    const cameraResult = Camera.getPhoto({
      quality: 20,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      direction: CameraDirection.Front,
      promptLabelCancel: "Cancel",
      correctOrientation: false,
    })
      .then((cameraResult) => {
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        let base64Image = "data:image/jpeg;base64," + cameraResult.base64String;
        setBase64Image(base64Image);
        setShowAlert(true);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const handleImageUpdate = (alertData: any) => {
    setImageTitle(alertData.image_title);

    let images;
    let single_image_data;
    if (selectedImageType == "checkin") {
      images = locationImages;
      single_image_data = {
        title: alertData.image_title,
        image: base64Image,
      };
      images.push(single_image_data);
      setLocationImages(images);
    } else if (selectedImageType == "checkout") {
      images = locationCheckOutImages;
      single_image_data = {
        title: alertData.image_title,
        image: base64Image,
      };
      images.push(single_image_data);
      setLocationCheckOutImages(images);
    }
    setImageTitle("");
  };

  const reasonValidation = (reason: any) => {
    if (reason == "") {
      return false;
    } else {
      return true;
    }
  };

  const updateReason = (alertData: any) => {
    if (reasonValidation(alertData.reason)) {
      setEarlyCheckoutReason(alertData.reason);
      checkoutRequest(alertData.reason);
    } else {
      setMessage("Reason not provided");
      setShowToast(true);
    }
  };

  const jobRemainingTime = () => {
    // let jobDetail = cookies.get("job-detail");
    let job_details: any = localStorage.getItem("job-detail");
    let jobDetail = job_details;

    if (!jobDetail.ends_at && !checkedIn) {
      return 1;
    } else if (!jobDetail.ends_at && checkedIn) {
      return -1;
    } else {
      let d = new Date();
      let year = d.getFullYear();
      let month: any = d.getMonth();
      month = parseInt(month) + 1;
      let hours: any = d.getHours();
      if (hours < 10) {
        hours = "0" + hours;
      }
      let minutes: any = d.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let seconds: any = d.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      if (month < 10) {
        month = "0" + month;
      }
      let date = d.getDate();
      let date_today = year + "-" + month + "-" + date;

      var t1 = new Date(
        date_today + " " + hours + ":" + minutes + ":" + seconds
      );
      var t2 = new Date(date_today + " " + jobDetail.ends_at);
      var dif = (t2.getTime() - t1.getTime()) / 1000;
      return dif;
    }
  };

  const checkoutRequest = (reason: any) => {
    setCheckinLoading(true);
    const url = BASE_URL + "jobs/check-out";
    let payloadData;

    if (reason == "") {
      payloadData = {
        job_tracking_id: jobTrackingId,
        attachments: locationCheckOutImages,
      };
    } else {
      payloadData = {
        job_tracking_id: jobTrackingId,
        attachments: locationCheckOutImages,
        early_checkout_reason: reason,
      };
    }

    axios
      .post(url, payloadData, {
        headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
        },
      })
      .then(async (res) => {
        setCheckinLoading(false);
        if (res.data.status) {
          handlerStop();
          //resetTimer();
          setCheckedIn(false);
          setMessage(res.data.message);
          setShowToast(true);
          setLocationImages([]);
          setLocationCheckOutImages([]);
          //window.location.reload();
          history.push("/page/dashboard");
        } else {
          setCheckedIn(true);
          setCheckinLoading(false);
          setMessage(res.data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckIn = () => {
    if (checkedIn) {
      if (locationCheckOutImages.length > 0) {
        if (jobRemainingTime() > 0) {
          setShowAlert2(true);
        } else {
          checkoutRequest("");
        }
      } else {
        setMessage("Please capture atleast one check-out picture");
        setShowToast(true);
      }
    } else {
      if (locationImages.length > 0) {
        if (jobRemainingTime() > 0) {
          setCheckinLoading(true);
          const url = BASE_URL + "jobs/check-in";
          axios
            .post(
              url,
              {
                job_employee_id: jobDetail.id,
                attachments: locationImages,
              },
              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
                },
              }
            )
            .then((res) => {
              setCheckinLoading(false);
              if (res.data.status) {
                // myTimeout = setTimeout(() => msToTime(res.data.data.starts_at), 1000);
                // setNumber(myTimeout);
                msToTime(res.data.data.starts_at);
                setCheckedIn(true);
                setJobTrackingId(res.data.data.id);
                setMessage(res.data.message);
                setShowToast(true);
              } else {
                setCheckedIn(false);
                setCheckinLoading(false);
                setMessage(res.data.message);
                setShowToast(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setMessage("Can not check in at this time");
          setShowToast(true);
        }
      } else {
        setMessage("Please capture atleast one check-in picture");
        setShowToast(true);
      }
    }
  };

  const handleImageClick = (index: any, type: any) => {
    setSelectedImageType(type);
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const handleRemoveImage = (index: any) => {
    let allImages: any =
      selectedImageType == "checkin" ? locationImages : locationCheckOutImages;
    allImages.splice(index, 1);
    setShowModal(false);
    setSelectedImageIndex(0);
    if (selectedImageType == "checkin") {
      setLocationImages(allImages);
    } else if (selectedImageType == "checkout") {
      setLocationCheckOutImages(allImages);
    }
  };

  const handler = () => {
    //msToTime("2022-04-22 15:19:40");
    myTimeout = setInterval(() => {
      setNumber(myTimeout);
      let d = new Date();
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      let p = new Date("2022-04-22 15:19:40");

      let duration = d.getTime() - p.getTime();
      duration = Math.abs(duration);
      var milliseconds: any = Math.floor((duration % 1000) / 100),
        seconds: any = Math.floor((duration / 1000) % 60),
        minutes: any = Math.floor((duration / (1000 * 60)) % 60),
        hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);
  };

  const handlerStop = () => {
    clearInterval(number);
    setHours("00");
    setMinutes("00");
    setSeconds("00");
  };

  return (
    <IonPage className="page">
      <IonHeader>
        <div className="back-btn-containerr">
          <IonIcon
            icon={arrowBack}
            onClick={() => {
              history.goBack();
            }}
            size="large"
            className="text-white"
          />
        </div>
        <IonToolbar className="job-timeing">
          <h1 className="end-time">
            {hours}:{minutes}:{seconds}
          </h1>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="loader-container">
            <IonSpinner className="dash-spinner" name="crescent" />
          </div>
        ) : (
          <IonGrid>
            <IonModal isOpen={showModal}>
              <IonContent>
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <h1 className="modal-header-title">Attachment</h1> */}
                    <IonImg
                      src={
                        selectedImageType == "checkin" &&
                          locationImages &&
                          locationImages.length > 0
                          ? locationImages[selectedImageIndex].image
                          : selectedImageType == "checkout" &&
                            locationCheckOutImages &&
                            locationCheckOutImages.length > 0
                            ? locationCheckOutImages[selectedImageIndex].image
                            : ""
                      }
                      className="modal-image"
                    />

                    <h1
                      onClick={() => setShowModal(false)}
                      className="modal-close"
                    >
                      <IonIcon icon={closeCircle} />
                    </h1>
                  </div>
                  <div className="modal-body">
                    <div className="modal-actions">
                      {/* <span onClick={() => {
                        setImageTitle(locationImages[selectedImageIndex].title);
                        setShowAlert(true);
                      }}>Edit title</span> */}

                      {/* <h3 className="img-caption">
                      {selectedImageType == "checkin" &&
                      locationImages &&
                      locationImages.length > 0
                        ? locationImages[selectedImageIndex].title
                        : selectedImageType == "checkout" &&
                          locationCheckOutImages &&
                          locationCheckOutImages.length > 0
                        ? locationCheckOutImages[selectedImageIndex].title
                        : ""}
                    </h3> */}

                      <h3 className="caption-img-heading">
                        {selectedImageType == "checkin" &&
                          locationImages &&
                          locationImages.length > 0
                          ? locationImages[selectedImageIndex].title
                          : selectedImageType == "checkout" &&
                            locationCheckOutImages &&
                            locationCheckOutImages.length > 0
                            ? locationCheckOutImages[selectedImageIndex].title
                            : ""}
                      </h3>

                      {/* {checkedIn && selectedImageType == "checkin" ? (
                        ""
                      ) : (
                        <span
                          onClick={() => handleRemoveImage(selectedImageIndex)}
                        >
                          Remove
                        </span>

                          <div className="Modal-btn">
                               <button  className='btn-content'  onClick={() => handleRemoveImage(selectedImageIndex)}>Remove</button>
                          </div>
                        
                      )} */}
                      {selectedImageType == "checkin" && !checkedIn ? (
                        <div className="Modal-btn">
                          <button
                            className="btn-content"
                            onClick={() =>
                              handleRemoveImage(selectedImageIndex)
                            }
                          >
                            Remove
                          </button>
                        </div>
                      ) : selectedImageType == "checkout" ? (
                        <div className="Modal-btn">
                          <button
                            className="btn-content"
                            onClick={() =>
                              handleRemoveImage(selectedImageIndex)
                            }
                          >
                            Remove
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <img
                      src={
                        selectedImageType == "checkin" &&
                        locationImages &&
                        locationImages.length > 0
                          ? locationImages[selectedImageIndex].image
                          : selectedImageType == "checkout" &&
                            locationCheckOutImages &&
                            locationCheckOutImages.length > 0
                          ? locationCheckOutImages[selectedImageIndex].image
                          : ""
                      }
                    /> */}
                    {/* <h3>
                      {selectedImageType == "checkin" &&
                      locationImages &&
                      locationImages.length > 0
                        ? locationImages[selectedImageIndex].title
                        : selectedImageType == "checkout" &&
                          locationCheckOutImages &&
                          locationCheckOutImages.length > 0
                        ? locationCheckOutImages[selectedImageIndex].title
                        : ""}
                    </h3> */}
                  </div>
                </div>
              </IonContent>
            </IonModal>
            {/* <div className="end-time">
            <h1 className="end">12:30</h1>
          </div> */}
            <IonCol class="management">
              {/* <div className="login">
                <button
                  onClick={() => {
                    handler();
                  }}
                >
                  Timmer
                </button>
                <button
                  onClick={() => {
                    handlerStop();
                  }}
                >
                  stop
                </button>
              </div> */}

              <h3 className="sub-title">
                {jobDetail ? jobDetail.job.location.name : ""}
              </h3>
              <h1 className="title">{jobDetail ? jobDetail.job.title : ""}</h1>
              {jobDetail ? (
                jobDetail.starts_at_formatted && jobDetail.ends_at_formatted ? (
                  <span className="time">
                    {jobDetail.starts_at_formatted} -{" "}
                    {jobDetail.ends_at_formatted}
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {/* <h6 className="progress-heading">14 mins remaining</h6>
            <IonProgressBar
              className="Progressbar"
              color="primary"
              value={0.2}
            ></IonProgressBar> */}
              <br />

              <div className="ion-address">
                <IonIcon className="gray-color" icon={pinOutline} />
                <p className="parmentant-address">
                  {jobDetail ? jobDetail.job.location.address : ""}
                </p>
              </div>
              <div className="ion-phone">
                <IonIcon className="gray-color" icon={callOutline} />
                <p className="parmentant-no">
                  {jobDetail && jobDetail.job.location.phone
                    ? jobDetail.job.location.phone
                    : "+(44) 770 018 2411"}
                </p>
              </div>

              <h2>Check-in attachments:</h2>
              <div className="flex-box">
                {locationImages.map((item: any, index: any) => {
                  return (
                    <div
                      onClick={() => handleImageClick(index, "checkin")}
                      className="box-content"
                      key={index}
                    >
                      {/* <h4 className="box-title">HM</h4> */}
                      <img src={item.image} className="box-title" />
                      <p className="box-discription">{item.title}</p>
                    </div>
                  );
                })}
                {!checkedIn ? (
                  <div
                    className="icon-box"
                    onClick={() => handleCamera("checkin")}
                  >
                    <IonIcon className="Plus-icon" icon={addCircleOutline} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {checkedIn ? (
                <>
                  <h2>Check-out attachments:</h2>
                  <div className="flex-box">
                    {locationCheckOutImages.map((item: any, index: any) => {
                      return (
                        <div
                          onClick={() => handleImageClick(index, "checkout")}
                          className="box-content"
                          key={index}
                        >
                          {/* <h4 className="box-title">HM</h4> */}
                          <img src={item.image} className="box-title" />
                          <p className="box-discription">{item.title}</p>
                        </div>
                      );
                    })}
                    {checkedIn ? (
                      <div
                        className="icon-box"
                        onClick={() => handleCamera("checkout")}
                      >
                        <IonIcon
                          className="Plus-icon"
                          icon={addCircleOutline}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {jobCompletedToday ? (
                ""
              ) : (
                <div className="Login">
                  <button onClick={() => handleCheckIn()} className="btn">
                    {checkinLoading ? (
                      <IonSpinner className="load-spinner" name="crescent" />
                    ) : checkedIn ? (
                      "Check Out"
                    ) : (
                      "Check In"
                    )}
                  </button>
                </div>
              )}
            </IonCol>
          </IonGrid>
        )}
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          backdropDismiss={false}
          cssClass="my-custom-class"
          header={"Picture caption"}
          inputs={[
            {
              name: "image_title",
              type: "text",
              // autoComplete: false,
              value: imageTitle ? imageTitle : "",
            },
          ]}
          buttons={[
            {
              text: "Save",
              handler: (alertData) => {
                handleImageUpdate(alertData);
              },
            },
          ]}
        />
        <IonAlert
          isOpen={showAlert2}
          onDidDismiss={() => setShowAlert2(false)}
          backdropDismiss={false}
          cssClass="my-custom-class"
          header={"Early checkout reason"}
          inputs={[
            {
              name: "reason",
              type: "text",
              // autoComplete: false,
              value: earlyCheckoutReason ? earlyCheckoutReason : "",
            },
          ]}
          buttons={[
            {
              text: "Submit",
              handler: (alertData) => {
                updateReason(alertData);
              },
            },
          ]}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={900}
          cssClass="tlh-service-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default JobDetail;
