import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { IonAvatar, IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToolbar, useIonRouter, useIonViewWillEnter } from "@ionic/react";
import axios from "axios";
import { closeCircleSharp, text, addCircle, accessibility, chevronDown, calendar, newspaper, trashOutline, addCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";

const EmployeeDetail: React.FC = () => {
    let history = useHistory();
    const router = useIonRouter();
    let logged_user_data: any = localStorage.getItem("tlh_service_user");
    const cookies = new Cookies();
    const location: any = useLocation<{ data: any }>();
    // const employeeList: any[] = location?.state?.data?.job_employees || [];
    const employeeList: any = location?.state?.data;
    const types: any = location?.state || '';
    const jobData: any = location?.state?.data || '';

    const [loading, setLoading] = useState<any>(false);
    const [showToast, setShowToast] = useState(false);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userData, setUserData] = useState<any>(JSON.parse(logged_user_data));
    const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number>(-1);
    const [dateType, setDateType] = useState<'starts_at' | 'ends_at' | ''>('');
    const [message, setMessage] = useState('');
    const [employeesList, setEmployeesList] = useState([]);
    const [employees, setEmployees] = useState<any>([
        {
            user_id: '',
            title: '',
            description: '',
            starts_at: '',
            ends_at: '',
            attachments_by_admin: [],
            attachments_by_staff: [],
        },
    ]);
    const addEmployeeSection = () => {
        setEmployees([
            ...employees,
            {
                user_id: '',
                title: '',
                description: '',
                starts_at: '',
                ends_at: '',
                attachments_by_admin: [],
                attachments_by_staff: [],
            },
        ]);
    };

    useEffect(() => {
        // if (employeeList && employeeList.length > 0) {
        if (employeeList) {
            setEmployees([employeeList]);
            // setEmployees({
            //     user_id: employeeList?.id || "",
            //     user_name: employeeList?.full_name || "",
            //     title: employeeList?.title || "",
            //     description: employeeList?.description || "",
            //     starts_at: employeeList.starts_at_formatted || "",
            //     ends_at: employeeList.ends_at_formatted || "",
            //     attachments_by_admin: employeeList.attachments_by_admin,
            //     attachments_by_staff: employeeList.attachments_by_staff,
            // });
        }
    }, [employeeList]);
    const token: any = localStorage.getItem("access_token_tlh_service")
    useEffect(() => {
        let isMounted = true; // To track if the component is still mounted
        // Fetch employee list
        const getEmployeeList = async () => {
            setLoading(true);
            const url = "https://quote.tlhbuildings.com/api/users/staff";
            try {
                const res = await axios.post(
                    url, {},
                    {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(token)}`,
                        },
                    }
                );

                if (res.data.data && isMounted) {
                    setEmployeesList(res.data.data);
                }
            } catch (error) {
                console.error('Error fetching employee list:', error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        const fetchData = () => {
            if (isMounted) {
                getEmployeeList();
            }
        };

        fetchData();

        // Cleanup function to set `isMounted` to false
        return () => {
            isMounted = false;
        };
    }, []);

    // Remove Employee
    const removeEmployee = (index: number) => {
        setEmployees(employees.filter((_: any, i: any) => i !== index));
    };

    // Handle Input Changes
    const handleEmployeeChange = (index: number, field: string, value: any) => {
        const updatedEmployees: any = [...employees];
        updatedEmployees[index][field] = value;
        setEmployees(updatedEmployees);
    };

    // Capture or Select Image
    const handleImageCapture = async (index: number, attachmentType: string) => {
        try {
            const image = await Camera.getPhoto({
                resultType: CameraResultType.Base64,
                source: CameraSource.Prompt, // Allows user to choose between Camera and Gallery
                quality: 90,
            });

            const base64String = 'data:image/png;base64,' + (image.base64String || '');

            // Update the state based on the attachment type (either 'staff' or 'admin')
            setEmployees((prevEmployees: any) => {
                const updatedEmployees = [...prevEmployees];
                if (attachmentType === 'staff') {
                    updatedEmployees[index].attachments_by_staff.push({ base64: base64String });
                } else if (attachmentType === 'admin') {
                    updatedEmployees[index].attachments_by_admin.push({ base64: base64String });
                }
                return updatedEmployees;
            });
        } catch (error) {
            console.error('Image capture failed:', error);
        }
    };

    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';

        const formattedHours = String(hours % 12 || 12).padStart(2, '0'); // Convert 24-hour to 12-hour format

        return `${day}/${month}/${year} ${formattedHours}:${minutes} ${period}`;
    };

    const handleTimeChange = (event: any, index: number, dateType: 'starts_at' | 'ends_at') => {
        const selectedDate = new Date(event.detail.value);
        console.log(dateType)
        // Format the date using the formatDate function
        const formattedDate = formatDate(selectedDate);

        setEmployees((prevEmployees: any) => {
            const updatedEmployees = [...prevEmployees];
            const currentEmployee = updatedEmployees[index];

            if (dateType === 'starts_at') {
                // Update starts_at and ensure ends_at is not earlier
                updatedEmployees[index] = {
                    ...currentEmployee,
                    starts_at: formattedDate,
                    ends_at: currentEmployee.ends_at && formattedDate > currentEmployee.ends_at ? '' : currentEmployee.ends_at,
                };
            } else if (dateType === 'ends_at') {
                // Update ends_at only if it's not before starts_at
                if (!currentEmployee.starts_at || formattedDate >= currentEmployee.starts_at) {
                    updatedEmployees[index] = { ...currentEmployee, ends_at: formattedDate };
                } else {
                    setShowToast(true);
                    setMessage('End time cannot be before the start time.');
                }
            }

            return updatedEmployees;
        });
    };
    const handleRemoveImage = (employeeIndex: number, attachmentIndex: number, attachmentType: string) => {
        // Create a copy of the employees array
        const updatedEmployees = [...employees];

        // Get the specific employee
        const employee = updatedEmployees[employeeIndex];

        // Check if the specified attachment type exists and is an array
        if (attachmentType === 'staff' && Array.isArray(employee.attachments_by_staff)) {
            // Remove the attachment at the specified index for staff
            employee.attachments_by_staff = [
                ...employee.attachments_by_staff.slice(0, attachmentIndex),
                ...employee.attachments_by_staff.slice(attachmentIndex + 1),
            ];
        } else if (attachmentType === 'admin' && Array.isArray(employee.attachments_by_admin)) {
            // Remove the attachment at the specified index for admin
            employee.attachments_by_admin = [
                ...employee.attachments_by_admin.slice(0, attachmentIndex),
                ...employee.attachments_by_admin.slice(attachmentIndex + 1),
            ];
        }

        // Update the state with the modified employees array
        setEmployees(updatedEmployees);
    };

    const getCurrentDateTime = (): string => {
        const now = new Date();
        return now.toISOString(); // ISO format required for IonDatetime min/max
    };

    const uploadAttachments = async (id: any, attachments: any, type: any, index: any) => {
        setLoading(true);

        try {

            // Filter valid IDs from attachments
            const attachmentIds = attachments
                .map((attachment: any) => attachment.id)
                .filter((id: any) => id !== undefined && id !== null);

            // Construct the URL based on the type
            const url =
                type === 'admin'
                    ? `https://quote.tlhbuildings.com/api/app/admin/job-employees/${id}/upload-attachments`
                    : `https://quote.tlhbuildings.com/api/app/staff/sub-jobs/${id}/upload-attachments`;


            // Make the POST request
            const res: any = await axios.post(
                url,
                {
                    attachment_ids: attachmentIds, // Attach valid attachment IDs
                    attachments: attachments, // Use valid base64 data
                },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(token)}`,
                    },
                }
            );


            // Handle the response status
            if (res.data.status) {
                setLoading(false);
                setMessage(res.data.message);
                setShowToast(true);
                // history.push('/admin-job-tracking');
            } else {
                setShowToast(true);
                setMessage(res.data?.message);
            }
        } catch (error: any) {
            setLoading(false);

            // Log detailed error for debugging
            console.error('Error uploading attachments:', error);

            // Provide feedback to the user
            setShowToast(true);
            setMessage(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <IonPage>
            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={"Please wait..."}
            />
            <IonHeader>
                <IonToolbar style={{ paddingBottom: '5px' }} className="quote_bg_primary ptb">
                    <div className="quote_header_text">
                        <IonBackButton mode="md" defaultHref="/" />
                        <h4>Employee Detail</h4>
                        <div className="extra_class"></div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="ion-padding ion-d-flex">
                    <IonAvatar>
                        <IonImg
                            src={
                                jobData.job?.avatar ?
                                    jobData.job?.avatar
                                    : require("../images/location.jpg")
                            }
                        />
                    </IonAvatar>
                    <div className="ion-padding-start">
                        <h2 className="ion-no-margin">{jobData?.title}</h2>
                        <p className="ion-no-margin">{jobData?.location?.address}</p>
                        <IonBadge className="ion-text-capitalize" style={{ marginTop: "5px" }}>{jobData?.status}</IonBadge>
                    </div>
                </div>
                <IonList lines="none" class="ion-padding-horizontal ion-h-100 ion-overflow-y">
                    {employees.map((employee: any, index: any) => {
                        return (
                            <div key={index} className={types.type === 'view' ? 'ion-margin-top ion-padding-top' : ''}>
                                {/* {types.type !== 'view' ?
                                    <IonItem>
                                        <IonIcon slot="end" onClick={() => removeEmployee(index)} size="large" color='danger' icon={closeCircleSharp} />
                                    </IonItem>
                                    : ""} */}
                                <IonItem lines="none" className="box_shadow br_20 quote_ion_input">
                                    <IonIcon icon={text} className="ion-margin-end" />
                                    <IonInput
                                        readonly={types.type !== 'view' ? false : true}
                                        placeholder="Title"
                                        value={employee.title}
                                        onIonChange={(e) => handleEmployeeChange(index, 'title', e.detail.value)}
                                    />
                                </IonItem>
                                <div className="mt_25 ">
                                    {/* {types.type !== 'view' ?
                                        <IonItem>
                                            <IonIcon icon={addCircle} size="large" color="primary" slot="end" onClick={() => setIsModalOpen(true)} />
                                        </IonItem>
                                        : ""} */}
                                    <IonItem lines="none" className="box_shadow br_20 quote_ion_input">
                                        <IonIcon icon={accessibility} className="ion-margin-end" />
                                        <IonSelect
                                            style={{
                                                '--placeholder-color': 'black',
                                                '--placeholder-opacity': '1'
                                            }}
                                            mode="ios"
                                            placeholder={employee?.employee?.full_name ? employee?.employee?.full_name : "Select Employee"}
                                            interface="action-sheet"
                                            value={employee.employee_id}
                                            onIonChange={(e) => handleEmployeeChange(index, 'user_id', e.detail.value)}
                                            disabled={types.type === 'staff' || true}
                                        >
                                            {employeesList.map((listEmployee: any) => (
                                                <IonSelectOption key={listEmployee.id} value={listEmployee.id}>
                                                    {listEmployee.full_name}
                                                </IonSelectOption>
                                            ))}
                                        </IonSelect>
                                        {types.type !== 'staff' ? <IonIcon icon={chevronDown} slot="end" /> : ''}
                                    </IonItem>
                                </div>
                                {/* <div className="ion-d-flex ion-align-items-center">
                                    <IonItem style={{ width: '50%', marginRight: '15px' }} lines="none" className="box_shadow br_20 mt_25 quote_ion_input" onClick={() => {
                                        if (types.type !== 'view') {
                                            setCurrentEmployeeIndex(index);
                                            setDateType('starts_at');
                                            setIsDateModalOpen(true);
                                        }
                                    }}>
                                        <IonIcon icon={calendar} className="ion-margin-end" />
                                        <IonLabel>{employee.starts_at || 'Start At'}</IonLabel>
                                    </IonItem>
                                    <IonItem style={{ width: '50%' }} lines="none" className="box_shadow br_20 mt_25 quote_ion_input" onClick={() => {
                                        if (types.type !== 'view') {
                                            setCurrentEmployeeIndex(index);
                                            setDateType('ends_at');
                                            setIsDateModalOpen(true);
                                        }
                                    }}>
                                        <IonIcon icon={calendar} className="ion-margin-end" />
                                        <IonLabel>{employee.ends_at || 'Ends At'}</IonLabel>
                                    </IonItem>
                                </div> */}
                                <IonItem lines="none" style={{ padding: '5px', marginRight: '15px' }} className='ion-position-relative ion-w-100 box_shadow br_20 mt_25 quote_ion_input'>
                                    <IonIcon icon={newspaper} className="ion-margin-top ion-margin-end" />
                                    <IonTextarea
                                        readonly={types.type !== 'view' ? false : true}
                                        rows={6}
                                        className="review-area"
                                        placeholder="Description"
                                        value={employee.description}
                                        onIonChange={(e) => handleEmployeeChange(index, 'description', e.detail.value)}
                                    />
                                </IonItem>
                                <div className={employee.attachments_by_staff.length > 0 ? "ion-margin-top" : "ion-d-flex ion-flex-column ion-margin-top"}>
                                    {employee.attachments_by_staff.length > 0 || userData.role?.id !== 1 ? <IonLabel className="ion-label-text">Attachments by Staff:</IonLabel> : ""}
                                    <div className="ion-d-flex ion-wrap ion-align-items-center ion-margin-vertical" style={{ position: 'relative' }}>
                                        {employee.attachments_by_staff.length > 0 ? (
                                            employee.attachments_by_staff.map((attachment: any, attachmentIndex: any) => (
                                                <div
                                                    key={attachmentIndex}
                                                    style={{ position: 'relative', width: '85px' }}
                                                    className="icon-box"
                                                >
                                                    <IonImg
                                                        src={attachment.base64 ? attachment.base64 : attachment.attachment_url}
                                                        style={{ width: '100%', height: '100%', margin: '5px' }}
                                                    />
                                                    {/* Delete Icon */}
                                                    {types.type !== 'view' && userData.role?.id !== 1 ? (
                                                        <div
                                                            className="delete-icon"
                                                            onClick={() => handleRemoveImage(index, attachmentIndex, 'staff')}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-12px',
                                                                right: '-5px',
                                                                cursor: 'pointer',
                                                                backgroundColor: 'white',
                                                                borderRadius: '50%',
                                                                padding: '5px',
                                                                width: '30px',
                                                                height: '30px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <IonIcon icon={trashOutline} />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            ""
                                        )}

                                        {/* Add Attachment Icon */}
                                        {types.type !== 'view' && userData.role?.id !== 1 ? (
                                            <div
                                                style={{ width: '85px' }}
                                                className="icon-box"
                                                onClick={() => handleImageCapture(index, 'staff')}
                                            >
                                                <IonIcon className="Plus-icon" icon={addCircleOutline} />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    {types.type === 'staff' && userData.role?.id !== 1 ?
                                        <div className="ion-d-flex ion-justify-content-end ion-align-items-center">
                                            <IonButton slot="end" mode="ios" onClick={() => uploadAttachments(employeeList.id, employeeList.attachments_by_staff, 'staff', index)}>
                                                <IonLabel>Upload Attachments</IonLabel>
                                            </IonButton>
                                        </div>
                                        : ""}
                                </div>
                                <div className="ion-margin-top">
                                    {employee.attachments_by_admin.length > 0 || userData.role?.id === 1 ? <IonLabel className="ion-label-text">Attachments by Admin:</IonLabel> : ""}
                                    <div className="ion-d-flex ion-wrap ion-align-items-center ion-margin-vertical" style={{ position: 'relative' }}>
                                        {employee.attachments_by_admin.length > 0 ? (
                                            employee.attachments_by_admin.map((attachment: any, attachmentIndex: any) => (
                                                <div
                                                    key={attachmentIndex}
                                                    style={{ position: 'relative', width: '85px' }}
                                                    className="icon-box"
                                                >
                                                    <IonImg
                                                        src={attachment.base64 ? attachment.base64 : attachment.attachment_url}
                                                        style={{ width: '100%', height: '100%', margin: '5px' }}
                                                    />
                                                    {/* Delete Icon */}
                                                    {types.type !== 'view' && userData.role?.id === 1 ? (
                                                        <div
                                                            className="delete-icon"
                                                            onClick={() => handleRemoveImage(index, attachmentIndex, 'admin')}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-12px',
                                                                right: '-5px',
                                                                cursor: 'pointer',
                                                                backgroundColor: 'white',
                                                                borderRadius: '50%',
                                                                padding: '5px',
                                                                width: '30px',
                                                                height: '30px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <IonIcon icon={trashOutline} />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            ""
                                            // <div style={{ width: '100%' }} className="ion-d-flex ion-justify-content-center ion-align-items-center">
                                            //     <IonLabel className="ion-text-center">No attachments found! </IonLabel>
                                            // </div>
                                        )}

                                        {/* Add Attachment Icon */}
                                        {types.type !== 'view' && userData.role?.id === 1 ? (
                                            <div
                                                style={{ width: '85px' }}
                                                className="icon-box"
                                                onClick={() => handleImageCapture(index, 'admin')}
                                            >
                                                <IonIcon className="Plus-icon" icon={addCircleOutline} />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    {types.type !== 'view' && userData.role?.id === 1 ?
                                        <div className="ion-d-flex ion-justify-content-end ion-align-items-center">
                                            <IonButton slot="end" mode="ios" onClick={() => uploadAttachments(employeeList.id, employeeList.attachments_by_admin, 'admin', index)}>
                                                <IonLabel>Upload Attachments</IonLabel>
                                            </IonButton>
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        )
                    })}
                    {types.type !== 'view' ?
                        <div className="ion-d-flex ion-justify-content-end ion-align-items-center">
                            {/* <IonButton slot="end" mode="ios" onClick={addEmployeeSection}>
                                <IonLabel>Update</IonLabel>
                            </IonButton> */}
                            {/* <IonButton slot="end" mode="ios" onClick={addEmployeeSection}>
                                <IonIcon icon={addCircle} />
                                <IonLabel> Add more</IonLabel>
                            </IonButton> */}
                        </div> : ""}
                    <IonModal isOpen={isDateModalOpen} onDidDismiss={() => setIsDateModalOpen(false)}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Select Date and Time</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => setIsDateModalOpen(false)}>Close</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonDatetime
                                className="ion-max-w-100"
                                presentation="date-time"
                                min={getCurrentDateTime()}
                                onIonChange={(e) =>
                                    handleTimeChange(e, currentEmployeeIndex, dateType as 'starts_at' | 'ends_at')
                                }
                                locale="en-GB-u-hc-h12"
                            />
                        </IonContent>
                    </IonModal>
                </IonList>
            </IonContent>
            <IonToast
                mode="ios"
                color={"primary"}
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={message}
                duration={2000}
                position="middle"
            />
        </IonPage >
    )
}
export default EmployeeDetail;