import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  useIonViewWillEnter,
  IonIcon,
  IonButton,
  IonLabel,
} from "@ionic/react";
import "./Dashboard.scss";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  add,
  checkmark,
  checkmarkDone,
  cloudUpload,
  hourglass,
  list,
  notificationsOutline,
  closeCircleOutline
} from "ionicons/icons";
import {
  LocalNotifications,
  LocalNotificationSchema,
} from "@capacitor/local-notifications";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [71, 20, 36, 48, 75, 36, 97],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      fill: false,
      pointBorderColor: "rgba(27, 76, 197, 0.8)",
      pointBackgroundColor: "rgba(27, 76, 197, 0.8)",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "gray",
      pointHoverBorderColor: "gray",
      pointHoverBorderWidth: 4,
      pointRadius: 4,
      pointHitRadius: 10,
      stack: "line",
      borderDashOffset: 5,
      showline: true,
    },
    {
      label: "Dataset 1",
      data: [1, 37, 76, 88, 25, 96, 17],
      borderColor: "red",
      backgroundColor: "red",
      fill: false,
      pointBorderColor: "gray",
      pointBackgroundColor: "gray",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "rgba(27, 76, 197, 0.8)",
      pointHoverBorderColor: "rgba(27, 76, 197, 0.8)",
      pointHoverBorderWidth: 4,
      pointRadius: 4,
      pointHitRadius: 10,
      stack: "line",
      borderDashOffset: 5,
      showline: true,
    },
  ],
};
const cookies = new Cookies();

const Dashboard: React.FC = () => {
  let history = useHistory();
  let logged_user_data: any = localStorage.getItem("tlh_service_user");

  const [loading, setLoading] = useState<any>(false);
  const [userJobs, setUserJobs] = useState([]);
  const [userData, setUserData] = useState<any>(JSON.parse(logged_user_data));
  const [newNotification, setNewNotification] = useState(false);
  const [managerJobs, setManagerJobs] = useState<any>([]);

  const buttonItems = [
    { label: "Create Job", icon: add, url: "/createjob", status: "active" },
    { label: "All Job", icon: list, url: "/admin-job-list", status: "joblist" },
    { label: "Pending Job", icon: list, url: "/job-list", status: "pending" },
    {
      label: "Awaiting Jobs",
      icon: hourglass,
      url: "/job-list",
      status: "awaiting_approval_of_admin",
    },
    {
      label: "Active Jobs",
      icon: checkmark,
      url: "/job-list",
      status: "active",
    },
    {
      label: "Jobs Submitted",
      icon: cloudUpload,
      url: "/job-list",
      status: "submitted",
    },
    {
      label: "Completed Jobs",
      icon: checkmarkDone,
      url: "/job-list",
      status: "completed",
    },
  ];

  const staffJobItems = [
    { label: "New Jobs", icon: add, url: "/job-list", status: "pending" },
    {
      label: "Active Jobs",
      icon: checkmark,
      url: "/job-list",
      status: "active-employee",
    },
    {
      label: "Completed Jobs",
      icon: checkmarkDone,
      url: "/job-list",
      status: "completed-employee",
    },
    {
      label: "Awaiting Jobs",
      icon: hourglass,
      url: "/job-list",
      status: "awaiting_approval_of_staff",
    },
    {
      label: "Rejected By Admin",
      icon: closeCircleOutline,
      url: "/job-list",
      status: "rejected_by_admin",
    },
  ];

  const buttonItemsToShow =
    userData.role?.id === 1
      ? buttonItems
      : [staffJobItems].find(() => [7, 3].includes(userData.role?.id)) || [];

  useIonViewWillEnter(() => {
    if (!localStorage.getItem("tlh_service_user")) {
      history.push("/page/accountcreated");
    } else {
      if (Capacitor.platform !== "web") {
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
            setNewNotification(true);
          }
        );
        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification: ActionPerformed) => {
            history.push("/page/notifications");
          }
        );
      }

      // setUserJobs([]);
      // setManagerJobs([]);

      // setLoading(true);
      // // setUserData(localStorage.getItem("tlh_service_user"));

      // let url;
      // let user_role_id: any = JSON.parse(logged_user_data);
      // user_role_id = user_role_id.role.id;

      // if (user_role_id == "6") {
      //   url = BASE_URL + "manager/jobs/active";
      //   //url = BASE_URL + "manager/103/job-employees-trackings-today";
      //   axios
      //     .get(url, {
      //       headers: {
      //         Authorization: `Bearer ${cookies.get(
      //           "access_token_tlh_service"
      //         )}`,
      //       },
      //     })
      //     .then(async (res) => {
      //       if (res.data.status) {
      //         setUserJobs(res.data.data);
      //         setLoading(false);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } else if (user_role_id == "3" || user_role_id == "7") {
      //   url = BASE_URL + "jobs/active";
      //   axios
      //     .get(url, {
      //       headers: {
      //         Authorization: `Bearer ${cookies.get(
      //           "access_token_tlh_service"
      //         )}`,
      //       },
      //     })
      //     .then(async (res) => {
      //       if (res.data.status) {
      //         setLoading(false);
      //         setUserJobs(res.data.data);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } else if (user_role_id == "4") {
      //   url = BASE_URL + "customer/jobs";
      //   // url = BASE_URL + "customer/quotation-requests";
      //   axios
      //     .post(url, {
      //     }, {
      //       headers: {
      //         Authorization: `Bearer ${cookies.get(
      //           "access_token_tlh_service"
      //         )}`,
      //       },
      //     }
      //     )
      //     .then(async (res) => {
      //       if (res.data.status) {
      //         setLoading(false);
      //         setUserJobs(res.data.data);
      //       } else {
      //         setLoading(false);
      //         setUserJobs([]);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } else if (user_role_id === 1) {
      //   getAdminJobs(user_role_id);
      // }
    }
  });

  const getAdminJobs = (user_role_id: any) => {
    // let url = BASE_URL + `admin/jobs/${user_role_id}/show`;
    let url = BASE_URL + `admin/jobs`;
    let isMounted = true; // Initialize the isMounted flag

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access_token_tlh_service") || "null"
          )}`,
        },
      })
      .then((res) => {
        if (isMounted) {
          // Only update state if component is still mounted
          setLoading(false);
          if (res.data.status) {
            setUserJobs(res.data.data);
          }
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error(error);
        }
      });

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  };

  const handleJobClick = (job: Object, type: any) => {
    console.log(job);
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    localStorage.setItem("job-detail", JSON.stringify(job));
    if (type == "staff" || type == "professional") {
      history.push("/page/job-detail", { job_detail: job });
    } else {
      history.push("/page/job-trackings", { job_detail: job });
    }
  };

  const handleBellIconClick = () => {
    setNewNotification(false);
    history.push("/page/notifications");
  };
  const navigateTo = (url: string, status: any) => {
    history.push(url, { status });
  };

  return (
    <IonPage>
      <IonHeader className="header-wrapper">
        <div className="header-wrapper-inner">
          <IonRow className="row">
            <IonCol className="column" size="6">
              <IonButtons className="padding-b-30">
                <IonMenuButton>
                  <IonImg
                    src={require("../images/menu.png")}
                    className="manuPicture"
                  />
                </IonMenuButton>
              </IonButtons>

              <div
                className={
                  newNotification
                    ? "notification-icon new-notification-badge"
                    : "notification-icon"
                }
                onClick={() => handleBellIconClick()}
              >
                <IonIcon icon={notificationsOutline} size="large" />
              </div>

              <div className="employee-description">
                <span>
                  Hi {userData ? userData.first_name : "Jane"}{" "}
                  {userData ? userData.last_name : "Mile"}
                </span>
                {/* <span className="job-number">
                  You have{" "}
                  {userJobs.length > 0 ? userJobs.length : managerJobs.length}{" "}
                  Job{userJobs.length > 1 ? "s" : ""}{" "}
                  {userData && userData?.role?.id == "6" ? "to review" : ""}
                </span> */}
              </div>
            </IonCol>
            <IonCol className="column" size="6">
              <IonImg
                src={require("../images/climbing.png")}
                className="header-image"
              />
            </IonCol>
          </IonRow>
        </div>
      </IonHeader>
      <IonContent className="wrapper">
        {loading ? (
          <div className="loader-container">
            <IonSpinner className="dash-spinner" name="crescent" />
          </div>
        ) : (
          <IonGrid>
            <IonRow className="row">
              {buttonItemsToShow.map((item, index) => (
                <IonCol key={index} size="6" sizeMd="4">
                  <IonButton
                    onClick={() => navigateTo(item.url, item.status)}
                    mode="ios"
                    expand="block"
                    color="primary"
                  >
                    <IonIcon size="large" icon={item.icon} slot="start" />{" "}
                    {/* Icon on the left */}
                    <IonLabel> {item.label}</IonLabel>
                  </IonButton>
                </IonCol>
              ))}
              {/* {userJobs.length > 0 ?
                userJobs.map((item: any, index: any) => {
                  return (
                    <IonCol
                      key={index}
                      onClick={() => handleJobClick(item, userData.role.key)}
                      className="column"
                      size="12"
                    >
                      <IonImg
                        src={
                          item.job ?
                            item.job?.location?.location_pic_url
                            :
                            item.location ? item?.location?.location_pic_url :
                              require("../images/location.jpg")
                        }
                      />
                      <div className="job-description">
                        {item.starts_at_formatted && item.ends_at_formatted ? (
                          <span className="card-timing">
                            {item.starts_at_formatted} - {item.ends_at_formatted}
                          </span>
                        ) : (
                          ""
                        )}
                        <span className="font-weight-700 address">
                          {item.job ? item.job.title : item.title ? item.title : ''}
                        </span>
                        <span className="area">
                          {item.job ? item?.job?.location?.name : item?.location ? item?.location?.name : ''}
                        </span>
                      </div>
                    </IonCol>
                  );
                }) 
              :
              <h3>No Jobs Found</h3>
              } */}

              {/* { userJobs.map((item: any, index: any) => {
                return (
                  <IonCol
                    key={index}
                    onClick={() => handleJobClick(item, "staff")}
                    className="column"
                    size="12"
                  >
                    <IonImg
                      src={
                        item.job.location.location_pic_url
                          ? ASSET_URL + "/" + item.job.location.location_pic_url
                          : require("../images/location.jpg")
                      }
                    />
                    <div className="job-description">
                      {item.starts_at_formatted && item.ends_at_formatted ? (
                        <span className="card-timing">
                          {item.starts_at_formatted} - {item.ends_at_formatted}
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="font-weight-700 address">
                        {item.job.title ? item.job.title : ""}
                      </span>
                      <span className="area">
                        {item.job.location.name ? item.job.location.name : ""}
                      </span>
                    </div>
                  </IonCol>
                );
              })} */}

              {/* {managerJobs.length > 0 ? 
                managerJobs.map((item: any, index: any) => {
                    if(item.job){
                        return (
                            <IonCol
                            key={index}
                            onClick={() => handleJobClick(item, "manager")}
                            className="column"
                            size="12"
                            >
                            <IonImg
                                src={
                                item.job.location.location_pic_url
                                    ? ASSET_URL +
                                    "/" +
                                    item.job.location.location_pic_url
                                    : require("../images/location.jpg")
                                }
                            />
                            <div className="job-description">
                                {item.starts_at_formatted && item.ends_at_formatted ? (
                                <span className="card-timing">
                                    {item.starts_at_formatted} -{" "}
                                    {item.ends_at_formatted}
                                </span>
                                ) : (
                                ""
                                )}
                                <span className="font-weight-700 address">
                                {item.job.title ? item.job.title : ""}
                                </span>
                                <span className="area">
                                {item.job.location.name ? item.job.location.name : ""}
                                </span>
                            </div>
                            </IonCol>
                        );
                    }
                })
                :
                  ''
                } */}

              {/* {userJobs.length === 0 && managerJobs.length === 0? 
                  <h3>No Jobs Found</h3>
                  :
                  ''
                } */}

              {/* {managerJobs.map((item: any, index: any) => {
                    if (item.job) {
                    return (
                        <IonCol
                        key={index}
                        onClick={() => handleJobClick(item, "manager")}
                        className="column"
                        size="12"
                        >
                        <IonImg
                            src={
                            item.job.location.location_pic_url
                                ? ASSET_URL +
                                "/" +
                                item.job.location.location_pic_url
                                : require("../images/location.jpg")
                            }
                        />
                        <div className="job-description">
                            {item.starts_at_formatted && item.ends_at_formatted ? (
                            <span className="card-timing">
                                {item.starts_at_formatted} -{" "}
                                {item.ends_at_formatted}
                            </span>
                            ) : (
                            ""
                            )}
                            <span className="font-weight-700 address">
                            {item.job.title ? item.job.title : ""}
                            </span>
                            <span className="area">
                            {item.job.location.name ? item.job.location.name : ""}
                            </span>
                        </div>
                        </IonCol>
                    );
                    }
              })} */}

              {/* <Line options={options} data={data} /> */}
            </IonRow>
          </IonGrid>
        )}
        {/* <RatingStar/> */}
      </IonContent>
    </IonPage>
  );
};
export default Dashboard;
