import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonBackButton,
  IonImg,
  IonAvatar,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonAlert,
  IonButton,
  IonTextarea,
  useIonViewWillEnter,
  IonLoading,
  IonToast,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { camera, close } from "ionicons/icons";
import { Camera, CameraResultType, CameraSource, CameraDirection } from '@capacitor/camera';
import { chevronDown, reader, business, closeCircle, addCircleOutline, earthOutline, trailSignOutline, businessOutline, personOutline, cashOutline, locationOutline, mailOutline, callOutline, constructOutline, lockClosedOutline, pin } from "ionicons/icons";
import React, { useState } from "react";
import axios from "axios";
import { BASE_URL, API_URL } from "../BaseUrl";
import { postCall } from "../ApiCalls";
import { validate } from "../Validation";
import Cookies from "universal-cookie";
import "./Quote.scss";
import "./JobDetail.scss";
import {
  BrowserRouter as Router,
  useHistory,
  Redirect,
} from "react-router-dom";
const cookies = new Cookies();

const Register: React.FC = () => {
  let history = useHistory();

  const [showLoading, setShowLoading] = useState(false);
  const [registerData, setRegisterData] = useState<any>({});
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<any>('');
  const [userType, setUserType] = useState('customer');
  // const [segmentActive, setSegmentActive] = useState<boolean>(true);

  // const segmentButton = (type: string) => {
  //   if (type === "customer") {
  //     setSegmentActive(true);
  //   }
  //   else if (type === "professionals") {
  //     setSegmentActive(false);
  //   }
  // }

  const registerClick = () => {
    if (validate(registerData.first_name, 'name')) {
      setToastMessage('Invalid first name');
      setShowToast(true);
      return;
    } else if (validate(registerData.last_name, 'name')) {
      setToastMessage('Invalid last name');
      setShowToast(true);
      return;
    } else if (validate(registerData.email, 'email')) {
      setToastMessage('Invalid email');
      setShowToast(true);
      return;
    } else if (validate(registerData.phone_no, 'phone')) {
      setToastMessage('Invalid phone number');
      setShowToast(true);
      return;
    } else if (validate(registerData.password, 'password')) {
      setToastMessage('Password should be minimum 6 characters long');
      setShowToast(true);
      return;
    }

    setShowLoading(true);
    registerData.role_key = userType;

    var url = BASE_URL + "customer/register";
    //let res = await postCall(url, registerData);
    let result = postCall(url, registerData);
    result.then((data: any) => {
      setShowLoading(false);
      if (data.status) {
        setRegisterData({
          first_name: "",
          last_name: "",
          email: "",
          phone_no: "",
          password: "",
          role_key: userType
        });
        history.replace('/page/login');
      } else {
        setToastMessage(data.message);
        setShowToast(true);
      }
    }).catch((err: any) => {
      console.log(err);
    })
  }

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar className="quote_bg_primary ptb">
            <div className="quote_header_text">
              <IonBackButton mode="md" defaultHref="/" />
              <h4>Register</h4>
              <div className="extra_class"></div>
            </div>
          </IonToolbar>
        </IonHeader>
        <div className="quote_wrapper">
          <div className="form">
            <IonItem lines="none" className='box_shadow br_20 mt_25 quote_ion_input'>
              <IonIcon icon={personOutline} />
              <IonInput placeholder="First name" type="text"
                onIonChange={(e) => {
                  let customerData = registerData;
                  customerData.first_name = e.detail.value;
                  setRegisterData(customerData);
                }}></IonInput>
            </IonItem>
            <IonItem lines="none" className='box_shadow br_20 mt_25 quote_ion_input'>
              <IonIcon icon={personOutline} />
              <IonInput placeholder="Last name" type="text"
                onIonChange={(e) => {
                  let customerData = registerData;
                  customerData.last_name = e.detail.value;
                  setRegisterData(customerData);
                }}></IonInput>
            </IonItem>
            <IonItem lines="none" className='box_shadow br_20 mt_25 quote_ion_input'>
              <IonIcon icon={mailOutline} />
              <IonInput placeholder="Email" type="email"
                onIonChange={(e) => {
                  let customerData = registerData;
                  customerData.email = e.detail.value;
                  setRegisterData(customerData);
                }}></IonInput>
            </IonItem>
            <IonItem lines="none" className='box_shadow br_20 mt_25 quote_ion_input'>
              <IonIcon icon={callOutline} />
              <IonInput placeholder="Phone +44 7911 123456" type="tel"
                onIonChange={(e) => {
                  let customerData = registerData;
                  customerData.phone_no = e.detail.value;
                  setRegisterData(customerData);
                }}></IonInput>
            </IonItem>
            <IonItem lines="none" className='box_shadow br_20 mt_25 quote_ion_input'>
              <IonIcon icon={lockClosedOutline} />
              <IonInput placeholder="Password" type="password"
                onIonChange={(e) => {
                  let customerData = registerData;
                  customerData.password = e.detail.value;
                  setRegisterData(customerData);
                }}></IonInput>
            </IonItem>

            <IonSegment value="customer" mode="ios" className="mt_25">
              <IonSegmentButton
                onClick={() => {
                  setUserType('customer');
                }}
                value="customer">
                <IonLabel
                // className={segmentActive ? "active_segment" : ""}
                >Customer</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                onClick={() => {
                  setUserType('professional');
                }}
                value="professional">
                <IonLabel
                // className={!segmentActive ? "active_segment" : ""}
                >Professional</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {/*<div className="card-wrapper">*/}
            {/* <IonCard className="registerPage-card">*/}
            {/*   <IonImg src={require("../images/istock.jpg")} />*/}
            {/* </IonCard>*/}
            {/* <IonIcon icon={close} className="close-icon"></IonIcon>*/}
            {/*</div>*/}
            {/* <IonIcon icon={camera} size="large" className="camera-icon"></IonIcon>*/}

            <IonButton onClick={() => {
              registerClick();
            }} className="fs_18 h_45 w_100 btn_br_20 text_cap mt_25">register</IonButton>
          </div>
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={600}
        />
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please Wait"}
        />
      </IonContent>
    </IonPage>
  );
};

export default Register;
