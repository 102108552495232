import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonButtons,
  IonBackButton,
  IonSpinner,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  calendarOutline,
  chevronForwardOutline,
  arrowBack,
} from "ionicons/icons";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";
import "./History.scss";
//axios and base url
import axios from "axios";
import { BASE_URL, ASSET_URL } from "../BaseUrl";
import Cookies from "universal-cookie";
import { useState } from "react";

const cookies = new Cookies();

const History: React.FC = () => {
  let history = useHistory();
  // const [jobDetail, setJobDetail] = useState<any>(cookies.get("job-detail"));
  let job_details: any = localStorage.getItem('job-detail');
  const [jobDetail, setJobDetail] = useState<any>(JSON.parse(job_details));
  // const [userData, setUserData] = useState<any>(cookies.get('tlh_service_user'));
  let logged_user_data: any = localStorage.getItem('tlh_service_user');
  const [userData, setUserData] = useState<any>(JSON.parse(logged_user_data));
  const [loading, setLoading] = useState<any>(true);
  const [jobTrackings, setJobTrackings] = useState<any>([]);

  useIonViewWillEnter(() => {
    let url = '';
    if (userData.role.key === 'staff') {
      url += BASE_URL + "jobs/" + jobDetail.job.id + "/trackings-history";
    } else if (userData.role.key === 'customer') {
      url += BASE_URL + "customer/" + jobDetail.job.id + "/trackings";
    }
    axios
      .get(url, {
        headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token_tlh_service") || 'null')}`,
        },
      })
      .then(async (res) => {
        if (res.data.status) {
          setLoading(false);
          setJobTrackings(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const handleTrackingDetail = (tracking: any) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    // cookies.set("tlh_service_tracking_detail", tracking, {
    //   path: "/",
    //   expires: date,
    //   sameSite: "lax",
    // });
    localStorage.setItem("tlh_service_tracking_detail", JSON.stringify(tracking))
    history.push("/page/detail-image");
  };

  return (
    <IonPage>
      <IonHeader className="history-header">
        <IonRow className="row">
          <IonCol className="column" size="12">
            <div className="flex">
              <IonIcon
                icon={arrowBack}
                onClick={() => {
                  history.goBack();
                }}
                size="large"
                className="text-white"
              />
              <h3>Job History</h3>
              {/* <IonIcon icon={calendarOutline} size="large" className="text-white calendar-icon" /> */}
            </div>
          </IonCol>
        </IonRow>
        {/* <IonRow className="row flex">
          <IonCol className="column">
            <p>Mon</p>
            <h4>21</h4>
          </IonCol>
          <IonCol className="column">
            <p>Tue</p>
            <h4>22</h4>
          </IonCol>
          <IonCol className="column active-background">
            <p>Web</p>
            <h4>23</h4>
            <div className="dot"></div>
          </IonCol>
          <IonCol className="column">
            <p>Thu</p>
            <h4>24</h4>
          </IonCol>
          <IonCol className="column">
            <p>Fri</p>
            <h4>25</h4>
          </IonCol>
          <IonCol className="column">
            <p>Sat</p>
            <h4>26</h4>
          </IonCol>
          <IonCol className="column">
            <p>Sun</p>
            <h4>27</h4>
          </IonCol>
        </IonRow> */}
      </IonHeader>
      <IonContent className="history-wrapper">
        <div className="theme-background">
          <IonGrid className="border-radius-35">
            {loading ? (
              <div className="loader-container">
                <IonSpinner className="dash-spinner" name="crescent" />
              </div>
            ) : (
              <IonRow className="row">
                {jobTrackings.map((item: any, index: any) => {
                  var started_at;
                  let ended_at;

                  if (item.starts_at) {
                    let started_at = item.starts_at;
                    started_at = started_at.split(" ");
                    started_at = started_at[1];
                  }

                  if (item.ends_at) {
                    let ended_at = item.ends_at;
                    ended_at = ended_at.split(" ");
                    ended_at = ended_at[1];
                  }

                  return (
                    <IonCol
                      className="history-column"
                      onClick={() => handleTrackingDetail(item)}
                      size="11"
                      key={index}
                    >
                      {/* <h5>Active</h5> */}
                      <div className="history-card ">
                        <div className="job-description flex items-center">
                          <IonImg
                            src={
                              ASSET_URL +
                              "public/" +
                              item.attachments[0].attachment_url
                            }
                          />
                          <div className="title">
                            <h5>
                              check-in:{" "}
                              <span style={{ color: "#888" }}>
                                {item.starts_at ? item.starts_at_formatted : ""}
                              </span>
                            </h5>
                            <h5>
                              check-out:{" "}
                              <span style={{ color: "#888" }}>
                                {item.ends_at ? item.ends_at_formatted : "-"}
                              </span>
                            </h5>
                            <p>{item.date_formatted}</p>
                            {/* <p>hello</p> */}
                            {item.review ? (
                              <span className="rating-box pdleft">
                                <img
                                  className="rating-img"
                                  src={require("../images/check-mark.png")}
                                />{" "}
                                reviewed
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <IonIcon icon={chevronForwardOutline} />
                      </div>
                    </IonCol>
                  );
                })}
                {jobTrackings.length <= 0 ? (
                  <div className="no-data">
                    <img src={require("../images/sad.png")} />
                    <h4>No data available at the moment!</h4>
                  </div>
                ) : (
                  ""
                )}
              </IonRow>
            )}
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default History;
